import React, { useEffect, useState,useRef } from 'react';
import { Row,Container,Spinner, Button,Alert,Form,Col} from 'react-bootstrap';
import { useParams,Link, useNavigate } from 'react-router-dom';
import { firestore } from '../../firebase';
import { has } from 'lodash'


//  costum hooks 
import { useElectionContext } from '../../components/Context/ElectionContext';


//styled elements
import { LinkButton } from '../ViewsElement';


const IdLog =(props)=>{
    const myhistory = useNavigate();
    const [loading,setLoading] = useState(false);
    const [code,setCode] = useState('');
    const idRef= useRef();
    const codeRef= useRef();
    const [error,setError] = useState('');
    const [election,setElection] = useState('');
    const {setCurrentElection,setCurrentVoterPro,currentElection} = useElectionContext();
    const {linkcode} = useParams()

    //on code enter and click proceed
    const GetElection= async()=>{
        let Id = idRef.current.value.replace(/\\|\//g,'')
        console.log(Id)
        setLoading(true);
        const elections = firestore.collection("voters").doc(currentElection.Provoters);
      
      await elections.get().then((doc) => {
        if (doc.exists) {
          var data =doc.data();
            console.log("election data: fetched", data.voters);
            console.log(Id)
            if (has(data.voters, Id)){
            console.log(data.voters[Id].name)
            console.log("--------------")
                if(data.voters[Id].code){
                    if(codeRef.current.value === data.voters[Id].code){
                            // console.log("Id exists, Proceed ----", data.voters[Id]);
                            setCurrentVoterPro(Id,data.voters[Id])
                            setLoading(false);
                            myhistory('/portfolio');
                            throw "log in successful";
                        }else{
                        throw "Incorrect Credentials";

                        }
                }else{
                    throw "Voter not eligible";

                }
                
            
               

            }else{
                throw "Id does not exists, Proceed ----" + Id;
            }
    
        } else {
                // doc.data() will be undefined in this case
                setError("Election Code Invalid");
                setLoading(false);
                console.log("No such election!");
                throw "No such election!";
            }
        }).catch((err)=>{
            console.log("------------ Error -------------");
            console.log(err);
            setError(err)
            setLoading(false);
        })
       
    }
    
    const Proceed =(event)=>{
        event.preventDefault();

        setError('')
        if(codeRef.current.value && idRef.current.value){
        // console.log(Id)
            GetElection()
        }else(
            setError("please Enter an election Code to proceed")
        )
    }

    return(
        props.electionId.live ?

    <div className="col-md-10 col-lg-8 col-xl-7  mx-auto">
       <Row>
            <Col sx={11} sm={12} md={8} lg={6}  className="mx-auto my-auto text-center ro-bg-white my-lg-2 ">
        {(loading && !error) && <Spinner variant="success" animation="border"/>}
        {error && <Alert variant="danger">{error}</Alert>}
            
            <Form onSubmit={Proceed} >
                <div className="form-row">
                <h5 className="text-dark m-3">
                    Enter your details to proceed
                </h5>
                <Form.Group>
                <labe>Enter ID</labe>

                        <Form.Control
                        className=" form-control m-2 text-center"
                        type="text"
                        placeholder="Enter ID"
                        ref={idRef}
                        required
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                    <labe>Enter Code</labe>

                        <Form.Control
                        className="form-control text-center m-2"
                        type="password"
                        placeholder="Enter Code"
                        ref={codeRef}
                        required
                        />

                    </Form.Group>
                    <Button
                        disabled={loading}
                        variant="success"
                        className="primary-button"
                        type="submit"
                    >
                        Proceed
                    </Button>
                
                </div>
            </Form>
            
            </Col>
        </Row>
     </div>
        :<Alert variant="warning">Sorry:This election is currently offline!</Alert>

     )
}

export default IdLog;