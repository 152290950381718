import React, { useEffect, useState } from 'react';
import { useParams,Link } from 'react-router-dom';
import { Row, Media , Container , Card, Col ,Modal, Button,Spinner} from 'react-bootstrap';

import logo from "../../assets/img/logo.png"
import Voteslip from '../Voteslip/Voteslip';
import { useElectionContext } from '../../components/Context/ElectionContext';
import "./Portfolio.css";
import { WelcomeText } from '../../components/Elements/DashboardElemtents';

//visible header that contains LOGO and Navigation Icon
var d = new Date();
var n = d.getTime();

          

const Category =({data,setTriger})=>{
    const [show, setShow] = useState(false);
    const [sec, setSec] = useState('');
    const {voter , currentElection} = useElectionContext();
      
    const handleClose = () => {
      var a = new Date();
      var b = a.getTime();
      setShow(false)

    };
    const handleShow = () => setShow(true);

    const handleSuccess = () => {
      
      var a = new Date();
      var b = a.getTime();
     
      var width = 3;
      var id = setInterval(frame, 1000);
      
      function frame() {
        if (width == 0) {
          clearInterval(id);
            setTriger(b)
             setShow(false)
        } else {
          width--;
          setSec(width)
        }
      }
    }
    

  const VotingModal=
          <>     
            <Modal show={show}  
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static" keyboard={false} onHide={handleClose}>
              <Modal.Header className='' closeButton>
                <Modal.Title className="text-success fw-bolder text-uppercase">{data.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              
                <Row className='justify-content-center'>
                   <Voteslip closeModal={handleSuccess} portfolioId={data.portfolioId} candidates={data.candidate}/>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" className="rounded-pill" onClick={handleClose}>
                  Close {sec && "( "+ sec +" )"}
                </Button>
              </Modal.Footer>
            </Modal>
          </>;
    
    const canvote =
    <Col md={8} className="mx-auto ">
        <Container className="row justify-content-between align-items-center px-4 ">
        <Col  className="text-md-start">
        {/* {variant.name} */}
                <h5 className='h5'>{data.name}</h5>
            </Col>
            {}
            <Col  className="text-md-end">
                <Button onClick={()=>setShow(!show)} className="btn btn-successs primary-button  px-5 py-2" href="#">open</Button>
            </Col>
                <hr/>
        </Container>
        {VotingModal}

        </Col>
    
    const cannotvote =
        <Col md={8} className="mx-auto ">
        <Container className="row justify-content-between align-items-center px-4">
            <Col  className="text-md-start">
            <h5 className='h5'>{data.name}</h5>
            </Col>
            {}
            <Col  className="text-md-end">
                <Button disabled  className="btn btn-light primary-button px-5 py-2" href="#">vote casted</Button>
            </Col>        <hr/>

        </Container>

        </Col>
    

          if (data.voters.indexOf(voter) !== -1){
            console.log("voted already");
            return cannotvote;


        }else{
            console.log("can vote");
            return canvote;
        }
}

export default Category;