import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card ,InputGroup, Table, Alert, Image,Form,ProgressBar} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { Link, useHistory, useParams } from 'react-router-dom';
import { BecomeProWidget } from '../../../components/BecomeProWidget';
import { useAuth } from '../../../components/Context/AuthContext';

import { useElectionContext } from '../../../components/Context/ElectionContext';
import { ResetElectionContext } from '../../../components/Hooks/Functions';
import SetOfline from '../../../components/Hooks/useSetOfline';
import SetLive from '../../../components/Hooks/useSetLive';
import SetClose from '../../../components/Hooks/useSetClose';
import DeleteElection from '../../../components/Hooks/useDeleteElection';
import { firestore } from '../../../firebase';
import ProgressHeader from '../Elections/ProgressHeader';
import { DropzoneArea } from 'material-ui-dropzone';
import * as csv from 'csvtojson';
import { CSVReader } from 'react-papaparse';
import AddVoters from '../../../components/Hooks/useAddVoters';
import { pickBy, identity } from 'lodash'
import DemoTable from '../../../components/Table/DemoTable';


const AddMultipleVoters =()=>{
    const {voter,currentElection} = useElectionContext();
    const [electionData,setElectionData] = useState(currentElection)
    const [success,setSuccess] = useState(false);
    const [level, setLevel] = useState(2);
    const [error,setError] = useState("");
    const [copied,setCopied] = useState(false);
    const [loading,setLoading] = useState(false);
    const {userID,userProfile} = useAuth()
    const [progress, setProgress] = useState(0);
    const [progressImg, setProgressImg] = useState(0);
    const [data, setData] = useState([]);
    const [arrayData, setArrayData] = useState({});
    const [validated, setValidated] = useState(false);

    const UpdateData =(item,value)=>{
         item = item.replace(/\\|\//g,'')
        console.log(item)
        if (item){
            if(arrayData.length === 0){
            setArrayData({[item]:{name:value,code:'',id:item}})
            console.log(item)
        }else{
            setArrayData(arrayData => ({...arrayData, [item]:{name:value,code:'',id:item}}))

        } 
        }
       
      }

    const handleOnDrop = (data) => {
        console.log('---------------------------');
        console.log(data);
        setData([...data])
        console.log('---------------------------');
      };
    
    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
      };
    
    const handleOnRemoveFile = (data) => {
        console.log('---------------------------');
        console.log([]);
        console.log('---------------------------');
      };
    
        const Submit = async ()=>{
            const cleanedObject = pickBy(arrayData, identity)
            
            console.log('----------------------cleanedObject-------------------------')
            console.log(cleanedObject)
      
             const {success,error,} = await AddVoters(cleanedObject,userID,currentElection);
             setSuccess(success);
             setError(error);

        }
        
    const Proceed = async(event) => {
                setValidated(false) 
                setArrayData([])
            setError("")
            event.preventDefault();
            //  str
        // if(Object.keys(data).length !== 0){
        //     await data.map((obj) => {
        //                 UpdateData(obj.data[0].replace(/\\/g, '').replace(".", "dot") ,obj.data[1])
        //             }) 
        
        //         setValidated(true) 
        // }else{
        //     setError("Error  :  Add a CSV file first")
        // }
       
        // Assuming 'data' is an array of objects
if (Array.isArray(data) && data.length > 0) {
    // Use forEach() to iterate over the array and handle each object
    data.forEach(async (obj) => {
      if (obj.data && obj.data.length >= 2) {
        // Access properties using obj.data[0] and obj.data[1]
         UpdateData(obj.data[0].replace(/\\/g, '').replace(".", "dot"), obj.data[1]);
      } else {
        // Handle the case where the object does not have the expected structure
        console.error('Invalid data format:', obj);
      }
    });
    setValidated(true) 
  } else {
    // Handle the case where 'data' is empty or not an array
    console.error('Data is empty or not an array:', data);
    setError("Error  :  Add a CSV file first")

  }
  
        // Object.keys(arrayData).forEach(key => arrayData[key] === undefined && delete arrayData[key])
        
        console.log(arrayData)

        };
    
        return(
           
    
  
                <div  className='col-12 col-md-8 mx-auto p-4 '>
                <h4 className='text-center text-muted p-3 m-1'>Add Voters in bulk</h4>
                {(loading && !error) && <Spinner variant="success" animation="border"/>}
                    {error && <Alert variant="warning">{JSON.stringify(error)}</Alert>}
                    {success && <Alert variant="success">
                        <h4>{success}</h4>
                    </Alert>}
                <div className='row'>
                <div className='col'>
                    <h5 className='text-danger'>Notice!</h5>
                    <ol className='text-danger ordered-list'>
                        <li>Load voters from file (csv)</li>
                        <li className='text-primary'>Click on "Read Data" before</li>
                        <li className='text-success'>Submit List</li>
                    </ol>
                </div>
                <div className='col'>
                      <div className='p-4 m-3'>
                    <h5>CSV Upload Preview</h5>
                    <p>Here's how your data will be arranged in the CSV file:</p>
                    <DemoTable />
                    
                    </div>
                </div>

                </div>
               

              
                <Form noValidate  onSubmit={Proceed} inline>
    
                {/* <Form.Group className="row m-2" controlId="validationCustom07">
                    <Form.Label className="col-3 align-bottom my-auto text-end">
                    Election Logo
                    </Form.Label>
                    <Col>
                    {progress !== 0 && <ProgressBar label={progress +'%'} striped now={progress} />}
                    <DropzoneArea

                        // acceptedFiles={["*"]}
                        dropzoneText={"Upload Election Image"}
                         onDrop={(files) => onDrop(files)}
                        maxFileSize={300000000}
                        filesLimit={1}
                    />
                {error.img && <div className="alert-danger">{error.img} </div>}
                
                    </Col>
                </Form.Group>
             */}

            <CSVReader
            onDrop={handleOnDrop}
            onError={handleOnError}
            style={{}}
            config={{}}
            addRemoveButton
            onRemoveFile={handleOnRemoveFile}
            >
            <span>Drop CSV file here or click to upload.</span>
            </CSVReader>
                <Col className="text-center">
                    <Button
                    type="submit"
                    className="m-2 btn-warn text-center"
                    >
                    Read Data
                    </Button>
                    <Button
                    disabled={!validated}
                    onClick={()=>Submit()}
                    type="button"
                    className="m-2 btn-success text-center"
                    >
                    Submit List
                    </Button>
    
                </Col>
                </Form>
    
    
                
            </div>
              
        )
    }
    
    export default AddMultipleVoters;