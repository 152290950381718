import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Alert, Container, Row, Col } from "react-bootstrap";
import { useAuth } from "../../components/Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";

const NotVerifiedPage = () => {
  const history = useNavigate();
  const { logout, verifyUser } = useAuth();
  const [disabled, setDisabled] = useState(false);
  const [countdown, setCountdown] = useState(120); // 2 minutes in seconds

  const clickButton = () => {
    verifyUser();
    setDisabled(true);
    setCountdown(120); // Start the countdown from 2 minutes
  };

  useEffect(() => {
    let timer;
    if (disabled && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setDisabled(false); // Re-enable the button after the countdown
      clearInterval(timer);
    }

    return () => clearInterval(timer); // Clean up the interval on component unmount
  }, [disabled, countdown]);

  return (
    <Container fluid>
      <Row>
        <Container fluid className="my-auto">
          <Row className="height-full">
            <Col md={12} className="mx-auto my-auto text-center">
              <Row>
                <h2 className="header p-1 text-success">Congratulations!!!</h2>
                <h4 className="header p-1">But... you haven't been verified yet!</h4>
                <Col className="text-center">
                  <Button
                    disabled={disabled}
                    onClick={clickButton}
                    className="primary-button"
                  >
                    Verify my account
                  </Button>
                  {disabled && (
                    <p>
                    Didn't receive a mail?...Please wait {Math.floor(countdown / 60)}:
                      {("0" + (countdown % 60)).slice(-2)} minutes to try again.
                    </p>
                  )}
                  {!disabled && (
                    <p>Didn't receive a mail?...wait for 5 minutes to try again.</p>
                  )}
                </Col>
                </Row>
                <Row>
                <Col>
                  <Button
                    disabled={disabled}
                    onClick={logout}
                    className="secondary-button btn btn-dark"
                  >
                    Log out
                  </Button>
                </Col>
                
              </Row>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default NotVerifiedPage;
