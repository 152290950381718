import React, { useEffect ,useState} from 'react'
import { Container,Card,Button,Col,Row,Spinner, Alert,Image } from 'react-bootstrap'
import useFetchCandidateById from '../../../components/Hooks/useFetchCandidateById';
import logo from  "../../../assets/img/logo.png"
import { useElectionContext } from '../../../components/Context/ElectionContext';


const UniqueSingleCandidateResult=React.forwardRef((props)=>{
    const {voter , currentElection} = useElectionContext();
    const [candidate,setCandidate] = useState([])
    const [success,setSuccess] = useState(false);
    const [error,setError] = useState("");
    const [initerror,setInitError] = useState("");
    const [initloading,setInitLoading] = useState(false);
    const [loading,setLoading] = useState(false);
    const [vote,setVote] = useState(false);
    const candidateId = props.candidateId;
    const portfolioId = props.portfolioId;

   
              
const UpdateChart=(mycandidate)=>{
    if(mycandidate){
        var name =  mycandidate.name;
        var vote = mycandidate.votes;
        var rejects = mycandidate.rejects;
        props.UpdateData(name,vote,rejects)
    }
}

  const FetchCandidate = async()=>{
             const {candidate, loading ,error} = await useFetchCandidateById(props.candidateId);
            setInitLoading(loading);
            setCandidate(candidate);
            setInitError(error);
           

        }
        FetchCandidate();

        useEffect(()=>{

            if(candidate.name){
              UpdateChart(candidate)
            }

        },[candidate])


  
    return(
    <div   className="text-center border  my-auto ">
       
        {(error ) && <Alert variant="danger">{error}</Alert>}
        {(initerror) && <Alert variant="danger">{initerror}</Alert>}
        {(initloading && !error)   &&  <Spinner animation="border" variant="success"/> }
        {(loading && !error)   &&  <Spinner animation="grow" variant="success"/> }
        {success &&  <Alert variant="success">vote cast success</Alert>}


        {(!initloading && !initerror && !loading && !error && !success && candidate) &&  
        
        <Row className="p-2">
            <Col sx={12} md={4} lg={6} className="text-start">
                <Image
                 src={candidate.img ||logo} width="50" height="50" roundedCircle /> {candidate.name } 
            </Col>
            <Col>
            <Row>
            <Col className="text-start text-success my-auto"> {candidate.votes} </Col>
            <Col className="text-start text-success my-auto"> {parseFloat((candidate.votes/props.total)*100).toFixed(2)}%  </Col>
            <Col className="text-start text-success my-auto">  Yes </Col>

            </Row>
            <hr/>
            <Row>
            <Col className="text-start text-success my-auto"> {candidate.rejects} </Col>
            <Col className="text-start text-success my-auto"> {parseFloat((candidate.rejects/props.total)*100).toFixed(2)}%  </Col>
            <Col className="text-start text-success my-auto">  No </Col>

            </Row>
            </Col>
                  </Row>
        }
        
    </div>
)
});

export default UniqueSingleCandidateResult;