import React, { useState } from 'react';
import { Row,Container,Col } from 'react-bootstrap';
import { FaUserFriends } from "react-icons/fa";
import {  RiFlashlightLine,RiShieldFlashLine,RiCheckboxCircleLine,RiWalletLine } from "react-icons/ri";
import { WiLightning } from "react-icons/wi";
import { GrSecure } from "react-icons/gr";

//visible header that contains LOGO and Navigation Icon
import "./Info.css";
import { Link } from 'react-router-dom';
import Overlay from './Overlay';
// import "../../assets/css/custom.css";
// import fastimg from "../../assets/img/undraw_fast_car_p4cu.svg";
// import easytouse from "../../assets/img/undraw_Usability_testing_re_uu1g.svg";
// import secure from "../../assets/img/undraw_Security_on_re_e491.svg";


function Home() {
    return(
            
    <div className="jumbotron jumbotron-fluid bg-success bg-gradient bg-gradient-70 h-100 mh-100 w-100 row m-0" id="banner" >
        <div className=" text-center text-md-start col my-auto">
            <div className='container '>
            
                <h1 data-aos="fade" data-aos-easing="linear" data-aos-duration="1000" data-aos-once="true" className="display-3 text-white fw-bold my-5">
                    Ilect Corp<br/>
                    The Trusted Election Aid.
                </h1>
                <p data-aos="fade" data-aos-easing="linear" data-aos-duration="1000" data-aos-once="true" className="lead text-white my-4">
                The trusted provider of secure and reliable election technology.
                <br/>
                </p>
                <Link to={"/get-started"} href="#" data-aos="fade" data-aos-easing="linear" data-aos-duration="1000" data-aos-once="true" className="btn m-3 font-weight-bold atlas-cta cta-green">Create Election</Link>
                <a href="#vote" data-aos="fade" data-aos-easing="linear" data-aos-duration="1000" data-aos-once="true" className="btn m-3 font-weight-bold atlas-cta cta-blue">Cast A Vote</a>
            </div>
        </div>
        {/* <div className='d-none col-lg-5 bg-success bg-gradient'>
            <Overlay/>
        </div> */}
    </div>


    );
}
export default Home;











