import React, { useEffect ,useState} from 'react'
import { Container,Card,Button,Col,Row,Spinner, Alert } from 'react-bootstrap'
import { BsCheck } from 'react-icons/bs';
import logo from  "../../assets/img/logo.png"
import { useElectionContext } from '../../components/Context/ElectionContext';
import useCastVote from '../../components/Hooks/useCasteVote';
import useFetchCandidateById from '../../components/Hooks/useFetchCandidateById';
import { arrayAdd, firestore, increment,db } from '../../firebase';


const MultipleCandidates=(props)=>{
    const {voter , currentElection} = useElectionContext();
    const [candidate,setCandidate] = useState([])
    const [success,setSuccess] = useState(false);
    const [error,setError] = useState("");
    const [initerror,setInitError] = useState("");
    const [initloading,setInitLoading] = useState(false);
    const [loading,setLoading] = useState(false);
    const [vote,setVote] = useState(false);
    const candidateId = props.candidateId;
    const portfolioId = props.portfolioId;

        const CastVote=()=>{
                // Get a new write batch
                var batch = db.batch();

                // Set the value of 'NYC'
                const portfolio = firestore.collection('portfolio').doc(portfolioId);
                // find if voter hasn't voted for portfolio
                const candidate = firestore.collection('candidates').doc(candidateId);
            
                const election = firestore.collection('election').doc(currentElection.id);
                
                
                batch.update(candidate, {"votes": increment});
                batch.update(election, {"votersCast": arrayAdd.arrayUnion(voter)});

                // Update the portfolio
                
                // batch.update(portfolio, {"voters": arrayAdd.arrayUnion(voter)});
                batch.update(portfolio, {"voters": arrayAdd.arrayUnion(voter),"votesCast":increment});


                // Commit the batch
                batch.commit().then(() => {
                    // ...
                    console.log("voted successfully");
                    setSuccess(true)
                    setLoading(false)
                    props.closeModal()
                }).catch((err)=>{
                    console.log(err)
                    setError(err)
                })
            }
    const dovote =()=>{
        setLoading(true);
        return VoteCandidate();
    }

    const VoteCandidate =async ()=>{
            // const createdAt = timestamp();
            

            const portfolio = firestore.collection('portfolio').doc(portfolioId);
        

           await portfolio.get().then((doc) => {
                if (!doc.exists) {
                    console.log("can vote");
                    return;
                    
                } 
                if (doc.data().voters.indexOf(voter) !== -1){
                        console.log("voted already");
                        failed("voted already")


                    }else{
                        console.log("can vote");
                        CastVote();
                    }
            })
                    
        }

    function failed(err) {
        console.log("voted already in failed");

            setError(err);
            setLoading(true)
            props.closeModal()
        }
        
       
            
        
        const FetchCandidate = async()=>{
            const {candidate, loading ,error} = await useFetchCandidateById(props.candidateId);
            setInitLoading(loading);
            setCandidate(candidate);
            setInitError(error);

        }
        FetchCandidate();


    const Output = <>
         <Col sx={12}  className="m-2 card  text-center ">
                    {/* <Row style={{minHeight:"150px"}}>
                        <Col sx={12} md={4} lg={6} className="text-md-end my-auto">
                            <img variant="top" src={candidate.img || logo}  className="rounded-circle border border-secondary border-2 img-fluid mx-auto"/>
                        </Col>
                        <Col sx={12} md={8} lg={6} className="my-auto text-md-start">
                            <h3>{!loading && candidate.name}</h3>
                            
                        </Col>
                    </Row> */}
                    <Card>
            <Card.Img variant="top" src={candidate.img || logo} width="200" height="200" />
            <Card.Body>
                <Card.Title>{!loading && candidate.name}</Card.Title>
                {(loading && !error)   &&  <Spinner animation="grow" variant="success"/> }
                {(error ) && <Alert variant="danger">{error}</Alert>}
                {success &&  <BsCheck color="green" fontSize="2.5em"/>}<br/>
                <Button variant="success" onClick={()=>dovote()} className="m-1 rounded-pill">Vote</Button>

            </Card.Body>
            </Card>
                </Col> 
    </>
    return(
    <Col sx={12} md={6} lg={4} className="text-center">
       

        {(initerror) && <Alert variant="danger">{initerror}</Alert>}
        {(initloading && !error)   &&  <Spinner animation="border" variant="success"/> }
        {/* {(loading && !error)   &&  <Spinner animation="grow" variant="success"/> } */}
        {success &&  <Alert variant="success">vote cast success</Alert>}

        {(!initloading && !initerror ) &&  Output }


               
            
        
        
    </Col>
)
}

export default MultipleCandidates;