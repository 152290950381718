import React ,{ useState ,useEffect} from "react";
import {arrayAdd, firestore,increment,timestamp} from '../../firebase';

import Hashids from 'hashids'



async function AddPortfolio(data,userID,currentElection){
    const hashids = new Hashids('',5)
    const longhashids = new Hashids('ILoveAVMB-Portfolio',15)
    let error = ''
    let id="";
    let success=false;
    
    
        //references
        const createdAt = timestamp();
       
        // Create a reference to the SF doc.
        var MainCounters = firestore.collection("EC").doc("--Counter--");
        var election = firestore.collection("election").doc(currentElection.id);
        const portfolios = firestore.collection('portfolio')
        const users = firestore.collection('users').doc(userID);
        // let promise = await admin.firestore().runTransaction(transaction => {
        //     var post = transaction.get(docRef);
        //     var anotherPost = transaction.get(anotherDocRef);
          
        //     if (post.exists && anotherPost.exists) {
        //       var newLikes = (post.data().likes || 0) + 1;
        //       await transaction.update(docRef, { likes: newLikes });
        //       newLikes = (anotherPost.data().likes || 0) + 1;
        //       await transaction.update(anotherdocRef, { likes: newLikes });
        //     }
        //   })
        await firestore.runTransaction(async (transaction) => {
            var user =  await transaction.get(users);
            var ilect =  await transaction.get(election);
            var EC =  await transaction.get(MainCounters);
            
              if(!user.exists){   
                        throw "User does not exist!";
                    }
                    if(!ilect.exists){   
                        throw "election does not exist!";
                    } 
                    if(ilect.data().userId !== userID){ 
                        throw "You are not admin for this election";
                    }
                    if(ilect.data().live){ 
                        throw "You can't edit this election now";
                    }
                    if((ilect.data().portfolioCount >= 4) && (ilect.data().type === 1)){ 
                        throw "You can't add more categories to this election, upgrade to add more";
                    }
                    if((ilect.data().portfolioCount >= 10) && (ilect.data().type === 2)){ 
                        throw "You can't add more categories to this election, upgrade to add more";
                    }
                    if((ilect.data().portfolioCount >= 15) && (ilect.data().type === 3)){ 
                        throw "You can't add more categories to this election, upgrade to add more";
                    }
                console.log(ilect.data())
                    
                        var newCount = (EC.data().portfolioCount || 0) + 1;
                        console.log(newCount)
                        id = longhashids.encode(newCount);    

                        transaction.update(MainCounters, { portfolioCount: newCount });
                        transaction.set(portfolios.doc(id), {
                            ...data,
                            candidate:[],
                            electionId:currentElection.id,
                            portfolioId:id,
                            portfolio:[],
                            voters:[],
                            votesCast:0,
                            createdAt,
                        })

                        transaction.update(election, {
                            portfolioCount: increment,
                            portfolios: arrayAdd.arrayUnion(id)
                        });
                     success = true

                    return success;
 
            }).catch((err) => {
                    error = err;
                    console.error("Error adding election: ", error);
                    return error;
                });
        


       
    return {error,success}

}

export default AddPortfolio;