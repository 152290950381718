import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card ,Image, Table,Alert,Breadcrumb} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { Link,useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../../components/Context/AuthContext';
import useFetchElectionByUserId from '../../../components/Hooks/useFetchElectionByUserId';
import ElectionList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
import logo from "../../../assets/img/logo.png"


const UserElections =()=>{
    const [electionData,setElectionData] = useState([])
    const [success,setSuccess] = useState(false);
    const [error,setError] = useState("");
    const [loading,setLoading] = useState(false);
    const {userID ,currentUser,userProfile} = useAuth()
    

        const FetchElection = async()=>{
            // console.log(userID);
        const {election, loading ,error} = await useFetchElectionByUserId(userID);
        setLoading(loading);
        setElectionData(election);
        setError(error);

    }
    FetchElection();

    return(
        <Body>
            <Container className="m-2 mx-auto align-content-sm-center container-fluid">
             {/*welcome Heading  */}
             <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard" }}>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/myelections" }} active >
                    My Election 
                </Breadcrumb.Item>
            </Breadcrumb>
            <HeaderBanner className="row  mx-auto my-auto p-2">
                    <Col md={6} lg={8}>
                        <h5>My Elections</h5>
                    </Col>
                    <Col md={3} lg={2} className="text-end mx-auto my-auto">
                        <LinkButton to="/dashboard/new-election" className="my-auto btn my-auto mx-auto"><BsPlus/> New Election</LinkButton>
                    </Col>
             </HeaderBanner>
             {(!loading && !error) && <ElectionList elections={electionData}/>}
             {loading && <Spinner animation="grow" variant="success"/>}
             {(error ) && <Alert variant="danger">{error}</Alert>}

        </Container>
    </Body>
    )
}

export default UserElections;