import styled from 'styled-components';
import { Link as LinkR } from "react-router-dom";

export const LinkButton = styled(LinkR)`
    background: var(--unnamed-color-e6e6e6) 0% 0% no-repeat padding-box;
    background: rgb(11 72 107) 0% 0% no-repeat padding-box;
  
    opacity: 1;
    white-space: nowrap;
    padding: 10px 22px;
    color: #fff;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    text-decoration:none;
    &:hover {
        transition: all 0.2s ease-in-out;
        color: #fff;
        background: var(--unnamed-color-e6e6e6) 0% 0% no-repeat padding-box;
        background: rgb(11 72 107) 0% 0% no-repeat padding-box;
    }
    a:hover {
    color: #FF00FF;
    }

    
`;

export const HeaderBanner = styled.div`
background: #dbf3d4;
white-space: nowrap;
padding: 10px 22px;
font-size: 16px;
outline: none;
border: none;
display:flex;
`
export const Body = styled.div`

 padding: 10px 22px;
font-size: 1rem;
outline: none;
border: none;
@media (max-width: 576px) {
    padding: 5px;

  }
  
  @media (max-width: 768px) {
    padding: auto;
  }
`
export const HeaderBannerIcon = styled.div`
    font-size: 2rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    text-align: center;
    padding: 0.8333333333rem;
    margin: 0 30px 0 0;
    background: #fff;
    -webkit-box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
    box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
    border-radius: 0.25rem;
    width: 60px;
    height: 60px;
`

export const Cardheader = styled.div`
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    border-bottom-width: 1px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0.625rem;
    height: 3.5rem;
    &:first-child {
        border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    }
`

export const ActionButtonRight = styled.div`
    display:flex;
    margin-left: auto;
    white-space: nowrap;

`