import React ,{ useState ,useEffect} from "react";
import {arrayAdd, firestore,increment,timestamp} from '../../firebase';

import Hashids from 'hashids'



async function AddVoters(data,userID,currentElection){
    const hashids = new Hashids('',5)
    const longhashids = new Hashids('IAmMightyMan-voters',15)
    let error = ''
    let id="";
    let success=false;
    
    
        //references
        const createdAt = timestamp();
       
        // Create a reference to the SF doc.
        var MainCounters = firestore.collection("EC").doc("--Counter--");
        var election = firestore.collection("election").doc(currentElection.id);
        const portfolios = firestore.collection('voters')
        const users = firestore.collection('users').doc(userID);
  
        await firestore.runTransaction(async (transaction) => {
            var user =  await transaction.get(users);
            var ilect =  await transaction.get(election);
            var EC =  await transaction.get(MainCounters);
            
              if(!user.exists){   
                        throw "User does not exist!";
                    }
                    if(!ilect.exists){   
                        throw "election does not exist!";
                    } 
                    if(ilect.data().userId !== userID){ 
                        throw "You are not admin for this election";
                    }
                    if(ilect.data().live){ 
                        throw "You can't edit this election now";
                    }
                // console.log(ilect.data())
                    
                        var newCount = (EC.data().Loadedvoters || 0) + 1;
                        console.log(newCount)
                        id = longhashids.encode(newCount);    
                        // create a new voters list and assign to your election
                        transaction.update(MainCounters, { Loadedvoters: newCount });
                        transaction.set(portfolios.doc(id), {
                            //
                            candidate:[],
                            // votersCount: data.length,
                            electionId:currentElection.id,
                            provotersId:id,
                            votesCast:0,
                            createdAt,
                            voters:{...data},

                        })
                        console.log(data);

                        transaction.update(election, {
                            // add voters document id to your election as provoters
                            Provoters: id, 

                        });
                     success = true

                    
 
            }).catch((err) => {
                    error = err;
                    console.error("Error adding election: ", error);
                });
        


       
    return {error,success}

}

export default AddVoters;