import React ,{ useState ,useEffect} from "react";
import {arrayAdd, firestore,increment,timestamp} from '../../firebase';

import Hashids from 'hashids'
import { json } from "react-router-dom";



// async function AddSingleVoterHook(data,userID,currentElection){
//     // const longhashids = new Hashids('IAmMightyMan-voters',15)
//     // let error = ''
//     // let success=false;
    
    
//     //     //references       
//     //     // Create a reference to the SF doc.
//     //     var election = firestore.collection("election").doc(currentElection.id);
//     //     const voters = firestore.collection('voters').doc(currentElection.Provoters);
//     //     const users = firestore.collection('users').doc(userID);
  
//     //     await firestore.runTransaction(async (transaction) => {
//     //         var user =  await transaction.get(users);
//     //         var ilect =  await transaction.get(election);
//     //         var provoters =  await transaction.get(voters);
            
//     //           if(!user.exists){   
//     //                     throw "User does not exist!";
//     //                 }
//     //                 if(!ilect.exists){   
//     //                     throw "Election does not exist!";
//     //                 } 
//     //                 if(ilect.data().userId !== userID){ 
//     //                     throw "You are not admin for this election";
//     //                 }
//     //                 if(ilect.data().live){ 
//     //                     throw "You can't edit this election now";
//     //                 }
//     //                 if(!provoters.exists){ 
//     //                     throw "Voter Database does not exist!";
//     //                 }
//     //                 console.log(provoters.data().voters);


//     //                 const currentVoters = provoters.data().voters;  // Default to an empty object if "voters" doesn't exist
//     //                     const newVoterId = data.id;

//     //                     var newVoterData = {
//     //                         code: "",
//     //                         id: `${data.id}`,
//     //                         name: `${data.name}`
//     //                     };
//     //                     console.log("Data to be added:", newVoterData);
//     //                     currentVoters[JSON.stringify(newVoterId)] = newVoterData;

//     //                     // update the "voters" map in the document
//     //                     transaction.update(provoters, { voters: JSON.parse(JSON.stringify(currentVoters))});

//     //                     success = true; // Indicate success within the transaction

//     //                     // No return is needed here, the value of 'success' will be captured in the outer promise chain

//     //                 }).catch((err) => {
//     //                     error = err;
//     //                     console.error("Error adding Voter: ", error);
//     //                 });
                        


       
//     // return {error,success}

// }

async function AddSingleVoterHook(data, userID, currentElection) {
    const db = firestore;
    
    try {
        // Get user, election, and voters document references
        const userSnapshot = await db.collection('users').doc(userID).get();
        const electionSnapshot = await db.collection('election').doc(currentElection.id).get();
        const votersSnapshot = await db.collection('voters').doc(currentElection.Provoters).get();
        
        // Check if documents exist
        if (!userSnapshot.exists || !electionSnapshot.exists || !votersSnapshot.exists) {
            return { error: "Required document(s) not found.", success: false };
        }
        
        // Check admin and election live status
        if (electionSnapshot.data().userId !== userID || electionSnapshot.data().live) {
            return { error: "You are not allowed to edit this election now.", success: false };
        }
        
        // Prepare new voter data
        const newVoterData = {
            code: "",
            id: data.id,
            name: data.name
        };

        
        
        // Update the "voters" map in the document
        const currentVoters = votersSnapshot.data().voters || {};
        
        if (currentVoters[newVoterData.id]) {
            return { error: "This Voter ID already Exists.", success: false };
        }else{
                    currentVoters[newVoterData.id] = newVoterData;

        }
        await db.collection('voters').doc(currentElection.Provoters).update({
            voters: currentVoters
        });
        
        return { error: "", success: true };
    } catch (error) {
        console.error("Error adding Voter: ", error);
        return { error: "An error occurred.", success: false };
    }
}

// export default AddSingleVoterHook;


export default AddSingleVoterHook;