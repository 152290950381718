import React, { useEffect ,useState} from 'react'
import { Container,Card,Button,Col,Row,Spinner, Alert,Modal } from 'react-bootstrap'
import useFetchCandidateById from '../../../components/Hooks/useFetchCandidateById';
import logo from  "../../../assets/img/logo.png"
import { useElectionContext } from '../../../components/Context/ElectionContext';
import RemoveCandidate from '../../../components/Hooks/useRemoveCandidate';
import { useAuth } from '../../../components/Context/AuthContext';
import EditCandidateForm from './editCandidateForm';

// THIS IS THE UNIQUE CANDIDATE FUCNTION:
// IT EITHER A PREVIEW FUNCTION OR  EDIT OR DELETE CANDIDATE FUNCTION , 
// IT ACCEPTS A preveiw=true propetry ton only display previews  
// IT ACCEPTS candidateId and fecthes candidates details to be displayed

const UniqueCandidate=(props)=>{
    const {voter , currentElection} = useElectionContext();
    const { userID} = useAuth();
    const [candidate,setCandidate] = useState([])
    const [success,setSuccess] = useState(false);
    const [error,setError] = useState("");
    const [initerror,setInitError] = useState("");
    const [initloading,setInitLoading] = useState(false);
    const [loading,setLoading] = useState(false);
    const [vote,setVote] = useState(false);
    const [deleteTriger,PullTriger] = useState('')
    const candidateId = props.candidateId;
    const portfolioId = props.portfolioId;
    const [show, setShow] = useState(false);

    const handleClose = () => {
        var a = new Date();
        var b = a.getTime();
        setShow(false)
  
        props.setTriger(b);
        
  
      };
      const handleShow = () => setShow(true);
  
      const handleSuccess = async() => {
        
          var a = new Date();
        var b = a.getTime();
         setShow(false)
        await props.setTriger(b);

      }

  const FetchCandidate = async()=>{
             const {candidate, loading ,error} = await useFetchCandidateById(props.candidateId);
            setInitLoading(loading);
            setCandidate(candidate);
            setInitError(error);

        }
        FetchCandidate();
  
const callDelete = async()=> {
    var a = new Date();
    var b = a.getTime();
    await PullTriger(b)
}

        useEffect(()=>{
            const DeleteCandidate = async()=>{

                const {loading ,error} = await RemoveCandidate(userID,currentElection,candidate.portfolioId,props.candidateId);
               setInitLoading(loading);
               setInitError(error);
               props.setTriger();
   
           }

           if(deleteTriger){
            DeleteCandidate()
           }
        },[deleteTriger])

    const EditCandidateModal=
        <>     
          <Modal show={show}  
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static" keyboard={false} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Candidate</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EditCandidateForm  closeModal={handleSuccess} candidate={candidate} portfolioId={props.candidateId} />
                {/* <Voteslip candidates={data.candidate}/> */}
                
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" className="rounded-pill" onClick={handleClose}>
                Close 
              </Button>
            </Modal.Footer>
          </Modal>
        </>;
    const Output = <>
         <Col  className="m-2  border border-white rounded-3 text-center shadow ">
                     <Card>
            <Card.Img variant="top" src={candidate.img || logo} width="200" height="270" />
            <Card.Body>
              {/* show candidate name if not loading */}
                <Card.Title>{!loading && candidate.name}</Card.Title> 
                {/* show delete button or edit button if candidate is not being previewed*/}
                {!props.preview && <Button variant="danger" onClick={()=>callDelete()}>Delete</Button>}
                {!props.preview && <Button variant="danger" onClick={()=>handleShow()}>Edit</Button>}
                {EditCandidateModal}
            </Card.Body>
            </Card>

        </Col> 
    </>
    return(
    <Col sx={8} sm={6} md={4} lg={4} className="text-center col-lg-4 ">
       

        {(error ) && <Alert variant="danger">{error}</Alert>}
        {(initerror) && <Alert variant="danger">{initerror}</Alert>}
        {(initloading && !error)   &&  <Spinner animation="border" variant="success"/> }
        {(loading && !error)   &&  <Spinner animation="grow" variant="success"/> }
        {success &&  <Alert variant="success">vote cast success</Alert>}

        {(!initloading && !initerror && !loading && !error && !success) &&  Output }

        
    </Col>
)
}

export default UniqueCandidate;