import React ,{ useState ,useEffect} from "react";
import {arrayAdd, firestore,increment,timestamp} from '../../firebase';

import Hashids from 'hashids'



async function SetOfline(userID,currentElection){
    const hashids = new Hashids('',5)
    const longhashids = new Hashids('ILoveAVMB-Portfolio',15)
    let error = ''
    let success=false;
    
    
        //references
        const createdAt = timestamp();
       
        // Create a reference to the SF doc.
        var MainCounters = firestore.collection("EC").doc("--Counter--");
        var election = firestore.collection("election").doc(currentElection.id);
        const portfolios = firestore.collection('portfolio')
        const users = firestore.collection('users').doc(userID);

        await firestore.runTransaction(async (transaction) => {
            var user =  await transaction.get(users);
            var ilect =  await transaction.get(election);
            var EC =  await transaction.get(MainCounters);
            
              if(!user.exists){   
                        throw "User does not exist!";
                    }
                    if(!ilect.exists){   
                        throw "election does not exist!";
                    } 
                    if(ilect.data().userId !== userID){ 
                        throw "You are not admin for this election";
                    }
                    // if(ilect.data().live){ 
                    //     throw "You can't edit this election now";
                    // }
                console.log(ilect.data())
                    
                        var liveCount = (EC.data().liveElectionCount || 0) - 1;

                        transaction.update(MainCounters, { liveElectionCount: liveCount });
                       
                        transaction.update(election, {
                           live:false,
                        });
                        success = true

                    
 
                    }).then((success) => {
                        console.log("Transaction successfully committed!");
                        return success;
                    }).catch((err) => {
                    error = err;
                    console.error("Error adding election: ", error);
                    return error;
                });
        


       
    return {error,success}

}

export default SetOfline;