import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner,Tabs,Tab, Col, Container, Button, Card ,InputGroup, Table, Alert, Image,Form,Breadcrumb} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
import { useElectionContext } from '../../../components/Context/ElectionContext';

import AddSingleVoter from './AddSingleVoter';
import AddMultipleVoters from './AddMultipleVoters';
import VotersList from './VotersList';

const Voters =()=>{
    const {voter,currentElection} = useElectionContext();
  
    const myhistory = useNavigate();
    const {linkcode} = useParams();

    if(!linkcode){
        myhistory.push('/dashboard')
    }
   
    
    return(
        <Body>
        <Container className="m-2 mx-auto align-content-sm-center container-fluid">
         {/*welcome Heading  */}
         <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard" }}>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to:`/manager/${linkcode}` }}  >
                Election Manager
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to:`/manage-voters/${linkcode}` }} active >
                Manage Voters
            </Breadcrumb.Item>
            
        </Breadcrumb>

        <Row className="bg-white">
        
        <Tabs
            defaultActiveKey="home"
            id="fill-tab-example"
            className="mb-3"
            fill
            >
            <Tab eventKey="home" className='bg-white'  title="Register Voters">
                <Row className='bg-white' >
                    <Tabs
                    defaultActiveKey="individual"
                    id="fill-tab-example-3"
                    className="bg-white"
                    
                    >
                    <Tab eventKey="individual" className='bg-white' title="Add individual voters">
                    <div className='mx-auto'><AddSingleVoter/></div>
                        
                    </Tab>
                    <Tab eventKey="bulk" className='bg-white'  title="Add Voters in bulk">
                        <AddMultipleVoters/>
                    </Tab>
                    
                </Tabs>
            
            </Row>
            </Tab>
            <Tab eventKey="profile" title="View Voters Register">
                <Row className="bg-white mt-2">
                    <h1 className="text-center text-muted p-3 m-1">List Of Voters</h1>
                    
                    {/* ///////////display a list of voters */}
                    <VotersList/> 
                                

                </Row>
            </Tab>
                       
            </Tabs>
           
        </Row>
        </Container>
        </Body>
    )
}

export default Voters;