import React ,{ useState ,useEffect} from "react";
import {arrayAdd, firestore,increment,timestamp} from '../../firebase';

import Hashids from 'hashids'



async function AddCandidate(data,img,userID,currentElection,portfolioId){
    const longhashids = new Hashids('mightyMan-Candidates',15)
    let error = ''
    let id="";
    let success=false;
    
    
        //references
        const createdAt = timestamp();
        
        // Create a reference to the SF doc.
        var MainCounters = firestore.collection("EC").doc("--Counter--");
        var election = firestore.collection("election").doc(currentElection.id);
        const portfolio = firestore.collection('portfolio').doc(portfolioId);
        const candidate = firestore.collection('candidates');
             console.log(userID)
        const getusers = firestore.collection("users").doc(userID);
        await firestore.runTransaction(async (transaction) => {
            var user =  await transaction.get(getusers);
            var ilect =  await transaction.get(election);
            var EC =  await transaction.get(MainCounters);
            
                    if(!user.exists){   
                                throw "User does not exist!";
                            }
                    if(!ilect.exists){   
                        throw "election does not exist!";
                    }

                    if(ilect.data().userId !== userID){  
                        throw "You are not admin for this election";
                    }
                    if(ilect.data().live){ 
                        throw "You can't edit this election now";
                    }

                // console.log(ilect.data())
                    
                        var newCount = (EC.data().candidateCount || 0) + 1;
                        console.log(newCount)
                        id = longhashids.encode(newCount);    

                        transaction.update(MainCounters, { candidateCount: newCount });
                        transaction.set(candidate.doc(id), {
                            img,
                            votes:0,
                            rejects:0,
                            name: data.name,
                            email: data.email,
                            electionId:currentElection.id,
                            portfolioId,
                            voters:[],
                            createdAt,
                        })

                        transaction.update(portfolio, {
                            candidateCount: increment,
                            candidate: arrayAdd.arrayUnion(id),
                        });
                        transaction.update(election, {
                            candidateCount: increment,
                            candidate: arrayAdd.arrayUnion(id)
                        });
                     success = true

                    return success;
 
            }).catch((err) => {
                    error = err;
                    console.error("Error adding election: ", error);
                    return error;
                });
        


       
    return {error,success}

}

export default AddCandidate;