import { Link } from "react-router-dom";
import styled from "styled-components";

export const ActiveButtonDeepBlue = styled.button`
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        line-height: 47px;
        color: #FFFFFF;
        background: #0B486B;
        box-shadow: 0px 8.10606px 18.2386px rgba(0, 0, 0, 0.2);
        border-radius: 43px;
`
export const ActiveButtonGreen = styled.button`
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        line-height: 47px;
        color: #FFFFFF;
        background: #2FA712;
        box-shadow: 0px 8.10606px 18.2386px rgba(0, 0, 0, 0.2);
        border-radius: 43px;
`;
export const ActiveButtonRed = styled.button`
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        line-height: 47px;
        color: #FFFFFF;
        background: #ED2727;
        box-shadow: 0px 8.10606px 18.2386px rgba(0, 0, 0, 0.2);
        border-radius: 43px;
`
export const ActiveButtonLightBlue = styled.button`
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        line-height: 47px;
        color: #FFFFFF;
        background: #15A3CF;
        box-shadow: 0px 8.10606px 18.2386px rgba(0, 0, 0, 0.2);
        border-radius: 43px;
`
export const ActiveButtonBoxBlue = styled.button`   
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        line-height: 47px;
        color: #FFFFFF;
        background: #1C6B98;
        border-radius: 4px;
        border:0px;

`
export const ActionLink = styled(Link)`
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        line-height: 31px;
        text-decoration:none;
        color: #0B486B;
        &:hover{
            text-decoration:none;
            color: #0B486B; 
        }

`
export const ActionBox = styled.div`
background: #1C6B98;
border-radius: 4px;
`;

export const PrimaryHighlight = styled.p`
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        line-height: 31px;
        text-decoration:none;
        color: #0B486B;
        &:hover{
            text-decoration:none;
            color: #0B486B; 
        }

`

export const H4LoginBlue = styled.h4`
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 47px;
        color: #0B486B;

        ` 
export const H4Light = styled.h4`
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 47px;
        color: #FFFFFF;
        ` 
export const TextInput = styled.input`
        background: #FFFFFF;
        box-shadow: 0px 8.10606px 18.2386px rgba(0, 0, 0, 0.2);
        border-radius: 26.3447px;
`;

export const TextLabel = styled.label`
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 26.3152px;
        line-height: 31px;
        color: #666464;
`;

export const Widgets = styled.span`
        background: #FFFFFF;
        box-shadow: 0px 4px 18.2386px rgba(0, 0, 0, 0.2);
        border-radius: 9px;
`;

export const WidgetText = styled.div`
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        color: #1C6B98;
        opacity: 0.6;
`;
