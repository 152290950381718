import React, { useState } from 'react'
import { Card,Row,Col ,Modal, Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import UpgradePricing from '../../views/Pricing/UpgradePricing'

export const UpgradeWidget =({show,handleClose,data})=>{



    return(
       
  <Modal show={show}  
  size="xl"
  aria-labelledby="contained-modal-title-vcenter"
  centered
  fullscreen ={true}
  backdrop="static" keyboard={false} onHide={handleClose}>
    <Modal.Header className='bg-success text-center' closeButton>
      <Modal.Title className="text-white text-center">Upgrade {data.name}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    
      <UpgradePricing/>  
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" className="rounded-pill" onClick={handleClose}>
        Close 
      </Button>
    </Modal.Footer>
  </Modal>
  )

}

