import React ,{ useState ,useEffect} from "react";
import {arrayAdd, firestore,increment,decrement,timestamp} from '../../firebase';

import Hashids from 'hashids'



async function DeleteElection(userID,currentElection){
   
    let error = ''
    let success=false;
    
    
        //references
        const createdAt = timestamp();
       
        // Create a reference to the SF doc.
        var MainCounters = firestore.collection("EC").doc("--Counter--");
        var election = firestore.collection("election").doc(currentElection.id);
        const portfolios = firestore.collection('portfolio')
        const users = firestore.collection('users').doc(userID);

        await firestore.runTransaction(async (transaction) => {
            var user =  await transaction.get(users);
            var ilect =  await transaction.get(election);
            var EC =  await transaction.get(MainCounters);
            
              if(!user.exists){   
                        throw "User does not exist!";
                    }
                    if(!ilect.exists){   
                        throw "election does not exist!";
                    } 
                    if(ilect.data().userId !== userID){ 
                        throw "You are not admin for this election";
                    }
                    if(ilect.data().live){ 
                        throw "You can't edit this election now, it is still live";
                    }
                    
                       
                    transaction.update(users, {
                        myElectionsCount: decrement,
                        myDeletedElections: arrayAdd.arrayUnion(currentElection.id),
                        myElections: arrayAdd.arrayRemove(currentElection.id)

                    })
                    transaction.update(election,{
                        isDeleted: true,
                    });
                        success = true

                    
 
                    }).then((success) => {
                        console.log("Transaction successfully committed!");
                        return success;
                    }).catch((err) => {
                    error = err;
                    console.error("Error adding election: ", error);
                    return error;
                });
        


       
    return {error,success}

}

export default DeleteElection;