
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Navigate,redirect as Redirect, Outlet, Route, Routes, useLocation, useNavigate, useNavigation } from "react-router-dom";
import NotVerifiedPage from "../../views/User/NotVerified";
import { useAuth } from "./AuthContext";

const PrivateRoute = ({children, ...rest}) => {
  const { currentUser,setnewufrom,getUser,userID } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading,setIsLoading]=useState(true);
 
 
  useEffect(() => {
    if (userID) {
      getUser(userID).then(() => {
        setIsLoading(false);
      });
    }
      setnewufrom(location.pathname);
  }, [location.pathname]);
  
  if (!currentUser) {
    navigate("/login", {state: { from: location.pathname }})        
    return  navigate ( "/login", {state: { from: location?.pathname || "/" }});
  }

  if (!currentUser.emailVerified) {
    return <NotVerifiedPage/>;
  }
  return <Outlet {...rest}>{children}</Outlet>;
};

export default PrivateRoute;
