import React ,{useEffect, useState} from 'react'
import logo from "../../../assets/img/logo.png"
import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table,Alert,Form,InputGroup,ProgressBar,Modal} from 'react-bootstrap';
import { BsPlus, BsPlusSquare } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../../components/Context/AuthContext';
import useFetchElectionByUserId from '../../../components/Hooks/useFetchElectionByUserId';
import ElectionList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
// material ui imports
// import { DropzoneArea } from "material-ui-dropzone";
import { firestore, storageRef } from '../../../firebase';
import ProgressHeader from './ProgressHeader';
import { DropzoneArea } from 'material-ui-dropzone';
import { useElectionContext } from '../../../components/Context/ElectionContext';
import UniqueCandidate from './UniqueCandidate';
import AddCandidateForm from './addCandidateForm';



const Candidates =({currentPortfolio,setTriger,setCurrentPortfolio,trigger})=>{
  var a = new Date();
  var b = a.getTime();  
  const [candidate,setCandidate] = useState([])
    const [data,setData] = useState([])
    const [success,setSuccess] = useState(false);
    const [error,setError] = useState("");
    const [initerror,setInitError] = useState("");
    const [initloading,setInitLoading] = useState(false);
    const [loading,setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [sec, setSec] = useState('');
    const [myTriger,setMyTrigger] = useState(trigger);
      
    const handleClose = () => {
      var a = new Date();
      var b = a.getTime();
      setShow(false)
       setMyTrigger(b)
    };


    const handleSuccess = async() => {
      
        var a = new Date();
      var b = a.getTime();
       setShow(false)
      await setTriger(b)
      setMyTrigger(b)

    }

    useEffect(()=>{
        setError('')
          async function getAllElections() {
                    setLoading(true)

            
            const elections = firestore.collection("portfolio").doc(currentPortfolio.portfolioId);
        
          await elections.get().then((snapshot)=>{
        
          if (snapshot.exists) {
                
            setCurrentPortfolio(snapshot.data())
            setLoading(false)

          }else{
            setLoading(false)
            throw "Error fetching portfolio"


          }
          
        }).catch ((err)=>{
            console.log(err)
              setError(err)
            setLoading(false)

            })
        }

          if (currentPortfolio.portfolioId){
                    getAllElections();}
          },[myTriger])

    // i get portfolioid 
    // get candidates by that id
    const AddCandidateModal=
          <>     
            <Modal show={show}  
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static" keyboard={false} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title className='portfolio-name text-center m-3'>Add Candidate</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <AddCandidateForm  closeModal={handleSuccess} portfolioId={currentPortfolio.portfolioId} />
                  {/* <Voteslip candidates={data.candidate}/> */}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" className="rounded-pill" onClick={handleClose}>
                  Close 
                </Button>
              </Modal.Footer>
            </Modal>
          </>;

    return(
    <Col  className="mx-auto">
    {/* <h4 className="text-success">Add Candidates</h4> */}
        <Row className="text-center ">

        <Row className="text-muted mx-auto border " >
          {AddCandidateModal}
        <h5 className=" portfolio-name text-center m-3">Candidates for {currentPortfolio && currentPortfolio.name}</h5>
        <Col className='text-center'>
                <a href='#' onClick={()=>setShow(true)} disabled={currentPortfolio.portfolioId ? false : true} class="col btn btn-info mx-auto m-2">Add New Candidate</a>

        </Col>
            
            <Row className='text-center'>
            {(error ) && <Alert variant="danger"><h3 className="text-muted mx-auto">{error}</h3></Alert>}
            {currentPortfolio.candidate ?
            (!currentPortfolio.candidate.length) && <Alert variant="danger"><span className=" mx-auto">{"No Candidates"}</span></Alert> :''
            }
            {(loading ) &&  <Spinner animation="grow" variant="success"/> }
            </Row>
            {/* <Col sx={8} sm={6} md={4} lg={4} className="text-center col-lg-4 "> */}

              {/* <Col  className="m-2   rounded-3 text-center ">
                <Card className="bg-transparent no-border" >
               
                
                <a href='#' onClick={()=>setShow(true)} disabled={currentPortfolio.portfolioId ? false : true} class="btn btn-info stretched-link">Add New Candidate</a>
                {/* <Button  className="btn-success" ><BsPlusSquare/> {' '}Add Candidates</Button> */}
                {/* </Card>
              </Col>  
          </Col> */}
            {currentPortfolio.candidate && currentPortfolio.candidate.map((data,i) => (
            <UniqueCandidate candidateId={data} setTriger={handleClose} key={i}/>
            )) 
            }
              
                    {/* <Button onClick={()=>setShow(true)} disabled={currentPortfolio.portfolioId ? false : true} className="btn-success" ><BsPlusSquare/> {' '}Add Candidates</Button> */}

            </Row>
        </Row>
       
   
    </Col> 
    )
} 

export default Candidates;