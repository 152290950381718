
export const electionpackages = {
free : {
    price: 0,
    type: 1,
    maxcount:80,
    maxcat:3,

},
pro : {
    price: 300,
    type: 2,
    maxcat:10,
    maxcount:500,

},
 plus :{
    price: 1000,
    type: 3,
    maxcat:15,
    maxcount:1000,

},
grand :{
    price: 3000,
    type: 4,
    maxcat:20,
    maxcount:5000,


}

}