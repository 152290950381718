import React ,{ useState ,useEffect} from "react";
import {arrayAdd, firestore,increment,timestamp} from '../../firebase';
import { useAuth } from "../Context/AuthContext";
// import { useAuth } from "../../../contexts/AuthContext";
import Hashids from 'hashids'



async function AddElection(data,img,userID){
    const hashids = new Hashids('',5)
    const longhashids = new Hashids('ILoveMIghty-Elections',15)
    let error = ''
    let id="";
    let code="";
    
    
    
        //references
        const createdAt = timestamp();
     
        var sfDocRef = firestore.collection("EC").doc("--Counter--");
        const elections = firestore.collection('election')
        const users = firestore.collection('users').doc(userID);
        
        await firestore.runTransaction(async (transaction) => {
            var ilect =  await transaction.get(users);
            var EC =  await transaction.get(sfDocRef);
            
              if(!ilect.exists){   
                        throw "User does not exist!";
                    }
                console.log(ilect.data())
                console.log(ilect.data().myElectionsCount)
                var newElectionCount = (ilect.data().myElectionsCount || 0 ) +1;  
                // if (ilect.data().pro || newElectionCount <= 3) {
                 
                
                    
                    
                        var newCount = EC.data().Count + 1;
                        console.log(newCount)

                        code=hashids.encode(newCount); 
                        id = longhashids.encode(newCount);    

                        transaction.update(sfDocRef, { Count: newCount });
                        transaction.set(elections.doc(id), {
                            ...data,
                            img,
                            ended:false,
                            state:2,
                            type: parseInt(data.type),
                            inProgress:false,
                            portfolio:[],
                            votersCast:[],
                            userId:userID,
                            createdAt,
                            id,
                            code
                        })
                        console.log(newCount)

                        transaction.update(users, {
                            myElectionsCount: increment,
                            myElections: arrayAdd.arrayUnion(id)
                        });

                    return id;

                

                // }else{
                //     error = "you have more than 3 elections and you are not pro"
                    
                //     throw error;
                // } 
            }).catch((err) => {
                    error = err;
                    console.error("Error adding election: ", error);
                    return error;
                });
        


       
    return {error,id}

}

export default AddElection;