import React ,{ useState ,useEffect} from "react";
import {arrayAdd, decrement, firestore,increment,timestamp} from '../../firebase';




async function RemoveCandidate(userID,currentElection,portfolioId,candidateId){
    let error = ''
    let id="";
    let success=false;
    
    
        //references
        const createdAt = timestamp();
        
        // Create a reference to the SF doc.
        var MainCounters = firestore.collection("EC").doc("--Counter--");
        var election = firestore.collection("election").doc(currentElection.id);
        const portfolio = firestore.collection('portfolio').doc(portfolioId);
        const candidate = firestore.collection('candidates').doc(candidateId);
             console.log(userID)
        const getusers = firestore.collection("users").doc(userID);
        await firestore.runTransaction(async (transaction) => {
            var user =  await transaction.get(getusers);
            var ilect =  await transaction.get(election);
            var EC =  await transaction.get(MainCounters);
            
              if(!user.exists){   
                        throw "User does not exist!";
                    }
                    if(!ilect.exists){   
                        throw "election does not exist!";
                    }

                    if(ilect.data().userId !== userID){  

                        throw "You are not admin for this election";
                    }
                    if(ilect.data().live){ 
                        throw "You can't edit this election now";
                    }

                console.log(ilect.data())
                    
                          


                        transaction.update(portfolio, {
                            candidateCount: decrement,
                            candidate: arrayAdd.arrayRemove(candidateId),
                        });
                        transaction.update(election, {
                            candidateCount: decrement,
                            candidate: arrayAdd.arrayRemove(candidateId)
                        });
                        transaction.delete(candidate)

                     success = true

                    return success;
 
            }).catch((err) => {
                    error = err;
                    console.error("Error deleting candidate: ", error);
                    return error;
                });
        


       
    return {error,success}

}

export default RemoveCandidate;