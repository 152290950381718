import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table, Toast,ToastContainer,Alert, Image,Form,Breadcrumb} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BecomeProWidget } from '../../../components/BecomeProWidget';
import { useAuth } from '../../../components/Context/AuthContext';
import useFetchElectionByUserId from '../../../components/Hooks/useFetchElectionByUserId';
import ElectionList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
import logo from "../../../assets/img/logo.png"
import useFetchElectionById from '../../../components/Hooks/useFetchElectionById';
import { useElectionContext } from '../../../components/Context/ElectionContext';
import { ResetElectionContext } from '../../../components/Hooks/Functions';
import SetOfline from '../../../components/Hooks/useSetOfline';
import SetLive from '../../../components/Hooks/useSetLive';
import SetClose from '../../../components/Hooks/useSetClose';
import DeleteElection from '../../../components/Hooks/useDeleteElection';
import { firestore } from '../../../firebase';
import ProgressHeader, { PaidProgressHeader } from '../Elections/ProgressHeader';
import { Publish } from '@material-ui/icons';
import SetPublishResults from '../../../components/Hooks/useSetPublishResults';
import SetUnPublishResults from '../../../components/Hooks/useSetUnPublishResults';
import SetPrivate from '../../../components/Hooks/useSetPrivate';
import SetPublic from '../../../components/Hooks/useSetPublic';
import ErrorTemplate from './ErrorTemplate';
import { FaLock } from 'react-icons/fa';
import { UpgradeWidget } from '../../../components/Modals/UpgradeWidget';
import { electionpackages } from '../../../electiondata';

const ManageElection =()=>{
    const {setNewElection,currentElection} = useElectionContext();
    const [electionData,setElectionData] = useState({})
    const [success,setSuccess] = useState(false);
    // const [level, setLevel] = useState(electionData.state);
    const [error,setError] = useState("");
    const [msg,setMsg] = useState("");
    const [copied,setCopied] = useState(false);
    const [loading,setLoading] = useState(false);
    const {userID,currentUser} = useAuth()
    const myhistory = useNavigate();
    const [show, setShow] = useState(false);
    const [showupgrade, setShowUpgrade] = useState(false);
   
    const {linkcode} = useParams();

 const  ResetElectionContext= async(id)=>{
            setLoading(true);
          
            const elections = firestore.collection("election").doc(id);
          
            await elections.get().then(async(snapshot)=>{
                if (!snapshot.exists){
                  
                
                  var error = "Election does not Exist!";
                  throw error;
          
                }
                await setNewElection(snapshot.data());
                await setElectionData(snapshot.data());
                console.log(snapshot.data())
                setLoading(false)
          
          
            }).catch((err)=>{
              console.log(err)
                setError(err);
                setLoading(false);

            })
          
          
           
          }
    
   

    useEffect(()=>{
       
        if (linkcode) {
            ResetElectionContext(linkcode);    
          
        }else{
    
            myhistory.replace('/dashboard')
        }
          
    },[linkcode])

    const FetchElection = async()=>{
        // console.log(userID)
        setLoading(true);

    const elections = firestore.collection("election").doc(linkcode);

   await elections.get().then((doc) => {
        if (doc.exists) {
                
                setElectionData(doc.data());
                setLoading(false);
                return;
            }else{
                    throw "Election doesn't exist";

                }
        }).catch((err)=>{
           setError(error); 
                setLoading(false);

        })     

}

        // FetchElection();
    
    
        const GoOffline = async()=>{
            setLoading(true)

                    // console.log(userID);
                    const {error,success} = await SetOfline(userID,electionData);
        
                    setError(error);
                    setSuccess(success);
                    setLoading(false)
                   
                    
                  
                   FetchElection();

            }
        const GoLive = async()=>{
                // console.log(userID);
                setLoading(true)

                const {error,success} = await SetLive(userID,electionData);
        
                setError(error);
                setSuccess(success);
                setLoading(false)
                
                FetchElection();

        }
        const Upgrade = async()=>{
                // console.log(userID);
                setLoading(true)

                const {error,success} = await SetLive(userID,electionData);
        
                setError(error);
                setSuccess(success);
                setLoading(false)
                
                FetchElection();

        }
        const GoPrivate = async()=>{
                // console.log(userID);
                setLoading(true)
                if (electionData.type === 1){
                    setError("Sorry, you can't go private on a free election, kindly upgrade to Pro")
                    setLoading(false)

                }else{
                   const {error,success} = await SetPrivate(userID,electionData);
                   setError(error);
                setSuccess(success);
                setLoading(false)
                
                FetchElection();
                }

        
                

        }
        const GoPublic = async()=>{
                // console.log(userID);
                setLoading(true)

                const {error,success} = await SetPublic(userID,electionData);
        
                setError(error);
                setSuccess(success);
                setLoading(false)
                
                FetchElection();

        }
        const GoPublish = async()=>{
                // console.log(userID);
                setLoading(true)

                const {error,success} = await SetPublishResults(userID,electionData);
        
                setError(error);
                setSuccess(success);
                setLoading(false)
                
                FetchElection();

        }
        const GoUnPublish = async()=>{
                // console.log(userID);
                setLoading(true)

                const {error,success} = await SetUnPublishResults(userID,electionData);
        
                setError(error);
                setSuccess(success);
                setLoading(false)
                
                FetchElection();

        }
        const GoClose = async()=>{
                // console.log(userID);
                setLoading(true)

                const {error,success} = await SetClose(userID,electionData);
        
                setError(error);
                setSuccess(success);
                setLoading(false)
                FetchElection();


        }
        const Delete = async()=>{
                // console.log(userID);
                setLoading(true)

                const {error,success} = await DeleteElection(userID,electionData);
        
                setError(error);
                setSuccess(success);
                setLoading(false)
                FetchElection();


        }

    function copyurl() {
        const el = document.createElement('input');
        el.value = "https://www.ilectcorp.com/election/"+electionData.code ;

        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setMsg("Link Coppied Successfully");
        setShow(true)
      }
    function copyInvite() {
        const el = document.createElement('input');
        el.value = "Hello,\n Follow the link below to partake in the ongoing \""+electionData.name+"\" \n  https://www.ilectcorp.com/election/"+electionData.code+" \n you can also use the election code : "+electionData.code+" to login at https://www.ilectcorp.com ";
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setMsg("Link Coppied Successfully");
        setShow(true)
      }

    
    function copyRegister() {
        const el = document.createElement('input');
        el.value = "https://www.ilectcorp.com/register-voter/"+electionData.id ;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setMsg("Link Coppied Successfully");
        setShow(true)
      }



      const TforToast=   
        <Toast position="top-end"
        aria-live="polite"
        aria-atomic="true"
        className="mytoasts"
        // style={{ minHeight: '240px' }}
        onClose={() => setShow(false)} show={show} delay={3000} autohide>
            <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
            <strong className="me-auto">Alert</strong>
            <small className="text-muted">just now</small>
            </Toast.Header>
            <Toast.Body>{msg}</Toast.Body>
        </Toast>
        
      const LauchedActions = 
      <>
      <UpgradeWidget show={showupgrade} handleClose={()=>setShowUpgrade(!showupgrade)} data={currentElection && currentElection}/>
      <Row className="m-3">
                     <Col md={6} lg={3} className="my-auto fw-bold text-dark"><span> Participants </span> </Col>

                     <Col className="text-success mx-auto my-auto">
                            <strong>{electionData && 
                            (electionData.private ? "Private":"Public")}</strong>
                            
                            
                        </Col>
                     <Col className="text-danger mx-auto my-auto ">
                          
                     <strong>
                                {(electionData.type === 1 )&& electionpackages.free.maxcount - ((electionData.votersCast && electionData.votersCast.length) || 0 ) }
                                {(electionData.type === 2) && electionpackages.pro.maxcount - ((electionData.votersCast && electionData.votersCast.length) || 0 ) }
                                {(electionData.type === 3 )&& electionpackages.plus.maxcount - ((electionData.votersCast && electionData.votersCast.length) || 0 ) } 
                                {(electionData.type === 4) && electionpackages.grand.maxcount - ((electionData.votersCast && electionData.votersCast.length) || 0 ) } 
                            
                             {" "}Participants remaining to upgrade</strong>
                            
                        </Col>
                    </Row>
      {electionData && ( electionData.private &&
      <Row className="m-3">
                     <Col md={6} lg={3} className="my-auto fw-bold text-dark"><span> Register </span> </Col>

                     <Col className="text-success mx-auto my-auto">
                            <strong>{electionData && 
                            (electionData.registeredVotersCount || 0 ) }{" "} Registered Voters</strong>
                            
                            
                        </Col>
                     <Col className="text-success mx-auto my-auto text-warning">
                     {/* <strong>
                                {(electionData.type === 1 )&& electionpackages.free.maxcount - ((electionData.votersCast && electionData.votersCast.length) || 0 ) }
                                {(electionData.type === 2) && electionpackages.pro.maxcount - ((electionData.votersCast && electionData.votersCast.length) || 0 ) }
                                {(electionData.type === 3 )&& electionpackages.plus.maxcount - ((electionData.votersCast && electionData.votersCast.length) || 0 ) } 
                                {(electionData.type === 4) && electionpackages.grand.maxcount - ((electionData.votersCast && electionData.votersCast.length) || 0 ) } 
                            
                             {" "}Participants remaining to upgrade</strong> */}
                            
                            
                        </Col>
                    </Row>)}
       <Row className="m-3">    

                        <Col md={6} lg={3} className="my-auto fw-bold text-dark"><span> Voting Link </span> </Col>
                        <Col className=" mx-auto my-auto overflow-auto">
                        
                           <span>https://www.ilectcorp.com/election/{electionData && electionData.code}</span>
                            
                        </Col>
                        <Col>
                        <Button className=" m-1" onClick={() => copyurl()}>{"Copy url"}</Button>
                        <Button className=" m-1" onClick={() => copyInvite()}>{"Copy invite"}</Button>
                        </Col>
                     </Row>
                     {electionData && 
                     (electionData.type >= 2 && electionData.private ? 
                     <Row className="m-3">    

                        <Col md={6} lg={3} className="my-auto  fw-bold text-dark"><span> Voter Registration Link </span> </Col>
                        <Col className=" mx-auto my-auto overflow-auto">
                        
                           <span>https://www.ilectcorp.com/register-voter/{electionData && electionData.id}</span>
                            
                        </Col>
                        <Col>
                        <Button className="m-1 " onClick={() => copyRegister()}>{copied ? "Copied":"Copy URL"}</Button></Col>
                     </Row>:"")}
                     
                     
                     <Row className="m-3">
                     <Col md={6} lg={3} className="my-auto fw-bold text-dark"><span> Type </span> </Col>

                     <Col className="text-success mx-auto my-auto">
                            <strong>{electionData && 
                            <span>{electionData.type === 1 && "free"}
                            { electionData.type === 2 && "Pro"} 
                            { electionData.type === 3 && "plus"}{ electionData.type === 4 && "grand"}</span>}</strong>
                            
                            
                        </Col>
                     <Col className="text- mx-auto my-auto">
                     {electionData && 
                        (electionData.type === 4 ? "":<Button onClick={()=>setShowUpgrade(true)}  >Upgrade</Button>)}
                            
                        </Col>
                     </Row>
                     <Row className="m-3">
                     <Col md={6} lg={3} className="my-auto fw-bold text-dark"><span> Status </span> </Col>

                     <Col className="text-success mx-auto my-auto">
                            <strong>{electionData && 
                            (electionData.live ? "live":"offline")}</strong>
                            
                            
                        </Col>
                     <Col className="text- mx-auto my-auto">
                     {electionData && 
                        (electionData.live ? <Button onClick={()=>GoOffline()} >Go Offline</Button>:<Button onClick={()=>GoLive()}  >Go Live</Button>)}
                            
                            
                        </Col>
                     </Row>
                     <Row className="m-3">
                     <Col md={6} lg={3} className="my-auto fw-bold text-dark"><span> Security </span> </Col>

                     <Col className="text-success mx-auto my-auto">
                            <strong>{electionData && 
                            (electionData.private ? "Private":"Open")}</strong>
                            
                            
                        </Col>
                     <Col className="text- mx-auto my-auto">
                     {electionData && 
                        (electionData.private ? <Button onClick={()=>GoPublic()} >Go Public</Button>:<Button onClick={()=>GoPrivate()}  >{electionData.type === 1 && <FaLock/>} Go Private</Button>)}

                        </Col>
                     </Row>
                     <Row className="m-3">
                     <Col md={6} lg={3} className="my-auto fw-bold text-dark"><span> Details </span> </Col>
                     <Col className="text mx-auto my-auto">
                            <LinkButton to={`/manage-preview/${linkcode}`}  className="btn  btn-small">Preview Details</LinkButton>
                            
                        </Col>
                     </Row>
                     
                     <Row className="m-3">
                        <Col md={6} lg={3} className="fw-bold text-dark">View Result</Col>
                        <Col className="text mx-auto my-auto">
                            <LinkButton to={`/manage-results/${linkcode}`}  className="btn  btn-small">Result & Charts</LinkButton>
                        </Col>
                     </Row>
                     <Row className="m-3">
                     <Col md={6} lg={3} className="my-auto fw-bold text-dark"><span> Publish Results</span> </Col>
                     <Col className="text-success mx-auto my-auto">
                            <strong>{electionData && 
                            (electionData.published ? "Published":"Not Published")}</strong>
                            
                            
                        </Col>
                     <Col className="text mx-auto my-auto">
                     {electionData && 
                            (electionData.published ? 
                             <Button className="btn-dark" onClick={()=>GoUnPublish()} >UnPublish Results</Button>

                            :<Button className="btn-primary" onClick={()=>GoPublish()} >Publish Results</Button>
                            
                            
                            )}
                        </Col>
                     </Row>
     </>
      

    return(
        <Body>
            
            <Container className="m-2 mx-auto align-content-sm-center container-fluid">
             {TforToast}
             <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard" }}>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:`/manager/${linkcode}` }} active >
                    Election Manager
                </Breadcrumb.Item>
                
            </Breadcrumb>
            
             <HeaderBanner className=" row  mx-auto my-auto p-2">
                    <HeaderBannerIcon md={2} lg={2}>
                        <Image src={electionData && electionData.img }  roundedCircle fluid/>
                    </HeaderBannerIcon>
                    <Col md={6} lg={8}>
                        <h5 className="portfolio-name">{electionData && electionData.name}</h5>
                        <p>Ilectcorp </p>
                    </Col>
                    <Col md={3} lg={2} className="text-end mx-auto my-auto">
                        {loading && <Spinner animation="border" variant="secondary"/>}
                    {electionData.launched && 
                        (electionData.live ? <Button onClick={()=>GoOffline()} >Go Offline</Button>:<Button onClick={()=>GoLive()}  >Go Live</Button>)}
                    </Col>
             </HeaderBanner>
             {(electionData && !error && !loading ) && 
                     (electionData.launched ? '':
                          <ProgressHeader level={electionData.state} className="m-3"/> 

                     )}
             
             

             {/* activity summary bar */}
             <Container className="bg-white p-2 m-auto mx-auto mt-2">
             {loading && <Spinner className="text-center my-auto mx-auto" animation="border" variant="success"/>}
             {(error ) && <div>
                                <Alert className="text-center" variant="danger">{error}</Alert>
                                {/* <ErrorTemplate error={error}/> */}
                            </div>}
             {(!error && !loading ) &&   <Container className="mx-lg-auto">
                     {/* <Row className="m-3">
                        <Col md={6} lg={3} className="my-auto"><h4> {electionData && electionData.name}</h4> </Col>
                        
                     </Row> */}
                     {electionData && 
                     (electionData.launched ? LauchedActions :
                     <Row className="m-3">
                        <Col md={6} lg={3}><strong>Add Portfolios & Candidates</strong> </Col>
                        <Col className="text mx-auto my-auto">
                            <LinkButton to={`/create-portfolio/${linkcode}`}  className="btn ">Portfolios & Candidates</LinkButton>
                        </Col>
                     </Row>
                     )}
                     {electionData && 
                     (electionData.type >= 2 && electionData.private ? 
                     <>
                        <Row className="m-3">
                            <Col md={6} lg={3} className="fw-bold text-dark"><strong>Voters</strong> </Col>
                            <Col className="text mx-auto my-auto">
                                <LinkButton to={`/manage-voters/${linkcode}`}  className="btn  btn-small">Manage voters</LinkButton>
                            </Col>
                        </Row>
                     </>
                     :''
                     )}
                    
                     
                     <Row className="m-3">
                     <Col md={6} lg={3} className="my-auto fw-bold text-dark"><span> Delete</span> </Col>
                     <Col className="text mx-auto my-auto">
                            <Button className="btn-secondary" onClick={()=>Delete()} >Delete</Button>
                            
                        </Col>
                     </Row>
                 {/* {!currentUser.pro && <BecomeProWidget/>} */}
                
                </Container>
                }

             </Container>



             {/* table for recent elections */}
    
           


        </Container>
    

    </Body>
    )
}


export default ManageElection;