import React, { useEffect, useState } from 'react';
import Chart from "react-google-charts";


import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table, Alert, Image,Form,Breadcrumb} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useAuth } from '../../components/Context/AuthContext';
import useFetchElectionByUserId from '../../components/Hooks/useFetchElectionByUserId';
import ElectionList from '../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../User/Dashboard/DashboardElement';
import logo from "../../assets/img/logo.png"
import useFetchElectionById from '../../components/Hooks/useFetchElectionById';
import { useElectionContext } from '../../components/Context/ElectionContext';
import { firestore } from '../../firebase';
import { has } from 'lodash'
import VerifyVoters from '../../components/Hooks/useVerifyVoters';


//visible header that contains LOGO and Navigation Icon
var d = new Date();
var n = d.getTime();

const RegisterVoter =()=>{

    const [files, setFiles] = useState(null);
    const [data, setData] = useState([]);
    const [code, setCode] = useState('');
    const [success, setSuccess] = useState(false);
    const [mainError, setMainError] = useState('');
    const [loading, setLoading] = useState(true);
    const [portfolioData,setPortfolio] = useState([]) // data for old portfolio
    const [level, setLevel] = useState(3);
    const [error, setError] = useState(''); // sets Error from operations
    const { userID } = useAuth()
    const myhistory = useNavigate();
    const {linkcode} = useParams();
    const [triger,setTriger] = useState(n) // a random value that trigerss a refresh for the useEffect hook
    const [election,setElection] = useState('');
    const {setCurrentElection,setCurrentVoterPro,currentElection,setNewElection} = useElectionContext();
   

        const  ResetElectionContext= async(id)=>{
            // setLoading(true);
          try {
              const elections = firestore.collection("election").doc(id);
          
            await elections.get().then(async(snapshot)=>{
                if (!snapshot.exists){
                  
                
                  var error = "No such election!";
                  throw error;
          
                }
                await setNewElection(snapshot.data());
                // console.log(snapshot.data())
                setLoading(false)
          
          
            }).catch((err)=>{
                throw err;
            })
          } catch (error) {
            console.log(error)

          }          
           
          }

          const GetElection= async()=>{
            setLoading(true);
            const elections = firestore.collection("voters").doc(currentElection.Provoters);
            // const elections = firestore.collection("voters").doc(currentElection.Provoters);
          
          await elections.get().then((doc) => {
            if (doc.exists) {
              var data =doc.data();
                console.log("election data: fetched", data.voters);
                if (has(data.voters, code)){
                
                    console.log("Id exists,", data.voters[code]);
                    // setCurrentVoterPro(code,data.voters[code])
                    setData(data.voters[code]);
                    setLoading(false);
                    // setCode('');
                    //myhistory.push('/portfolio');
    
                }else{
                    throw "ID or Email does not exists! ";
                }
        
            } else {
                    // doc.data() will be undefined in this case
                    setError("Election Code Invalid");
                    setLoading(false);
                    console.log("No such election!");
                    throw "No such election!";
                }
            }).catch((err)=>{
                console.log("------------ Error -------------");
                console.log(err);
                setError(err)
                setLoading(false);
    
            })
           
        }
          const GenerateCode= async()=>{
                
                setLoading(true);
                 const {success,error,} = await VerifyVoters(data,currentElection);
                 setSuccess(success);
                 setError(error);
                setLoading(false);

    
            
        }
          

        const Proceed =(event)=>{
            event.preventDefault();
    
            setError('')
            if(code){
                GetElection()
            }else(
                setError("please Enter an voters ID or Email to proceed")
            )
        }    
    useEffect(()=>{
       
          
          if (linkcode) {
            ResetElectionContext(linkcode);    

        }else{
    
            myhistory.replace('/')
        }
      
          },[linkcode])


    

    return(
        <Body>
            <Container className="col-12 col-md-5 m-2 mx-auto align-content-sm-center container-fluid flex-fill">
             {/*welcome Heading  */}
             
            {/* <Breadcrumb className="no-printme">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/portfolio" }}>Election Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/view-results" }} active>
                    Results
                </Breadcrumb.Item>
            </Breadcrumb> */}
             <HeaderBanner className="row mx-sm-0 mx-auto my-auto p-2 bg-light text-center">
                    
                    <Col className="col-12 col justify-content-center">
                        <HeaderBannerIcon  className='mx-auto'>
                            <Image src={currentElection && (currentElection.img || logo)} rounded fluid/>
                        </HeaderBannerIcon>

                    </Col>
                    
                    <Col className="col-12 ">
                       <h4>{currentElection && currentElection.name}</h4>
                    </Col>
                 
             </HeaderBanner>
             
             

             {/* activity summary bar */}
             <div className=" p-md-2 mx-auto text-center ro-bg-white">
             {currentElection && currentElection.live ?

               
                    <Alert variant="warning">Sorry: Voters Registration closed!</Alert>:
                    <div className="col-md-10 col-lg-8 col-xl-7 m4 p4 mx-auto">
                    {(loading && !error) && <Spinner variant="success" animation="border"/>}
                    {error && <Alert variant="warning">{error}</Alert>}
                    {success && <Alert variant="success">
                        <h4>{success}</h4>
                    </Alert>}
                    {data.length !== 0 ?
                       data.code ? 
                            <Container>
                                <Alert variant="warning">{"This Id is already registered!"}</Alert> 
                                <h5 className="text-danger">Contact the EC to claim ID , if you did not register it.</h5>
                            </Container>
                           
                           : <Container>
                            {!success && <h5 className="lead">is this you?</h5>}
                            <Row>
                                <Col sm={3} md={2} className="text-end my-auto">Name :</Col>
                                <Col className="text-start my-auto lead">{data.name}</Col>
                            </Row>
                            <Row>
                                <Col sm={2} md={2} className="text-end my-auto">Id :</Col>
                                <Col className="text-start my-auto lead">{data.id}</Col>
                            </Row>
                            {/* what to say if code denerated successfuly */}
                            {success ?                        
                                <p className="text-danger">
                                 NB: Please keep this code safe, this is the only time you will see it. 
                                 <br/>Without this code, you can not vote.
                                 </p>
                             :
                                    <div className="my-1">
                                        <p>Confirm to generate your Code</p>

                                    <Button className="primary-button" onClick={()=>GenerateCode()}>Yes, Confirm</Button> 
                                    </div>}
                        </Container>
                    :
                    <Form onSubmit={Proceed} >
                        <div className="form-row col-md-11 mx-auto">
                        <h5 className="text-dark m-3">
                            Enter your ID / Email and click <span className="text-success">"Proceed"</span> to verify your details
                        </h5>
                        <div className="input-group mb-3 col-12 ">
                            <input 
                            type="text"
                            value={code}
                            onChange={(e)=>setCode(e.target.value.replace(/\\|\//g,'').replace(".", "dot"))}
                            className="form-control center-align text-center "  
                            placeholder="Enter your ID here" 
                            aria-label="Election code" 
                            aria-describedby="basic-addon2"/>
                            
                            </div>
                            <div className="">
                                {/* <button className="btn btn-success" type="button">Proceed</button> */}
                                <Button className="btn btn-success" type="submit">Proceed</Button>
                            </div>
                        </div>
                    </Form>
                    }
                </div>
             }
             </div>
        </Container>
    </Body>
    )
}


export default RegisterVoter;