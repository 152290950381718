import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import Overlay from "./Overlay";
import Info from "./Info";
import How from "./How";
import Home from './Home';
import Pricing from '../Pricing/Pricing';

//visible header that contains LOGO and Navigation Icon

function Landing() {
    return(
        <div className="w-100 min-vh-100 m-0">
            <Home className="m-0 mx-0"/>
            {/* <How style={{heigth: "80vph" ,}}/> */}
            <Overlay className=""/>
            <Info/>

            <Pricing/>
        </div>
    );
}
export default Landing;