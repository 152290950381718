import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table,Alert,Form,InputGroup,ProgressBar} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../../components/Context/AuthContext';
import useFetchElectionByUserId from '../../../components/Hooks/useFetchElectionByUserId';
import ElectionList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
// material ui imports
// import { DropzoneArea } from "mat

const ProgressHeader = ({level})=>{
    const now = 25*level
    return(
    <Row>
        <Col sm={11} md={8} lg={7} className='mx-auto text-center'>
        <h4  className="  mx-auto portfolio-name">
            {level===1 && "Add Election Details"}
            {level===2 && "Add Portfolio & candidates"}
            {level===3 && "Preview"}
            {level===4 && "Publish"}
            </h4>

        {/* <ProgressBar striped animated= {true} variant={"success"} now={now} key={1} label={`${now}%`}  /> */}
        </Col>
        
    </Row>

    )
}

// export const PaidProgressHeader = ({level})=>{
//     return(
//     <Row>
//         <Col>
//         <label className="text-center">Details</label>
//         <ProgressBar striped animated= {level >= 1 ? true :false} variant={level >=  1 ? "warning" :"secondary"} now={100} key={1} /></Col>
//         <Col >
//         <label  className="strong my-auto mx-auto text-success">Configuration</label>
//         <ProgressBar  visuallyHidden striped animated = {level >=  2 ? true :false}  variant={level >=  2 ? "info" :"secondary"} now={100} key={2} />
//         </Col>
//         <Col >
//         <label  className="strong my-auto mx-auto text-success">Payment</label>
//         <ProgressBar  visuallyHidden striped animated = {level >=  3 ? true :false}  variant={level >=  3 ? "dark" :"secondary"} now={100} key={2} />
//         </Col>
//         <Col>
//         <label className="text-center">Publish</label>
//         <ProgressBar striped animated = {level >=  4 ? true :false}  variant={level >=  4 ? "success" :"secondary"} now={100} key={3} />
//         </Col>
//     </Row>

//     )
// }

export default ProgressHeader;