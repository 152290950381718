import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table, Alert, Image,Breadcrumb} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { useHistory, useParams ,Link} from 'react-router-dom';
import { BecomeProWidget } from '../../../components/BecomeProWidget';
import { useAuth } from '../../../components/Context/AuthContext';
import ElectionList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from './DashboardElement';
import logo from "../../../assets/img/logo.png"
import { firestore } from '../../../firebase';


const Dashboard =()=>{

    const [electionData,setElectionData] = useState([])
    const [success,setSuccess] = useState(false);
    const [error,setError] = useState("");
    const [loading,setLoading] = useState(false);
    const {userID,currentUser,userProfile} = useAuth()

        const FetchElection = async()=>{
            setLoading(true);
        
            let allelections = [];
                
            const elections = firestore.collection("election").where("userId","==",userID).limit(5);
        
            await elections.get().then((snapshot)=>{
            
            if (snapshot.empty) {
                throw "Election doesn't exist!";
            }
            snapshot.forEach((doc) => {        
                allelections.push(doc.data());
            })
            setElectionData(allelections);
            setLoading(false);
            }).catch ((err)=>{
                setError(err);
                setLoading(false);

            })
  
    } 

    useEffect(()=>{
        userID && FetchElection();
    },[userID]) 
    

    return(
        <Body>
            <Container className="m-0 m-lg-2 p-0 p-lg-2 mx-auto align-content-sm-center container-fluid">
             {/*welcome Heading  */}
             <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard" }} active>Dashboard</Breadcrumb.Item>      
            </Breadcrumb>
             <HeaderBanner className=" row  mx-auto my-auto p-2 m-0 m-lg-auto p-0 p-lg-auto">
                    <HeaderBannerIcon md={2} lg={2}>
                        <Image src={(userProfile && userProfile.photoUrl )|| logo} rounded fluid/>
                    </HeaderBannerIcon>
                    <Col md={6} lg={8}>
                        <span className='fw-bold'>Welcome, {userProfile && userProfile.firstName}</span>
                    </Col>
                    <Col md={3} lg={2} className="text-end mx-auto my-auto">
                        <LinkButton to="/dashboard/new-election" className="my-auto btn my-auto mx-auto"><BsPlus/> New Election</LinkButton>
                    </Col>
             </HeaderBanner>

             <Row className=" p-2 m-0 m-lg-auto mx-auto mt-2" >
                 
                 <Card  className=" bg-white col-md-5 shadow  p-2 m-auto m-2 mt-2  h-50">
                     <Row>
                        <Col md={12} lg={12} className="text-center mx-auto my-auto "><h1>{(userProfile && userProfile.myElectionsCount) || 0} Elections</h1></Col>
                        <Col className="text-center mx-auto my-auto">
                            <LinkButton to="myelections" className="btn  btn-small">View</LinkButton>
                        </Col>
                     </Row>
                 </Card>
                 <Card  className=" bg-white col-md-5 shadow  p-2 m-auto m-2 mt-2  ">
                     <Row>
                        <Col md={12} lg={12} className="text-center mx-auto my-auto"><h1>Profile</h1></Col>
                        <Col className="text-center mx-auto my-auto">
                            <LinkButton to="profiles" className="btn  btn-small">View</LinkButton>
                        </Col>
                     </Row>
                 </Card>
                 
                

             </Row>



             {/* table for recent elections */}
             {loading && <Spinner className="text-center my-auto mx-auto" animation="border" variant="success"/>}
             {(error ) && <Alert className="text-center" variant="danger">{error}</Alert>}
             {(!loading && !error) && <ElectionList elections={electionData}/>}


        </Container>
    

    </Body>
    )
}


export default Dashboard;