import { Col } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

/*rooot Elements*/
export const Sidebar = styled.div`
        background: #0B486B;
        min-height: 100% !important;
        `;
export const LogoBar = styled.div`
        background: #1C6B98;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 56px;
        left: 0px;
        top: 0px;
        color: #CFF09E;
        `;

export const OnlineIcon = styled.div`
        width: 17px;
        height: 17px;
        left: 142px;
        top: 266px;
        background: #CFF09E;
        `
export const Networkstatus = styled.div`
        
        color: #CFF09E;
        `
export const UserName = styled.h4`
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 22.7517px;
        line-height: 27px;
        color: #FFFFFF;        
`;
export const Navli = styled.li`
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 21px;
        text-decoration:none;
        list-style: none;
        color: #FFFFFF !important;
        &:hover {
        opacity: 1;
        }
        `;

export const Navlinks = styled(NavLink)`
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 1.0rem;
        line-height: 21px;
        text-decoration:none;
        list-style: none;
        color: #FFFFFF !important;
        padding:10px 10px;
        min-width:60px
        &:hover {
        opacity: 0.8;
        background: #1C6B98;
        border-left:4px solid #CFF09E;
        }
        &:active {
        opacity: 1;
        background: #1C6B98;
        border-left:4px solid #CFF09E;
        }
        `;

        export const MainDiv = styled.div`
        background: #E7E7E7;`;

                        
        export const Headbar = styled.div`
        background: #FFFFFF;
        `;
        export const AddressBar = styled.div`
        background: #FFFFFF;
        `;
        export const Chartbox = styled(Col)`
        background: #FFFFFF;
        box-shadow: 0px 8.10606px 18.2386px rgba(0, 0, 0, 0.2);
        border-radius: 26.3447px;
        padding:10px;
        `;
        export const Widgets = styled.div`
        background: #FFFFFF;
        box-shadow: 0px 8.10606px 18.2386px rgba(0, 0, 0, 0.2);
        border-radius: 26.3447px;
        `;
        export const TitleText = styled.h2`
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 37.3506px;
        line-height: 44px;
        color: #424242;        
`;
        export const WelcomeText = styled.p`
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 37.3506px;
        line-height: 44px;        
        color: #0B486B;

`;
        export const Hr = styled.hr`
        border: 1px solid #666464 !important;
        padding:0px;
        margin:0px;


`;