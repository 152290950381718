import { DropzoneArea } from 'material-ui-dropzone'
import React, { useRef, useState } from 'react'
import { Row ,Form, Col, InputGroup, Container, Breadcrumb, ProgressBar, Image} from 'react-bootstrap'
import { FaCogs,FaCog, FaHammer } from 'react-icons/fa'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../components/Context/AuthContext'
import { AddressBar, Chartbox, Headbar, Hr, MainDiv, TitleText, WelcomeText } from '../../../components/Elements/DashboardElemtents'
import { ActionLink, ActiveButtonDeepBlue, ActiveButtonGreen, ActiveButtonLightBlue, ActiveButtonRed, H4Light, H4LoginBlue,PrimaryHighlight } from '../../../components/Elements/PagesElement';
import { storageRef } from '../../../firebase'
import Myimage from "../../../assets/img/logo.png"

// import "./Dashboard.css";

export default function Profile() {
  const { userID,currentUser ,userProfile} = useAuth()
  const [mainError, setMainError] = useState('');
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(''); // sets Error from operations
  const [formError, setFormError] = useState([]); // check error from forms
  const [success,setSuccess]=useState(false)
  const [loading, setLoading] = useState(false);
  const passwordRef = useRef();
  const NewpasswordRef = useRef();
  const RepeatnewpasswordRef = useRef();
  const [progress, setProgress] = useState(0);
  const [img,setImg] =useState(null);


  const history = useNavigate();
  // const { login } = useAuth();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/dashboard" } };



 

 

  
 
  // async function handleSubmit(e) {
  //   e.preventDefault();

   

  //   try {
  //     setError("");
  //     setLoading(true);

  //     //   await login(emailRef.current.value, passwordRef.current.value);
  //       console.log("completed login, going to verify")

  //     //               await verifyUser();
  //     history.push(from);
  //   } catch (error) {
  //     setError(error.message);
  //   }

  //   setLoading(false);
  // }

  return (
    <div className='w-100 m-0'>
    <Headbar className='col-12 m-0 align-top sticky-top m-0 p-0'>
    <WelcomeText className='align-top'>{userProfile && userProfile.firstName +" "+userProfile.lastName}</WelcomeText>

    
    <Hr></Hr>
    <AddressBar className='p-3'>
        <TitleText className='p-0'>Profile</TitleText>
        <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard" }}>Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/profiles" }} active >
                Profiles
                </Breadcrumb.Item>
            
        </Breadcrumb>
    </AddressBar>
    </Headbar>
    <MainDiv className='row p-3'>
    <div className='text-center my-2'>
                  
                  <ActiveButtonLightBlue as={Link} to="/dashboard/profiles/edit"
                      disabled={loading}
                      variant="success"
                      className="primary-button text-center border-0 px-5 mx-2"
                      type="button"
                  >
                      Edit Profile
                  </ActiveButtonLightBlue>
                  <ActiveButtonDeepBlue
                  as={Link}
                  to="/dashboard/profiles/reset-password"
                      disabled={loading}
                      variant="success"
                      className="primary-button text-center border-0 px-5 mx-2"
                      type="button"
                  >   Reset Password
                  </ActiveButtonDeepBlue>
                </div>

              <div className='col-12 col-md-8 col-lg-7  mx-auto'>
                  <Form  className="row ">
                  <Form.Group className="row m-2" controlId="validationCustom07">
                    
                    <Col className="p-3 border-0  bg-gradient">
                    <div className=''>
                            <Image rounded height={200} width={200} src={img ? img :( currentUser.photoURL||Myimage) } className=" text-center  responsive-image"/>

                        </div>
                    </Col>
                </Form.Group>
                    
                    <Form.Group className='my-2 opacity-100'>
                      <Form.Label >First Name </Form.Label>
                     : {userProfile && userProfile.firstName}
                    </Form.Group>
                    <Form.Group className='my-2 opacity-100'>
                      <Form.Label >Last Name</Form.Label>
                    : {userProfile && userProfile.lastName}
                    </Form.Group>
                    <Form.Group className='my-2 opacity-100'>
                      <Form.Label >Date of Birth</Form.Label>
                    : {userProfile && userProfile.dob}
                    </Form.Group>
                    <Form.Group className='my-2 opacity-100'>
                        <Form.Label >
                        Gender
                        </Form.Label>
                        
                      : {userProfile && userProfile.gender}
                    </Form.Group>
                    <Form.Group className='my-2 opacity-100'>
                        <Form.Label >
                        Country
                        </Form.Label>
                        
                    : {userProfile && userProfile.country}
                    </Form.Group>
                    
                  
                   
                    <Form.Group className='col-12 col-md-12 my-2 opacity-100'>
                      <Form.Label >Telephone</Form.Label>
                    : {userProfile && userProfile.contact}
                    </Form.Group>


                    <Form.Group className='col-12 col-md-12 my-2 opacity-100'>
                      <Form.Label >Email Address </Form.Label>
                    : {userProfile && userProfile.email}
                    </Form.Group>                 

                    
                  
                   
                    
                  </Form>
              </div>
          
    </MainDiv>
  </div>
  )
}
