import React ,{forwardRef, useEffect, useState} from 'react'
import logo from "../../../assets/img/logo.png"
import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table,Alert,Form,InputGroup,ProgressBar,Modal} from 'react-bootstrap';
import { BsPlus, BsPlusSquare } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../../components/Context/AuthContext';
import useFetchElectionByUserId from '../../../components/Hooks/useFetchElectionByUserId';
import ElectionList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
// material ui imports
// import { DropzoneArea } from "material-ui-dropzone";
import { firestore, storageRef } from '../../../firebase';
import { DropzoneArea } from 'material-ui-dropzone';
import { useElectionContext } from '../../../components/Context/ElectionContext';
import BarChart from './Charts/BarChart';
import UniqueCandidateResult from './UniqueCandidateResult';
import { GoCircleSlash,GoStar } from 'react-icons/go';
import UniqueSingleCandidateResult from './UniqueSingleCandidateResult';
import BarChartSingle from './Charts/BarChartSingle';
import PieChartSingle from './Charts/PieChartSingle';
import PieChart from './Charts/PieChart';
// import UniqueCandidate from './UniqueCandidate';


const CandidatesResults = React.forwardRef(({currentPortfolio,setTriger},ref)=>{
    const [candidate,setCandidate] = useState([])
    const [data,setData] = useState([])

    const [success,setSuccess] = useState(false);
    const [error,setError] = useState("");
    const [initerror,setInitError] = useState("");
    const [initloading,setInitLoading] = useState(false);
    const [loading,setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [sec, setSec] = useState('');

    const UpdateData =(name,votes)=>{
        setData(data => ([...data, [name,votes]]))
      }
    const UpdateDataSingle =(name,votes,rejects)=>{
        setData(data => ([...data, [name,votes,rejects]]))
      }

    const handleClose = () => {
      var a = new Date();
      var b = a.getTime();
      setShow(false)

      

    };
    const handleShow = () => setShow(true);

    const handleSuccess = () => {
      
        var a = new Date();
      var b = a.getTime();
      setTriger(b)
       setShow(false)
      
      
    }

    // i get portfolioid 
    // get candidates by that id
    var sum=0
      var sumSingle=0

    var filtered = data.filter(function (el) {
        return (el !== [] || el !== undefined);
      });
     
     filtered.map((e)=>(sum=sum+e[1]))
      var filteredSingles = data.filter(function (el) {
        return (el !== [] || el !== undefined);
      });
      var filteredSingle = [...filteredSingles];

      filteredSingle.map((e)=>(sumSingle=sumSingle+e[1]+e[2]))
      useEffect(()=>{
        setData([])
      },[])
    return(
    <Col  ref={ref} className="mx-auto">
        <Row className="text-center">
    
            
        <h3 className="portfolio-name my-3 bg-gradient">{currentPortfolio && currentPortfolio.name}</h3>
        <span>{currentPortfolio && currentPortfolio.votesCast} Votes cast</span>
       {/* {sumSingle || sum} */}
        <hr/>
        <Row className="bg-gradient">

            {(error ) && <Alert variant="danger"><h3 className="text-muted mx-auto">{error}</h3></Alert>}
            {currentPortfolio.candidate ?
            (!currentPortfolio.candidate.length) && <Alert variant="secondary"><h3 className="text-muted mx-auto">{"No Candidates"}</h3></Alert> :''
            }
            
            

            {(loading && !error)   &&  <Spinner animation="grow" variant="success"/> }

            {/* updateData={updateData} */}
            
            <Container className="">
              {!currentPortfolio.candidate ? '':
                  currentPortfolio.candidate.length && filtered !== null ?   
                      '':'' 
                      } 
              
            {!currentPortfolio.candidate ? '':
            currentPortfolio.candidate.length !==1 && filtered !== null ?   
                  currentPortfolio.candidate.map((candata,i) => (
                    <UniqueCandidateResult preview={true} total={sum} candidateId={candata} key={i} UpdateData={UpdateData} />))
                      :
                    <UniqueSingleCandidateResult preview={true} total={sumSingle} candidateId={currentPortfolio.candidate[0]}  UpdateData={UpdateDataSingle} />

               }

            {!currentPortfolio.candidate ? '':
            currentPortfolio.candidate.length !==1 && filtered !== null ?   
                sum === currentPortfolio.votesCast ? <GoStar color="goldenrod" size="24"/>:<GoCircleSlash size="24"/>:
                sumSingle === currentPortfolio.votesCast ? <GoStar color="goldenrod" size="24"/>:<GoCircleSlash size="24"/>

            } 
            
            {!currentPortfolio.candidate ? '':
            currentPortfolio.candidate.length !== 1 && filtered !== null ? 
              (sum === 0 ? <Alert variant='secondary'>No data Available</Alert>:
              <div  className="row">
                <BarChart data={filtered} portfolio={currentPortfolio && currentPortfolio.name}/> 
                <PieChart data={filtered} portfolio={currentPortfolio && currentPortfolio.name}/> 
              </div>)
              :(sumSingle === 0 ? <Alert variant='secondary'>No data Available</Alert>:
              <div className="row">
              <BarChartSingle data={filteredSingles} portfolio={currentPortfolio && currentPortfolio.name}/>
              <PieChartSingle data={{...filteredSingle[0]}} portfolio={currentPortfolio && currentPortfolio.name}/>
              </div>)
            }
            </Container>
           
            
      

           
            </Row>
        </Row>
       
   
    </Col> 
    )
});

export default CandidatesResults;