import React, { useEffect, useState,useRef } from 'react';
import ReactToPdf from 'react-to-pdf';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button,Breadcrumb, Card , Table,Alert,Form,InputGroup,ProgressBar,Divider} from 'react-bootstrap';
import { BsPlus, BsPlusSquare,BsCone } from 'react-icons/bs';
import { GoRocket } from 'react-icons/go';
import {Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../components/Context/AuthContext';
import useFetchElectionByUserId from '../../../components/Hooks/useFetchElectionByUserId';
import ElectionList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
// material ui imports
// import { DropzoneArea } from "material-ui-dropzone";
import { firestore, storageRef } from '../../../firebase';
import { DropzoneArea } from 'material-ui-dropzone';
import { GetElectionforEC } from '../../../components/Hooks/Functions';
import { useElectionContext } from '../../../components/Context/ElectionContext';

import CandidatesPreview from '../Elections/ListCandidatesPreview';
import { IsoRounded, Launch } from '@material-ui/icons';


//visible header that contains LOGO and Navigation Icon
var d = new Date();
var n = d.getTime();

const ViewPortfolio =(props)=>{
     // files to upload
     const componentRef = useRef();

  const [files, setFiles] = useState(null);
  const [data, setData] = useState([]);
  const [code, setCode] = useState('');
  const [success, setSuccess] = useState(false);
  const [mainError, setMainError] = useState('');
  const [loading, setLoading] = useState(true);
  const [portfolioData,setPortfolio] = useState([]) // data for old portfolio
  const [level, setLevel] = useState(3);
  const [error, setError] = useState(''); // sets Error from operations
  const { userID } = useAuth()
  const myhistory = useNavigate();
  const {linkcode} = useParams();
  const [currentElection,setElectionData] = useState({})
  const [triger,setTriger] = useState(n) // a random value that trigerss a refresh for the useEffect hook

if(!linkcode){
    myhistory.push(`/dashboard`)
}


    
   

    useEffect(()=>{
        const getPortfolios =async()=>{
        try {
              setLoading(true)
              let allportfolios =[];
      
              const portfolios = firestore.collection('portfolio').where("electionId", "==", (linkcode));
              const snapshot = await portfolios.get();
            
            if (snapshot.empty) {
              console.log("empty")
              setError("No Portfolio")
              setLoading(false)
                throw "No Portfolio";

             
            }else{
               snapshot.forEach((doc) => {
              allportfolios.push(doc.data());
             
            }); 
            setLoading(false)
            }
            setPortfolio(allportfolios);
          }  catch (error) {
              console.log(error)
              setError("No Portfolio")
            }
        }
          


              const  ResetElectionContext= async(id)=>{
                  setLoading(true);
                
                  const elections = firestore.collection("election").doc(id);
                
                  await elections.get().then(async(snapshot)=>{
                      if (!snapshot.exists){
                        
                      
                        var error = "No such election!";
                        throw error;
                
                      }
                      await setElectionData(snapshot.data());
                      console.log(snapshot.data())
                        getPortfolios();  

                      setLoading(false)

                  }).catch((err)=>{
                    console.log(err)
                  })
                
                
                 
                }
              if (linkcode) {
                  ResetElectionContext(linkcode);  
                
              }else{
          
                  myhistory.replace('/dashboard')
              }
                
          },[linkcode])

    return(
        
            <Body className="mx-auto">
            <Container className="mt-4 mb-4 my-auto">
            
            <Breadcrumb className="no-printme">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard" }}>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:`/manager/${linkcode}` }} >
                    Election Manager
                </Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:`/manage-preview/${linkcode}` }}active>
                    Election Preview
                </Breadcrumb.Item>
            </Breadcrumb>
                {(loading && !currentElection) && <Spinner animation="border" variant="success"/>}
                {(loading ) && <Spinner animation="border" variant="success"/>}
               {(!success ) &&  <h3 className="text-center my-3 text-success">{currentElection && currentElection.name}</h3>}
            <Col className="mx-auto ">
               

                    {/* <h4 className="text-success text-center">Preview Election and Launch</h4> */}
                    {(!success ) && <p className="text-center">
                        <strong className="text-secondary text-center "> Election overview </strong>
                    </p>}
                    <Row className="">
                   
                    <div className="m-lg-1 no-printme" >
                        {/* <Col  className="my-auto text-center"><h4> {currentElection && currentElection.name}</h4> </Col> */}
                                       <button className="primary-button " onClick={() => window.print()}>PRINT</button>

                     </div>
                        
                        <Container ref={componentRef} >
                        {(error ) && <Alert variant="danger"><h3 className="text-muted mx-auto">{error}</h3></Alert>}
                        
                        {(loading && !error)  &&  <Spinner animation="grow" variant="success"/> }

                            {(portfolioData && !success && !error) && portfolioData.map((data,i) => ( 
                                <Row className="shadow m-2 mb-4 bg-white"  >
                                    {/* <ListPorfolioPreview key={data.portfolioId} setCurrentPortfolio={setCurrentPortfolio} data={data} /> */}
                                    <CandidatesPreview  setTriger={setTriger} currentPortfolio={data}/>
                                </Row>
                            )) 
                            }

                            
                            
                            
                        </Container>

                    </Row>
                
            </Col>
            
           
            </Container>
            </Body>
          

    )
}

export default ViewPortfolio;