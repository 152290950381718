import React from "react";
import {  Navigate, Outlet, redirect } from "react-router-dom";
import { useElectionContext } from "./ElectionContext";

const ElectionPrivateRoute = ({ children, ...rest }) => {
  const { currentElection, voter } = useElectionContext();

  return (
    (currentElection && voter) ? (
      <Outlet {...rest}>
        {children}
      </Outlet>
    ) : (
      <Navigate to="/election" />
  ));
};

export default ElectionPrivateRoute;
