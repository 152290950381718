import React from 'react'
import { Col, Container, Image, Nav, Navbar, Row } from 'react-bootstrap'
import { NavLink, Outlet, useNavigate,useNavigation } from 'react-router-dom'
import { LogoBar, Networkstatus, OnlineIcon, Sidebar, UserName,Navli,Navlinks, StyledBody, Menu } from './RootElemtents'
import Myimage from "../../../assets/img/logo.png"

import "../Dashboard/Dashboard.css";
import { BsFillCircleFill } from "react-icons/bs";
import { FaChevronRight,FaChevronLeft,FaDoorOpen,FaUser,FaBars, FaPlusSquare, FaBoxes, FaBrain, FaServer } from "react-icons/fa";
import { useAuth } from '../../../components/Context/AuthContext'





export async function  loader (){
    await setTimeout(function(){
    
   }, 5000);
   return null;
}

export default function RootExternal() {
    const navigation = useNavigation();
    const {logout,currentUser} = useAuth();
const SidebarComponent =
    <div className='row m-0 p-0'>
        <Nav className='row m-0 p-0'>
            <Navlinks to={`/dashboard/profiles`} className="p-0">
            <LogoBar className="col m-0 p-2 text-center">
                <Image width={50} roundedCircle src={Myimage} className="mx-auto text-center  responsive-image"/>  </LogoBar>
                </Navlinks>
                
            <Navlinks to={`/dashboard/profiles`} className="p-0">
                <div className='text-center m-2'>
                    <Image roundedCircle src={currentUser && currentUser.photoURL} className="mx-auto text-center imageFrame responsive-image"/>

                </div>
                <UserName className='text-center mt-2'>
               { currentUser && currentUser.displayName ? currentUser.displayName : "User" }
                </UserName>
                <div>
                    
                        
                            {
                                window.navigator.onLine ? 
                                <Networkstatus status={window.navigator.onLine } className='text-center mt-2' >
                                    <BsFillCircleFill/> <span>Online </span> 
                                </Networkstatus>
                                :
                                <Networkstatus status={window.navigator.onLine } className='text-center mt-2' >
                                    <BsFillCircleFill/> <span>Offline </span> 
                                </Networkstatus>

                            }
                            
                        
                </div>
                
            </Navlinks>
            </Nav>
            <Nav className='row m-0 p-0 '>

            <Navlinks to={`/dashboard`} end>
                    <FaBoxes/>
                    <span className='px-1'>Dashboard</span>
            </Navlinks>
            <Navlinks to={`/dashboard/new-election`}>
                <FaPlusSquare/>
                <span className='px-1'>Create Election</span> 
            </Navlinks>
           
            <Navlinks to={`/dashboard/manager`}>
                <FaServer/>
                <span className='px-1'>Manage Elections</span> 
            </Navlinks>
            <Navlinks to={`/dashboard/profiles`}>
                <FaUser/>
                <span className='px-1'>My Profile</span> 
            </Navlinks>
            <Navlinks className="mb-1 position-absolute fixed-bottom" to={"/login"} onClick={()=>logout()}>
                <FaDoorOpen/>
                <span className='px-1'>logout</span> 
            </Navlinks>
        </Nav>
    </div>
  return (
    <div className=''>
        <Row className='m-0 p-0 '>
        <div className='row text-end m-0'>
            <Menu className="fw-bold mx-auto position-fixed pull-right px-3 m-0 mb-1 text-black d-block d-md-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
            Menu{" "}
            <FaBars/>
            </Menu>
            </div>
            <Sidebar className='col-4 col-md-3 col-lg-2 h-100 p-0 m-0 sidebar d-none d-md-block position-fixed '>
                 {SidebarComponent}
            </Sidebar>


            <div className=''>

                <div className="offcanvas offcanvas-start p-0" tabindex="-1" data-bs-scroll="true" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasExampleLabel">Menu</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body p-0 m-0 ">
                <Sidebar className='col-12 h-100 p-0 m-0 sidebar'>
                        {SidebarComponent}
                </Sidebar>
                </div>
                </div>
                </div>
            <StyledBody className="col-12 col-md-9 col-lg-10 offset-md-3 offset-lg-2">
            <Outlet />
            </StyledBody>
        </Row>
    </div>

  )
}
