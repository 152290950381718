import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table,Alert,Form,InputGroup,ProgressBar} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../components/Context/AuthContext';
import useFetchElectionByUserId from '../../../components/Hooks/useFetchElectionByUserId';
import ElectionList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
// material ui imports

import { storageRef } from '../../../firebase';
import ProgressHeader from './ProgressHeader';
import { DropzoneArea } from 'material-ui-dropzone';
import AddElection from '../../../components/Hooks/useAddElection';
import { VscArrowRight } from 'react-icons/vsc';
import PayButton from '../../../components/PayButton';
import { electionpackages } from '../../../electiondata';


const NewElectionPlus =(props)=>{
const [ready, setReady] = useState(false);
const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState(null);
  const [data, setData] = useState({"type":3,"price":electionpackages.plus.price});
  const [img,setImg] =useState(null);
  const [code, setCode] = useState('');
  const [mainError, setMainError] = useState(''); // error from process loading or adding election
  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(0);
  const [level, setLevel] = useState(1);
  const [progressImg, setProgressImg] = useState(0);
  const [error, setError] = useState([]); // form error mainly upload.
  const { userID } = useAuth() // user auth context
  const myhistory = useNavigate();

  const UpdateData =(item,value)=>{
    setData(data => ({...data, [item]:value}))
  }
  

    const eventHandler = (event) => {
        let nam = event.target.name;
        if(nam === 'type'){
        let val = parseInt(event.target.value,10);
        UpdateData(nam, val);

        }else{
        let val = event.target.value;
        UpdateData(nam, val);

        }

    };
    
    const findFormErrors = () => {
        const newErrors = {}
        // name errors
        if ( !data.name || data.name === '' ) newErrors.name = 'cannot be blank!'
        else if ( data.name.length > 100 ) newErrors.name = 'Name is too long!'
       
        if ( !data.type || data.type === '' ) newErrors.type = 'choose an election type!'
        if ( !img || img === '' ) newErrors.img = 'Add a Picture!'
        

        return newErrors;
    }

    const Submit = async (url)=>{
         const {id,error,} = await AddElection(data,url,userID);
         setCode(id);
         setMainError(error);
    }

    
    const handleChange = (File) => {
  
        const file = File[0];
        setImg(file) 
        setError(error => ({...error, img:''}))

    }


    const uploadImage = () => {
        // Uploading to firebase storage
        // and updating URLS array for storage in firestore
        setLoading(true)

        const file = img;
        console.log(file)
        if(file){
        var d = new Date();
        var n = d.getTime();
        const filename = n+file.name;
        // references
    
        
        const storeVideoRef = storageRef.child(`/images/elections/${filename}`);
        
        storeVideoRef.put(file).on('state_changed', (snap) => {
        let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        setProgress(parseInt(percentage));

        }, (err) => {
        setError(err);
        }, async () => {
        const url = await storeVideoRef.getDownloadURL();
        
        Submit(url)

        });
    }
        

    }

  
    
    const Proceed = (event) => {
        event.preventDefault();
    //  const form = event.currentTarget;
    //   if (form.checkValidity() === false) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //   }
    const newErrors = findFormErrors()
        // Conditional logic:
        if ( Object.keys(newErrors).length > 0 ) {
        // We got errors!
        setError(newErrors)
        } else {
        // No errors! Put any logic here for the form submission!
        setReady(true)
        // uploadImage();
            // continue with other rendering
        }
        
    };

    useEffect(()=>{

        if(code){
            console.log(code)
            console.log("redirecting")
            myhistory('/setup-election/'+code)

        }

    },[code])

    return(
        
            <Container className="mx-auto">
                <h3 className="text-center m-3">New Election - Plus</h3>
                <ProgressHeader level={level} className="m-3"/>
            <Row className="mt-4 mb-4 my-auto">

            <Col md={8} className="mx-auto">
                {mainError && <Alert variant="danger">{mainError}</Alert>}
                { ready ? 
                                <PayButton price={electionpackages.plus.price} submit={()=>uploadImage()}/>
                :
                <Form noValidate validated={validated} onSubmit={Proceed} inline>

                {/* election TITLE */}
                <Form.Group className="row" controlId="validationCustom01">
                    <Form.Label className="col-3 align-bottom text-end mx-auto">
                    Election Name
                    </Form.Label>
                    <InputGroup className="form-input col">

                    <Form.Control
                    className=""
                    type="text"
                    name="name"
                    required ={true}
                    value={data.name}
                    onChange={eventHandler}
                    isInvalid={ !!error.name }
                    />
                    <Form.Control.Feedback type="invalid">
                {error.name}
                </Form.Control.Feedback>
                </InputGroup>
                </Form.Group>
              
             
          
                {/* COURSE Price */} 
                
                {/* INTRO VIDEO */}
                <Form.Group className="row m-2" controlId="validationCustom07">
                    <Form.Label className="col-3 align-bottom my-auto text-end">
                    Election Logo
                    </Form.Label>
                    <Col>
                    {progress !== 0 && <ProgressBar label={progress +'%'} striped now={progress} />}
                    <DropzoneArea

                        acceptedFiles={["image/*"]}
                        dropzoneText={"Upload Election Image"}
                        onChange={(files) => handleChange(files)}
                        maxFileSize={300000000}
                        filesLimit={1}
                    />
                {error.img && <div className="alert-danger">{error.img} </div>}
                
                    </Col>
                </Form.Group>
           
                <Col className="text-center">
                {(loading && !mainError)   &&  <Spinner animation="border" variant="dark"/>}
                    <Button
                    type="submit"
                    className=" btn-success text-center btn my-4 font-weight-bold atlas-cta cta-blue mx-auto"
                    >
                    Proceed <VscArrowRight />
                    </Button>

                </Col>
                </Form>
                }
            </Col>
            </Row>
            </Container>
          

    )
}

export default NewElectionPlus;