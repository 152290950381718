import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { Toast, ToastBody, ToastHeader } from 'react-bootstrap';

function ToastMessage({ message, duration }) {
  const [show, setShow] = useState(true);

  setTimeout(() => setShow(false), duration);

  return (
    
    <Toast onClose={() => setShow(false)} show={show} delay={duration} position={"bottom-center"} autohide>
      <ToastHeader>
        Toast Message
      </ToastHeader>
      <ToastBody>
        {message}
      </ToastBody>
    </Toast>
  );
}

function toast(message, duration = 3000) {
  const container = document.createElement('div');
  document.body.appendChild(container);

  const root = createPortal(
    <ToastMessage message={message} duration={duration} />,
    container
  );

  return root;
}

export default toast;
