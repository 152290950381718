import React, { useEffect, useState } from 'react';
import Chart from "react-google-charts";


import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table, Alert, Image,Form,Breadcrumb} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useAuth } from '../../components/Context/AuthContext';
import useFetchElectionByUserId from '../../components/Hooks/useFetchElectionByUserId';
import ElectionList from '../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../User/Dashboard/DashboardElement';
import logo from "../../assets/img/logo.png"
import useFetchElectionById from '../../components/Hooks/useFetchElectionById';
import { useElectionContext } from '../../components/Context/ElectionContext';
import { ResetElectionContext } from '../../components/Hooks/Functions';
import { firestore } from '../../firebase';
import CandidatesResults from '../User/Manage/ListCandidatesResult';


//visible header that contains LOGO and Navigation Icon
var d = new Date();
var n = d.getTime();

const ShowResults =()=>{

    const [files, setFiles] = useState(null);
    const [data, setData] = useState([]);
    const [code, setCode] = useState('');
    const [success, setSuccess] = useState(false);
    const [mainError, setMainError] = useState('');
    const [loading, setLoading] = useState(true);
    const [portfolioData,setPortfolio] = useState([]) // data for old portfolio
    const [level, setLevel] = useState(3);
    const [error, setError] = useState(''); // sets Error from operations
    const { userID } = useAuth()
    const myhistory = useNavigate();
    const {linkcode} = useParams();
    const {currentElection,setNewElection} = useElectionContext();
    const [triger,setTriger] = useState(n) // a random value that trigerss a refresh for the useEffect hook
      
   

        const  ResetElectionContext= async(id)=>{
            // setLoading(true);
          
            const elections = firestore.collection("election").doc(id);
          
            await elections.get().then(async(snapshot)=>{
                if (!snapshot.exists){
                  
                
                  var error = "No such election!";
                  throw error;
          
                }
                await setNewElection(snapshot.data());
                console.log(snapshot.data())
                setLoading(false)
          
          
            }).catch((err)=>{
              console.log(err)
            })
          
          
           
          }
          
    
    useEffect(()=>{
        const getPortfolios =async()=>{
        try {
              setLoading(true)
              let allportfolios =[];
      
              const portfolios = firestore.collection('portfolio').where("electionId", "==", (linkcode));
              const snapshot = await portfolios.get();
            
            if (snapshot.empty) {
              console.log("empty")
              setError("No Portfolio")
              setLoading(false)
                throw "No Portfolio";

             
            }else{
               snapshot.forEach((doc) => {
              allportfolios.push(doc.data());
             
            }); 
            setLoading(false)
            }
            setPortfolio(allportfolios);
          }  catch (error) {
              console.log(error)
              setError("No Portfolio")
            }
        }
          
          if (linkcode) {
            ResetElectionContext(linkcode);    

            getPortfolios();

        }else{
    
            myhistory.replace('/')
        }
      
          },[linkcode])

    return(
        <Body>
            <Container className="m-2 mx-auto align-content-sm-center container-fluid flex-fill">
             {/*welcome Heading  */}
             
            <Breadcrumb className="no-printme">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/portfolio" }}>Election Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/view-results" }} active>
                    Results
                </Breadcrumb.Item>
            </Breadcrumb>
             <HeaderBanner className=" row mx-sm-0 mx-auto my-auto p-2">
                    
                    {/* <Col sm={2} md={2} lg={2}>
                        <HeaderBannerIcon >
                        <Image src={currentElection && (currentElection.img || logo)} rounded fluid/>
                    </HeaderBannerIcon>

                    </Col> */}
                    
                    <Col className="text-center">
                    <Image className="election-logo-head" src={currentElection && (currentElection.img || logo)} rounded fluid/>
                    {/* <Image className="election-logo-head" src={currentElection && ( logo)} rounded fluid/> */}
                        <h4>{currentElection && currentElection.name}</h4>
                    </Col>
                 
             </HeaderBanner>
             
             

             {/* activity summary bar */}
             <div className=" p-md-2 mx-auto text-end">

                     <div className="m-lg-1 no-printme" >
                        {/* <Col  className="my-auto text-center"><h4> {currentElection && currentElection.name}</h4> </Col> */}
                                       <button className="primary-button " onClick={() => window.print()}>PRINT</button>

                     </div>
                     <div className="mx-auto text-center">
                     {(error ) && <Alert variant="danger"><h3 className="text-muted mx-auto">{error}</h3></Alert>}
                        
                        {(loading && !error)  &&  <Spinner className="mx-auto" animation="border" variant="success"/> }
                        {currentElection && (currentElection.published ? 
                        (
                            (portfolioData && !success && !error) && portfolioData.map((data,i) => ( 
                                    <div className="mx-auto ro-bg-white mt-2">

                                        <CandidatesResults setTriger={setTriger} currentPortfolio={data}/>
                                    </div>
                                    ))
                            ) :
                            <Alert variant="danger"><p className="lead">{"Sorry - This election's results has not been made public yet, kindly check later!"}</p></Alert> 
                            )}
                            

                               
                     </div>
                   

             </div>



             {/* table for recent elections */}
    


        </Container>
    

    </Body>
    )
}


export default ShowResults;