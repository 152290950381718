import React from 'react';
import { PaystackButton } from 'react-paystack';
import { useAuth } from './Context/AuthContext';

  

  const PayButton = ({price,submit}) => {
    const {currentUser} =useAuth()
    const handlePaystackSuccessAction = async(reference) => {
      
      submit(reference)
      console.log(reference);
  
    };
  
    // you can call this function anything
    const handlePaystackCloseAction = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
      console.log("closed");
    }; 
    const config = {
      reference: (new Date()).getTime().toString(),
      currency: "GHS",
      email: currentUser.email,
      amount: 100* price,
      // publicKey: "pk_live_77083c72c9f06630019def9dd201de6d9ceda0e7",
      publicKey: "pk_test_616538abc71f69df481117b096611e10e1e1882c",
    };
  
    const componentProps = {
      ...config,
      text: "Pay Ghs"+price+" to proceed",
      onSuccess: (reference) => handlePaystackSuccessAction(reference),
      onClose: () => handlePaystackCloseAction(),
  
    };
  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
  }
      return (
           <PaystackButton
                            className="paystack-button mx-auto "
                            {...componentProps}
                        />
            
      );
  };

  export default PayButton;
  