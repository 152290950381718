import React, { Fragment } from 'react'
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import "./index.css";
import './App.css';
import Myrouter from "./MainRouter";
import { AuthProvider } from "./components/Context/AuthContext";

function AppTest() {
  return (
      <Fragment>
        <AuthProvider>

        <RouterProvider router={Myrouter}/>
        </AuthProvider>
      </Fragment>
  );
}

export default AppTest;
