import React, { useState } from 'react';
import { Row,Container,Col } from 'react-bootstrap';
import { FaUserFriends } from "react-icons/fa";
import {  RiFlashlightLine,RiShieldFlashLine,RiCheckboxCircleLine,RiWalletLine } from "react-icons/ri";
import { WiLightning } from "react-icons/wi";
import { GrSecure } from "react-icons/gr";

//visible header that contains LOGO and Navigation Icon
import "./Info.css";
import "../../assets/css/custom.css";
import fastimg from "../../assets/img/undraw_fast_car_p4cu.svg";
import easytouse from "../../assets/img/undraw_Usability_testing_re_uu1g.svg";
import secure from "../../assets/img/undraw_Security_on_re_e491.svg";
import feature1 from "../../assets/img/feature-1.png";
import feature2 from "../../assets/img/feature-2.png";
import feature3 from "../../assets/img/feature-2.png";
import { TitleText } from '../User/Root/RootElemtents';


function Info() {
    return(
            <div className="mt-5">
            <div className="my-lg-2 row m-0">
            
                <Col sm={12} md={9} className="text-center container mx-auto w-lg-50">
                     
                <div class="container m-0 my-5">
                    <TitleText class="text-center mb-5 animated fadeInDown">About Us</TitleText>
                    <p class="text-justify animated fadeInUp"><span className='fw-bolder'>IlectCorp</span> is a leading provider of election technology solutions. Our mission is to support fair and accurate elections by providing secure and reliable technology to election officials. Our tagline, "The trusted election aid," reflects our dedication to this mission.</p>
                    <p class="text-justify animated fadeInUp">With years of experience in the industry, our team of experts is dedicated to helping election officials conduct successful elections. We understand the unique challenges faced by election officials and work closely with them to design and implement customized election plans.</p>
                    <p class="text-justify animated fadeInUp">Our comprehensive suite of election management tools includes voter registration databases, ballot design and printing, voting equipment and technology, vote tabulation, and results reporting. Our technology is designed to meet the highest standards for security and reliability and is independently audited to ensure the integrity of election results.</p>
                    <p class="text-justify animated fadeInUp">We are committed to transparency and security, and have established protocols and disaster recovery plans to ensure the continuity of election operations in the event of unforeseen circumstances. Our ongoing investment in research and development ensures that our technology stays ahead of the curve, so election officials can have confidence in the accuracy of election results.</p>
                    <p class="text-justify animated fadeInUp">At <span className='fw-bolder'>IlectCorp</span>, we are proud to be the trusted provider of secure and reliable election technology. We are dedicated to playing a critical role in safeguarding the democratic process and to delivering technology solutions that help election officials conduct fair and accurate elections.</p>
                    </div>
                </Col>
                {/* <Col className="">
                        <Row className="my-2">
                           
                            <Col sm={12} md={6} className="">
                            <FaUserFriends size={60}/>

                              <h4>Easy to use</h4>
                            <p className=" mb-0">This is a core value in all our designs, they are extremely fast. By implementing our technology you will boost your election speed by a 100% and never slowing down. With our speed the size of your election will never be a burden.</p>
                      
                            </Col>   
                       
                            <Col sm={12} md={6} className="">
                            <RiWalletLine size={60}/>

                              <h4>Affordabilty</h4>
                            <p className=" mb-0">
                             Affordabilty is our way of giving back to society. We give you excellent and reliable elections for half the cost of what it's worth. We aim at reducing the cost of conducting quality elections.
                            </p>
                      
                            </Col>   
                     
                            <Col sm={12} md={6} className="">
                            <GrSecure size={60}/>

                              <h4>Security</h4>
                            <p className=" mb-0">
                            Implementing top notch security measures, we are confident of the total security of our services. We provide 100% fail proof security measures in our softwares, making them unbeatable in the market and trustworthy.

                            </p>
                            </Col>   
                   
                            <Col sm={12} md={6} className="">
                            <WiLightning size={60}/>
                              <h4>Speed</h4>
                            <p className=" mb-0">This is a core value in all our designs, they are extremely fast. By implementing our technology you will boost your election speed by a 100% and never slowing down. With our speed the size of your election will never be a burden.</p>
                      
                            </Col>   
                        </Row>
                    <div className="w-100"></div>
               
                </Col> */}
            </div>
            <div>
            <div className='overflow-hidden w-100'>
            {/* <!-- three-blcok --> */}
            <div className="container my-5 py-2">
                <TitleText className="text-center font-weight-bold my-5">Why Ilect Corp</TitleText>
                <div className="row">
                    <div data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000" data-aos-once="true" className="col-md-4 text-center mx-auto">
                        <img src={easytouse}  alt="User Friendly" className="mx-auto svg-icons"/>
                        <h4>Secure and Accurate Election Results</h4>
                        <p>
                        We understand the importance of secure and accurate election results, which is why we are committed to providing cutting-edge technology that is user-friendly and accessible to all. Our platform is designed to meet the unique needs of each election, and we work closely with our clients to ensure that their elections run smoothly and efficiently.

                        </p>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" data-aos-once="true" className="col-md-4 text-center mx-auto">
                        <img src={secure} alt="Phishing Detect" className="mx-auto svg-icons"/>
                        <h4>Dedicated to Ensuring Integrity</h4>
                        <p>
                        Our team of experts is dedicated to ensuring the integrity of every election and to providing unparalleled customer support. We are proud of the positive impact our technology has had on communities and organizations, and we look forward to serving you in the future.
                        </p>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-once="true" className="col-md-8 text-center mx-auto">
                        <img src={fastimg} alt="Smart Scan" className="mx-auto svg-icons"/>
                        <h4>Join Us in Our Mission</h4>
                        <p>Join us in our mission to make every election fair, secure, and transparent.</p>
                    </div>
                    {/* <!-- <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-once="true" className="col-md-4 text-center">
                        <img src="img/smart-protect-3.jpg" alt="Smart Scan" className="mx-auto"/>
                        <h4>Affordabilty</h4>
                        <p>
                            Affordabilty is our way of giving back to society. We give you excellent and reliable elections for half the cost of what it's worth. We aim at reducing the cost of conducting quality elections.
                        </p>
                    </div> --> */}
                </div>
            </div>
        <div className='row'>
          {/* <!-- feature (skew background) --> */}
           <div className="jumbotron jumbotron-fluid feature" id="feature-first"> 
              <div className="container my-5">
                  <div className="row justify-content-between text-center text-md-left">
                      <div data-aos="fade-right" data-aos-duration="1000" data-aos-once="true" className="col-md-6">
                          <h2 className="font-weight-bold">Remote (Online) elections</h2>
                          <p className="my-4">
                          Online elections are for a larger and unlocalized voters. <br/>
                          It is the preferred choice where large number of voters are involved and/or where there are different polling stations at different locations.


                          </p>
                          <a href="#" className="btn my-4 font-weight-bold atlas-cta cta-blue">Learn More</a>
                      </div>
                      <div data-aos="fade-left" data-aos-duration="1000" data-aos-once="true" className="col-md-6 align-self-center">
                          <img src={feature1} alt="Take a look inside" className="mx-auto d-block"/>
                      </div>
                  </div>
              </div>
          </div> 
          
          {/* <!-- feature (green background) --> */}
          <div className="jumbotron jumbotron-fluid feature" id="feature-last">
              <div className="container">
                  <div className="row justify-content-between text-center text-md-left">
                      <div data-aos="fade-left" data-aos-duration="1000" data-aos-once="true" className="col-md-6 flex-md-last ">
                          <h2 className="font-weight-bold">Onsite (Offline) elections</h2>
                          <p className="my-4">
                          Offline elections are for smaller and localized elections. <br/>
                          It is recommended for small scale elections and those that are held within 24hours.
                          </p>
                          <a href="#" className="btn my-4 font-weight-bold atlas-cta cta-blue">Learn More</a>
                      </div>

                      <div data-aos="fade-right" data-aos-duration="1000" data-aos-once="true" className="col-md-6 align-self-center d-flex justify-content-start order-first">
                          <img src={feature2} alt="Safe and reliable" className="mx-auto d-block"/>
                      </div>
                  </div>
              </div>
          </div>
      {/* <!-- last feature one --> */}
          <div className="jumbotron jumbotron-fluid feature" id="feature-second">
              <div className="container my-5">
                  <div className="row justify-content-between text-center text-md-left">
                      <div data-aos="fade-right" data-aos-duration="1000" data-aos-once="true" className="col-md-6">
                          <h2 className="font-weight-bold">Special elections</h2>
                          <p className="my-4">These types of elections are for all other special purposes,
                           <br/>ranging from award voting and nominations to online poles for surveys etc.</p>
                          <a href="#" className="btn my-4 font-weight-bold atlas-cta cta-blue">Learn More</a>
                      </div>
                      <div data-aos="fade-left" data-aos-duration="1000" data-aos-once="true" className="col-md-6 align-self-center">
                          <img src={feature1} alt="Take a look inside" className="mx-auto d-block"/>
                      </div>
                  </div>
              </div>
          </div>
        </div>  
        </div>
        </div>
    </div>


    );
}
export default Info;











