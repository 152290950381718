import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Breadcrumb,Alert,Form,InputGroup,ProgressBar,Divider} from 'react-bootstrap';
import { BsPlus, BsPlusSquare,BsCone } from 'react-icons/bs';
import { GoCreditCard, GoRocket } from 'react-icons/go';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useAuth } from '../../../components/Context/AuthContext';
import useFetchElectionByUserId from '../../../components/Hooks/useFetchElectionByUserId';
import ElectionList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
// material ui imports
// import { DropzoneArea } from "material-ui-dropzone";
import { firestore, storageRef } from '../../../firebase';
import ProgressHeader, { PaidProgressHeader } from './ProgressHeader';
import { DropzoneArea } from 'material-ui-dropzone';
import { GetElectionforEC } from '../../../components/Hooks/Functions';
import { useElectionContext } from '../../../components/Context/ElectionContext';
import ListPorfolio from './ListPortfolio';
import AddPortfolio from '../../../components/Hooks/useAddPortfolio';
import Candidates from './ListCandidates';
import ListPorfolioPreview from './ListPortfolioPreview';
import CandidatesPreview from './ListCandidatesPreview';
import { IsoRounded, Launch } from '@material-ui/icons';
import LuanchElection from '../../../components/Hooks/useLuanchElection';

import LuanchElectionPro from '../../../components/Hooks/useLuanchElectionPro';
import PayButton from '../../../components/PayButton';
import {PaystackButton} from "react-paystack";
import ErrorTemplate from '../Manage/ErrorTemplate';
import UpgradeElectionPro from '../../../components/Hooks/useUpgradeElectionPro';
import { electionpackages } from '../../../electiondata';
//visible header that contains LOGO and Navigation Icon
var d = new Date();
var n = d.getTime();

const UpgradeElection =({type})=>{
     // files to upload
  const [files, setFiles] = useState(null);
  const [data, setData] = useState([]);
  const [code, setCode] = useState('');
  const [success, setSuccess] = useState(false);
  const [mainError, setMainError] = useState('');
  const [loading, setLoading] = useState(true);
  const [portfolioData,setPortfolio] = useState([]) // data for old portfolio
  const [error, setError] = useState(''); // sets Error from operations
  const { userID,currentUser,userProfile } = useAuth()
  const myhistory = useNavigate();
  const {linkcode} = useParams();
  const {setNewElection} = useElectionContext();
  const [currentElection, setElectionData] = useState();

  const [level, setLevel] = useState(4);
  const [triger,setTriger] = useState(n) // a random value that trigerss a refresh for the useEffect hook
  const [pSuccess,setPSuccess]=useState(false)

    var mail = currentUser.email; 


  const ElectionPrice =()=>{
   if (type===2) return electionpackages.pro.price;
    if (type===3) return electionpackages.plus.price;
   if (type===4) return electionpackages.grand.price;
  }
    

   
   
    const SubmitPro = async (reference)=>{ // Add Portfolio

         const {error,success} = await UpgradeElectionPro(userID,currentElection,reference,type,ElectionPrice());
          setError(error);
         setSuccess(success);     
         setLoading(false)
    }

    
   
    const LaunchPRo = (reference) => {
 
    
        setLoading(true)
        SubmitPro(reference)

        
        
    };
  //  const getPortfolios =async()=>{
  //     try {
  //           setLoading(true)
  //           let allportfolios =[];
    
  //           const portfolios = firestore.collection('portfolio').where("electionId", "==", (linkcode));
  //           const snapshot = await portfolios.get();
          
  //         if (snapshot.empty) {
  //           console.log("empty")
  //           setError("No Portfolio")
  //           setLoading(false)
  //             throw "No Portfolio";

           
  //         }else{
  //            snapshot.forEach((doc) => {
  //           allportfolios.push(doc.data());
           
  //         }); 
  //         setLoading(false)
  //         }
  //         setPortfolio(allportfolios);
  //       }  catch (error) {
  //           console.log(error)
  //           setError("No Portfolio")
  //         }
  //     }
        


            const  ResetElectionContext= async(id)=>{
                setLoading(true);
              
                const elections = firestore.collection("election").doc(id);
              
                await elections.get().then(async(snapshot)=>{
                    if (!snapshot.exists){
                      
                    
                      var error = "Election Does Not Exist!";
                      throw error;
              
                    }
                    if( snapshot.data().live || snapshot.data().inProgress){
                       error = "Your election is live , you cant upgrade a live election!";
                      throw error;
              
                  }
                    await setElectionData(snapshot.data());
                    await setNewElection(snapshot.data());
                    if(snapshot.data().type === type){ 
                      throw "You can not upgrade the electtion to the same Status";
                  }
                    console.log(snapshot.data())

                    setLoading(false)

                }).catch((err)=>{
                  console.log(err)
                  setError(err)

                  setLoading(false)


                })
              
              
               
              }
    useEffect(()=>{
   
            if (linkcode) {
                ResetElectionContext(linkcode);  
              
            }else{
        
                myhistory.replace('/dashboard')
            }
              
        },[linkcode,triger])
    // PAYSTACK INTEGRATION

  // SUCCESSFULLY PAID
  const handlePaystackSuccessAction = async(reference) => {
    setLoading(true)
    setError(error);
    setSuccess(success);
    setLoading(false)
    LaunchPRo(reference)
    console.log(reference);

  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const config = {
    reference: (new Date()).getTime().toString(),
    currency: "GHS",
    email: mail,
    amount: 100* (ElectionPrice() - ( currentElection && currentElection.price)),
    // publicKey: "pk_live_77083c72c9f06630019def9dd201de6d9ceda0e7",
    publicKey: "pk_test_616538abc71f69df481117b096611e10e1e1882c",
  };

  const componentProps = {
    ...config,
    text: "Pay Ghs"+(ElectionPrice()-( currentElection && currentElection.price))+" to upgrade",
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: () => handlePaystackCloseAction(),

  };
  // END OF PAYSTACK INTEGRATION

    return(
        
            <Container className="mx-auto">
                <Breadcrumb className="no-printme">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard" }}>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/manager/"+linkcode}} >
                    Election Manager
                </Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/create-portfolio/"+linkcode}} >
                    Portfolios & Candidates
                </Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/create-portfolio/"+linkcode}} active>
                    Preview & Deploy
                </Breadcrumb.Item>
            </Breadcrumb>
            {
                        // currentElection.type > 1 ?
                        //  <PaidProgressHeader level={level} className="m-3" /> :
                          // <ProgressHeader level={level} className="m-3"/> 

                        
                     
                     }            <Row className="mt-4 mb-4 my-auto">
            {(success ) && 
                            <Alert variant="success" className="text-center"><h3 className="mx-auto">
                                {}
                                Your Election "{currentElection.name}" was upgraded succesfully</h3>
                                click here to view you election details<br/>
                                <LinkButton to={"/manager/"+currentElection.id} 
                      className="btn my-4 font-weight-bold atlas-cta cta-green mx-auto"
                      >View Details</LinkButton>
                            </Alert>}

              
                
                {(loading && !error) && <div className='row col text-center'><Spinner animation="border" variant="success"/></div>}
               {(!success && !error) &&  <h3 className="portfolio-name text-center m-3 text-success"> {currentElection && currentElection.name}</h3>}
               {(!success && !error) && 
                  <div className="row text-end text-center m-auto">
                          {currentElection && (
                            <PaystackButton
                            className="paystack-button mx-auto px-3"
                            {...componentProps}
                        />
                            

                        )  }
                        {/* <Button onClick={Launch} className="btn-success "> <GoRocket/> Launch</Button> '
                        className="btn my-4 font-weight-bold atlas-cta cta-blue mx-auto"
                          // className="btn btn-success p-2 m-2 primary-button"
                          > proceed <VscArrowRight />
                        */}
                        <br/>
                            <span className="text-danger fw-bolder"> NB: You will not be able to make any changes after Launch!!!</span>                      
                    
                    </div>}
                    {error && 
                    <ErrorTemplate error={error}/>
                    }
            
           
            </Row>
            </Container>
          

    )
}

export default UpgradeElection;