import React, { useEffect ,useState} from 'react'
import { Container,Card,Button,Col,Row,Spinner } from 'react-bootstrap'
import logo from  "../../assets/img/logo.png"
import { useElectionContext } from '../../components/Context/ElectionContext';
import useCastVote from '../../components/Hooks/useCasteVote';
import useFetchCandidateById from '../../components/Hooks/useFetchCandidateById';
import { arrayAdd, firestore, increment,db } from '../../firebase';
import MultipleCandidates from './MultipleCandidates';
import SingleCandidates from './SingleCandidate';


const Voteslip = (props) =>{


    
    
    
    //check for the number of candidates for this election
    if(props.candidates.length>1){
           // if candidates are more than one , return a map 
          return  props.candidates.map((ab,i) => (<MultipleCandidates key={i} setSuccess={props.closeModal} setError={props.closeModal}  closeModal={props.closeModal} candidateId={ab} portfolioId={props.portfolioId}/>))
        
        }else if(props.candidates.length===1){
                // else return a simple yes or know
            return <SingleCandidates candidateId={props.candidates[0]} portfolioId={props.portfolioId} closeModal={props.closeModal}/>;
        }else if(props.candidates.length===0){
            return <h1 className="text-center text-secondary"> No candidates available for this category</h1>
        }
    
}
export default Voteslip;