import React, { useState } from 'react';
import { Row,Container } from 'react-bootstrap';
import { TitleText } from '../User/Root/RootElemtents';
import { LinkButton } from '../ViewsElement';
import './Pricing.css'

//visible header that contains LOGO and Navigation Icon

function Pricing() {
    return(
        <Container className="bg-gradient bg-transparent">
           
                <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-lg-12">
                    <TitleText className="font-weight-light">Choose From Our Packages</TitleText>
                    <p className="text-muted mt-4 title-subtitle mx-auto">The best plans await you, for as low as GH₵1 per vote...you could always start a free trial!</p>
                    </div>
                </div>
                <div className="row justify-content">
                    <div className="col-lg-4">
                    <div className="bg-white mt-3 price-box free shadow">
                        <div className="pricing-name text-center">
                        <h4 className="mb-0">Free</h4>
                        </div>
                        <div className="plan-price text-center mt-2">
                        <h3 className="text-custom font-weight-normal mb-0">GH₵0.00<span></span></h3>
                        </div>
                        <div className="price-features mt-2">
                            <p><i className="mdi mdi-check"></i> Max Voters: <span className="font-weight-bold">80</span></p>
                            <p><i className="mdi mdi-check"></i> Max Categories: <span className="font-weight-bold">4 </span></p>
                            <p><i className="mdi mdi-check"></i> Support: <span className="font-weight-bold">Yes</span></p>
                            <p><i className="mdi mdi-check"></i> Security: <span className="font-weight-bold">Max</span></p>
                            <p><i className="mdi mdi-check"></i> Hidden Fees: <span className="font-weight-bold">No</span></p>
                            {/* <p><i className="mdi mdi-close"></i> Voter Restriction: <span className="font-weight-bold">No</span></p>
                            <p><i className="mdi mdi-close"></i> Monitize Election: <span className="font-weight-bold">No</span></p> */}
                        </div>
                        <div className="text-center mt-2">
                        <LinkButton href="#" to="/dashboard/create-election"  className="btn primary-button">Create Election</LinkButton>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4">
                    <div className="bg-active mt-3 price-box pro shadow">
                        <div className="pricing-name text-center">
                        <h4 className="mb-0 ">Pro</h4>
                        </div>
                        <div className="plan-price text-center mt-2">
                        <h3 className="text-white font-weight-normal mb-0 ">GH₵300.00<span className='text-white'>only</span></h3>
                        <h5 className="text-white font-weight-normal mb-0 "><del>GH₵600.00</del></h5>
                        </div>
                        <div className="price-features mt-2 text-white">
                            <p><i className="mdi mdi-check"></i> Max Voters: <span className="font-weight-bold">300</span></p>
                            <p><i className="mdi mdi-check"></i> Max Categories: <span className="font-weight-bold">10</span></p>
                            <p><i className="mdi mdi-check"></i> Support: <span className="font-weight-bold">Yes</span></p>
                            <p><i className="mdi mdi-check"></i> Security: <span className="font-weight-bold">Max</span></p>
                            <p><i className="mdi mdi-check"></i> Hidden Fees: <span className="font-weight-bold">No</span></p>
                            {/* <p><i className="mdi mdi-close"></i> Voter Restriction: <span className="font-weight-bold">No</span></p>
                            <p><i className="mdi mdi-close"></i> Monitize Election: <span className="font-weight-bold">No</span></p> */}
                        </div>
                        <div className="text-center mt-2">
                        <LinkButton href="#" to="/dashboard/create-election-pro"  className="btn primary-button">Create Election</LinkButton>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4">
                    <div className="bg-white mt-3 price-box plus shadow">
                        <div className="pricing-name text-center">
                        <h4 className="mb-0">Plus</h4>
                        </div>
                        <div className="plan-price text-center mt-2">
                        <h3 className="text-custom font-weight-normal mb-0">GH₵1000<span>only</span></h3>
                        </div>
                        <div className="price-features mt-2">
                            <p><i className="mdi mdi-check"></i> Max Voters: <span className="font-weight-bold">600</span></p>
                            <p><i className="mdi mdi-check"></i> Max Categories: <span className="font-weight-bold">15 </span></p>
                            <p><i className="mdi mdi-check"></i> Support: <span className="font-weight-bold">Yes</span></p>
                            <p><i className="mdi mdi-check"></i> Security: <span className="font-weight-bold">Max</span></p>
                            <p><i className="mdi mdi-check"></i> Hidden Fees: <span className="font-weight-bold">No</span></p>
                            {/* <p><i className="mdi mdi-close"></i> Voter Restriction: <span className="font-weight-bold">No</span></p>
                            <p><i className="mdi mdi-close"></i> Monitize Election: <span className="font-weight-bold">No</span></p> */}
                        </div>
                        <div className="text-center mt-2">

                        <LinkButton href="#" to="/dashboard/create-election-plus"  className="btn primary-button">Create Election</LinkButton>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4 mx-auto">
                    <div className="bg-white mt-3 price-box grand shadow">
                        <div className="pricing-name text-center">
                        <h4 className="mb-0">Grand</h4>
                        </div>
                        <div className="plan-price text-center mt-2">
                        <h3 className="text-custom font-weight-normal mb-0">GH₵3000<span>only</span></h3>
                        </div>
                        <div className="price-features mt-2">
                            <p><i className="mdi mdi-check"></i> Max Voters: <span className="font-weight-bold">1500</span></p>
                            <p><i className="mdi mdi-check"></i> Max Categories: <span className="font-weight-bold">20 </span></p>
                            <p><i className="mdi mdi-check"></i> Support: <span className="font-weight-bold">Yes</span></p>
                            <p><i className="mdi mdi-check"></i> Security: <span className="font-weight-bold">Max</span></p>
                            <p><i className="mdi mdi-check"></i> Hidden Fees: <span className="font-weight-bold">No</span></p>
                            {/* <p><i className="mdi mdi-close"></i> Voter Restriction: <span className="font-weight-bold">No</span></p>
                            <p><i className="mdi mdi-close"></i> Monitize Election: <span className="font-weight-bold">No</span></p> */}
                        </div>
                        <div className="text-center mt-2">

                        <LinkButton href="#" to="/dashboard/create-election-grand"  className="btn primary-button">Create Election</LinkButton>
                        </div>
                    </div>
                    </div>
                    {/* <div className="col-lg-4">
                    <div className="bg-dark text-white mt-3 price-box">
                        <div className="pricing-name text-center">
                        <h4 className="mb-0">Custom</h4>
                        </div>
                        <div className="plan-price text-center mt-4">
                        <h1 className="text-custom font-weight-normal mb-0">GH₵- -<span className="text-white">only</span></h1>
                        </div>
                        <div className="price-features mt-5">
                            <p><i className="mdi mdi-check"></i> Support: <span className="font-weight-bold">Yes</span></p>
                            <p><i className="mdi mdi-check"></i> Security: <span className="font-weight-bold">Max</span></p>
                            <p><i className="mdi mdi-check"></i> Hidden Fees: <span className="font-weight-bold">No</span></p>
                            <p><i className="mdi mdi-close"></i> Voter Restriction: <span className="font-weight-bold">No</span></p>
                            <p><i className="mdi mdi-check"></i> Monitize Election: <span className="font-weight-bold">Yes</span></p>
                        </div>
                        <div className="text-center mt-5">
                        
                        <a href="mailto:ilectcorp@gmail.com" className="btn primary-button">                             
                            Send a mail</a>
                        </div>
                    </div>
                    </div> */}
                </div>
                </div>

        </Container>
    );
}
export default Pricing;