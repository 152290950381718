import React, { useState } from 'react';
import { Row,Container } from 'react-bootstrap';
import { electionpackages } from '../../electiondata';
import { useElectionContext } from '../../components/Context/ElectionContext';
import { TitleText } from '../User/Root/RootElemtents';
import { LinkButton } from '../ViewsElement';
import './Pricing.css'

//visible header that contains LOGO and Navigation Icon


function UpgradePricing() {
    const {currentElection} = useElectionContext()


const plus=<>
  <div className="col-12 col-lg-4">
                    <div className="bg-white mt-3 price-box plus shadow">
                        <div className="pricing-name text-center">
                        <h4 className="mb-0">Plus</h4>
                        </div>
                        <div className="plan-price text-center mt-2">
                        <h3 className="text-custom font-weight-normal mb-0 ">GH₵{electionpackages.plus.price - ((currentElection && currentElection.price) || 0)}<span className=''>only</span></h3>
                        </div>
                        <div className="price-features mt-2">
                            <p><i className="mdi mdi-check"></i> Max Voters: <span className="font-weight-bold">1000</span></p>
                            <p><i className="mdi mdi-check"></i> Max Categories: <span className="font-weight-bold">15 </span></p>
                            <p><i className="mdi mdi-check"></i> Support: <span className="font-weight-bold">Yes</span></p>
                            <p><i className="mdi mdi-check"></i> Security: <span className="font-weight-bold">Max</span></p>
                            <p><i className="mdi mdi-check"></i> Hidden Fees: <span className="font-weight-bold">No</span></p>
                            {/* <p><i className="mdi mdi-close"></i> Voter Restriction: <span className="font-weight-bold">No</span></p>
                            <p><i className="mdi mdi-close"></i> Monitize Election: <span className="font-weight-bold">No</span></p> */}
                        </div>
                        <div className="text-center mt-2">

                        <LinkButton href="#" to={`/dashboard/upgrade-election-plus/${currentElection && currentElection.id}` } className="btn primary-button">Upgrage Now</LinkButton>
                        </div>
                    </div>
                    </div>
</>;
const grand=<>
<div className="col-12 col-lg-4 mx-auto">
                    <div className="bg-white mt-3 price-box grand shadow">
                        <div className="pricing-name text-center">
                        <h4 className="mb-0">Grand</h4>
                        </div>
                        <div className="plan-price text-center mt-2">
                        <h3 className="text-custom font-weight-normal mb-0">GH₵{electionpackages.grand.price - ((currentElection && currentElection.price) || 0)}<span>only</span></h3>
                        </div>
                        <div className="price-features mt-2">
                            <p><i className="mdi mdi-check"></i> Max Voters: <span className="font-weight-bold">5000</span></p>
                            <p><i className="mdi mdi-check"></i> Max Categories: <span className="font-weight-bold">20 </span></p>
                            <p><i className="mdi mdi-check"></i> Support: <span className="font-weight-bold">Yes</span></p>
                            <p><i className="mdi mdi-check"></i> Security: <span className="font-weight-bold">Max</span></p>
                            <p><i className="mdi mdi-check"></i> Hidden Fees: <span className="font-weight-bold">No</span></p>
                            {/* <p><i className="mdi mdi-close"></i> Voter Restriction: <span className="font-weight-bold">No</span></p>
                            <p><i className="mdi mdi-close"></i> Monitize Election: <span className="font-weight-bold">No</span></p> */}
                        </div>
                        <div className="text-center mt-2">

                        <LinkButton href="#" to={`/dashboard/upgrade-election-grand/${currentElection && currentElection.id}`}  className="btn primary-button btn-primary">Upgrage Now</LinkButton>
                        </div>
                    </div>
                    </div>
                  
</>;
const pro=<>
 <div className="col-12 col-lg-4  ">
                    <div className="bg-active mt-3 price-box pro shadow  ">
                        <div className="pricing-name text-center">
                        <h4 className="mb-0 ">Pro</h4>
                        </div>
                        <div className="plan-price text-center mt-2">
                        <h3 className="text-white font-weight-normal mb-0 ">GH₵{electionpackages.pro.price - ((currentElection && currentElection.price) || 0)}<span className='text-white'>only</span></h3>
                        </div>
                        <div className="price-features mt-2 text-white">
                            <p><i className="mdi mdi-check"></i> Max Voters: <span className="font-weight-bold">300</span></p>
                            <p><i className="mdi mdi-check"></i> Max Categories: <span className="font-weight-bold">10</span></p>
                            <p><i className="mdi mdi-check"></i> Support: <span className="font-weight-bold">Yes</span></p>
                            <p><i className="mdi mdi-check"></i> Security: <span className="font-weight-bold">Max</span></p>
                            <p><i className="mdi mdi-check"></i> Hidden Fees: <span className="font-weight-bold">No</span></p>
                            {/* <p><i className="mdi mdi-close"></i> Voter Restriction: <span className="font-weight-bold">No</span></p>
                            <p><i className="mdi mdi-close"></i> Monitize Election: <span className="font-weight-bold">No</span></p> */}
                        </div>
                        <div className="text-center mt-2">
                        <LinkButton href="#" to={`/dashboard/upgrade-election-pro/${currentElection && currentElection.id}`}  className="btn primary-button">Upgrage Now</LinkButton>
                        </div>
                    </div>
                    </div>
</>;


    return(
        <Container className="bg-gradient bg-transparent m-0 m-md-auto p-0 p-md-auto">
           
                <div className="container m-0 m-md-auto p-0 p-md-auto">
                <div className="row justify-content-center text-center">
                    <div className="col-lg-12">
                    <TitleText className="font-weight-light">Choose From Our Packages</TitleText>
                    <p className="text-muted mt-4 title-subtitle mx-auto">The best plans await you, for as low as GH₵1 per vote...you could always start a free trial!</p>
                    </div>
                </div>
                <div className="row justify-content m-0 m-md-auto p-0 p-md-auto">
                    {
                        currentElection && (
                            currentElection.type === 1 && (
                                <>
                                {pro}
                                {plus}
                                {grand}
                                </>

                            )
                         
                        )
                    }
                    {
                        currentElection && (
                            currentElection.type === 2 && (
                                <>
                                {plus}
                                {grand}
                                </>

                            )
                         
                        )
                    }
                    {
                        currentElection && (
                            currentElection.type === 3 && (
                                <>
                                {grand}
                                </>

                            )
                         
                        )
                    }
                   
                   
                    
                </div>
                </div>

        </Container>
    );
}
export default UpgradePricing;