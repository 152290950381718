import { useReducer, useEffect } from "react";

//importing firebase and initializing db constant
import { firestore } from "../../firebase";

const ACTIONS = {
  MAKE_REQUEST: "make-request",
  GET_DATA: "get-data",
  ERROR: "error",
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.MAKE_REQUEST:
      return { loading: true, candidate: [] };

    case ACTIONS.GET_DATA:
      return { ...state, loading: false, candidate: action.payload.candidate };

    case ACTIONS.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        candidate: [],
      };

    default:
      return state;
  }
}

export default function useFetchCandidateById(params) {
  const [state, dispatch] = useReducer(reducer, { candidate: [], loading: true });

  useEffect(() => {
    //retrieving all the candidates
    let allcandidates = [];
    dispatch({ type: ACTIONS.MAKE_REQUEST });
    async function getAllCandidates() {
      const candidates = firestore.collection("candidates").doc(params);
      
      await candidates.get().then((doc) => {
        if (doc.exists) {
          allcandidates=doc.data();
          dispatch({
            type: ACTIONS.GET_DATA,
            payload: { candidate: allcandidates },
          });
           // console.log("Candidate data: fetched", doc.data());
        } else {
            // doc.data() will be undefined in this case
            dispatch({
              type: ACTIONS.ERROR,
              payload: { error: "candidate Doesn't Exist" },
            });
            console.log("No such document!");
            return;
          }
    })
    
      
   

    }
      
  
    
console.log(params);
    getAllCandidates();
  }, [params]);

  return state;
}
