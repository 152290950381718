import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table,Alert,Form,InputGroup,ProgressBar,Divider} from 'react-bootstrap';
import { BsPlus, BsPlusSquare } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../../components/Context/AuthContext';
import useFetchElectionByUserId from '../../../components/Hooks/useFetchElectionByUserId';
import ElectionList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
// material ui imports
// import { DropzoneArea } from "material-ui-dropzone";
import { firestore, storageRef } from '../../../firebase';
import ProgressHeader from './ProgressHeader';
import { DropzoneArea } from 'material-ui-dropzone';
import { GetElectionforEC, ResetElectionContext } from '../../../components/Hooks/Functions';
import { useElectionContext } from '../../../components/Context/ElectionContext';
import ListPorfolio from './ListPortfolio';
import AddPortfolio from '../../../components/Hooks/useAddPortfolio';
import Candidates from './ListCandidates';
import AddCandidate from '../../../components/Hooks/useAddCandidate';

//visible header that contains LOGO and Navigation Icon
var d = new Date();
var n = d.getTime();

const AddCandidateForm =({portfolioId,closeModal})=>{
     // files to upload
  const [files, setFiles] = useState(null);
  const [data, setData] = useState([]);
  const [code, setCode] = useState('');
  const [mainError, setMainError] = useState('');
  const [validated, setValidated] = useState(false);
  const [img,setImg] =useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [portfolioData,setPortfolio] = useState([]) // data for old portfolio
  const [currentPortfolioData,setCurrentPortfolio] = useState([]) // data for current portfolio
  const [newPortfolioData,setNewPortfolioData] = useState([]) // data for new portfolio
  const [level, setLevel] = useState(2);
  const [progressImg, setProgressImg] = useState(0); // uplaod image progress barr
  const [error, setError] = useState(''); // sets Error from operations
  const [formError, setFormError] = useState([]); // check error from forms
  const {currentElection} = useElectionContext();
  const [success,setSuccess]=useState(false)
  const { userID } = useAuth()


  const UpdateData =(item,value)=>{
    setData(data => ({...data, [item]:value}))
    setFormError(formError => ({...formError, [item]:''}))
  }
  

    const eventHandler = (event) => {
        let val = event.target.value;
        let nam = event.target.name;
        UpdateData(nam,val)
    };


    const findFormErrors = () => {
        const newErrors = {}
        // name errors
        if ( !data.name || data.name === '' ) newErrors.name = 'Cannot be blank!'
        else if ( data.name.length > 100 ) newErrors.name = 'Name is too long!'
        // food errors
        // if ( !data.email || data.email === '' ) newErrors.startDate = 'Add a valid email!'
    
        if ( !img || img === '' ) newErrors.img = 'Add a Photo!'
        

        return newErrors;
    }

    
    const Submit = async (url)=>{ // Add Portfolio

        const {error,success} = await AddCandidate(data,url,userID,currentElection,portfolioId);
        
        setMainError(error);
        setSuccess(success);
        setData('');
        await closeModal()

   }

   


   
  

    
    const handleChange = (File) => {
  
        const file = File[0];
        setImg(file) 
    setFormError(formError => ({...formError, img:''}))

    }

    const uploadImage = () => {
        // Uploading to firebase storage
        // and updating URLS array for storage in firestore

        const file = img;
        console.log(file)
        if(file){
        var d = new Date();
        var n = d.getTime();
        const filename = n+file.name;
        // references
    
        
        const storeVideoRef = storageRef.child(`/images/candidates/${filename}`);
        
        storeVideoRef.put(file).on('state_changed', (snap) => {
        let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        setProgress(percentage);
        }, (err) => {
        setError(err);
        }, async () => {
        const url = await storeVideoRef.getDownloadURL();
        
        Submit(url)

        });
    }
        

    }

    
  
    
    const Proceed = async (event) => {
        event.preventDefault();
    //  const form = event.currentTarget;
    //   if (form.checkValidity() === false) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //   }
    const newErrors = findFormErrors()
        // Conditional logic:
       if ( Object.keys(newErrors).length > 0 ) {
        // We got errors!
        setFormError(newErrors)
        } else {
        // No errors! Put any logic here for the form submission!
        setLoading(true)
            uploadImage();
            // Submit(url);
            // continue with other rendering
        }
        
    };

    const output = <>
           <Form noValidate validated={validated} onSubmit={Proceed} inline>

                {/* election TITLE */}
                <Form.Group className="row" controlId="validationCustom01">
                    <Form.Label className="col-3 align-bottom text-end mx-auto">
                    Candidate Name
                    </Form.Label>
                    <InputGroup className="form-input col">

                    <Form.Control
                    className=""
                    type="text"
                    name="name"
                    required ={true}
                    value={data.name}
                    onChange={eventHandler}
                    isInvalid={ !!formError.name }
                    />
                    <Form.Control.Feedback type="invalid">
                {formError.name}
                </Form.Control.Feedback>
                </InputGroup>
                </Form.Group> 
                {/* election TITLE */}
                <Form.Group className="row" controlId="validationCustom01">
                    <Form.Label className="col-3 align-bottom text-end mx-auto">
                    Candidate Email
                    </Form.Label>
                    <InputGroup className="form-input col">

                    <Form.Control
                    className=""
                    type="email"
                    name="email"
                    required ={true}
                    value={data.email}
                    onChange={eventHandler}
                    isInvalid={ !!formError.email }
                    />
                    <Form.Control.Feedback type="invalid">
                {formError.email}
                </Form.Control.Feedback>
                </InputGroup>
                </Form.Group>



                {/* INTRO VIDEO */}
                <Form.Group className="row m-2" controlId="validationCustom07">
                    <Form.Label className="col-3 align-bottom my-auto text-end">
                    Photo
                    </Form.Label>
                    <Col>
                    {progress !== 0 && <ProgressBar striped now={progress} />}
                    <DropzoneArea

                        acceptedFiles={["image/*"]}
                        dropzoneText={"Upload candidate photo"}
                        onChange={(files) => handleChange(files)}
                        maxFileSize={300000000}
                        filesLimit={1}
                    />
                {formError.img && <div className="alert-danger">{formError.img} </div>}

                    </Col>
                </Form.Group>

                <Col className="text-center">
                    <Button
                    type="submit"
                    className=" btn-success text-center"
                    >
                    Proceed {(loading && !error)   &&  <Spinner animation="border" variant="white"/> }
                    </Button>

                </Col>
                </Form>

    </>

    return(
        <>
        {(error ) && <Alert variant="danger"><h3 className="text-muted mx-auto">{error}</h3></Alert>}
            
            {success && <Alert variant="success"><h3 className="mx-auto">{"Candidate added Succesfuly"}</h3></Alert> }
            {(!error && !success)   &&  output }

            
        </>
     
    );
}

export default AddCandidateForm;