import { Outlet } from 'react-router-dom'
import { AuthProvider } from '../Context/AuthContext'
import { ElectionContextProvider } from '../Context/ElectionContext'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import "./Layout.css"

export default function BasicLayout() {
  return (
    <>
    <AuthProvider>
        <ElectionContextProvider className="container">
            <Header />
            <Outlet className="content"/>
            <Footer />
         </ElectionContextProvider>
    </AuthProvider>
    </>
  )
}