import styled from 'styled-components';
import { Link as LinkR } from "react-router-dom";

export const LinkButton = styled(LinkR)`
    background: var(--unnamed-color-e6e6e6) 0% 0% no-repeat padding-box;
    background: #E6E6E6 0% 0% no-repeat padding-box;
    opacity: 1;
    background: #198754;
    white-space: nowrap;
    padding: 10px 22px;
    color: #fff;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    &:hover {
    transition: all 0.2s ease-in-out;
    color: #fff;
    }
    a:hover {
    color: #FF00FF;
    }
 
`;