import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Breadcrumb,Alert,Form,InputGroup,ProgressBar,Divider} from 'react-bootstrap';
import { BsPlus, BsPlusSquare,BsCone } from 'react-icons/bs';
import { GoCreditCard, GoRocket } from 'react-icons/go';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useAuth } from '../../../components/Context/AuthContext';
import useFetchElectionByUserId from '../../../components/Hooks/useFetchElectionByUserId';
import ElectionList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
// material ui imports
// import { DropzoneArea } from "material-ui-dropzone";
import { firestore, storageRef } from '../../../firebase';
import ProgressHeader, { PaidProgressHeader } from './ProgressHeader';
import { DropzoneArea } from 'material-ui-dropzone';
import { GetElectionforEC } from '../../../components/Hooks/Functions';
import { useElectionContext } from '../../../components/Context/ElectionContext';
import ListPorfolio from './ListPortfolio';
import AddPortfolio from '../../../components/Hooks/useAddPortfolio';
import Candidates from './ListCandidates';
import ListPorfolioPreview from './ListPortfolioPreview';
import CandidatesPreview from './ListCandidatesPreview';
import { IsoRounded, Launch } from '@material-ui/icons';
import LuanchElection from '../../../components/Hooks/useLuanchElection';

import LuanchElectionPro from '../../../components/Hooks/useLuanchElectionPro';
import PayButton from '../../../components/PayButton';
import {PaystackButton} from "react-paystack";
import { VscArrowRight } from 'react-icons/vsc';
import ErrorTemplate from '../Manage/ErrorTemplate';
//visible header that contains LOGO and Navigation Icon
var d = new Date();
var n = d.getTime();

const PreviewElection =(props)=>{
     // files to upload
const {setNewElection} = useElectionContext();
  const [files, setFiles] = useState(null);
  const [data, setData] = useState([]);
  const [code, setCode] = useState('');
  const [success, setSuccess] = useState(false);
  const [mainError, setMainError] = useState('');
  const [loading, setLoading] = useState(true);
  const [portfolioData,setPortfolio] = useState([]) // data for old portfolio
  const [error, setError] = useState(''); // sets Error from operations
  const [portfolioerror, setPortfolioError] = useState(''); // sets Error from operations
  const { userID } = useAuth()
  const myhistory = useNavigate();
  const {linkcode} = useParams();
  const [currentElection, setElectionData] = useState();
  const [level, setLevel] = useState(3);
  const [triger,setTriger] = useState(n) // a random value that trigerss a refresh for the useEffect hook
  const [pSuccess,setPSuccess]=useState(false)
    
 const AddNewError = (item,error) =>{
  console.log("error added")
  setPortfolioError(portfolioerror => ([...portfolioerror, error]))
  // setError(error)

 }

    useEffect(()=>{
        const getPortfolios =async()=>{
        try {
              setLoading(true)
              let allportfolios =[];
      
              const portfolios = firestore.collection('portfolio').where("electionId", "==", (linkcode));
              const snapshot = await portfolios.get();
            
            if (snapshot.empty) {
              console.log("empty")
              setError("No Portfolio")
              setLoading(false)
                throw "No Portfolio";

             
            }else{
               snapshot.forEach((doc) => {
              allportfolios.push(doc.data());
             
            }); 
            setLoading(false)
            }
            setPortfolio(allportfolios);
          }  catch (error) {
              console.log(error)
              setError("No Portfolio")
            }
        }
          


              const  ResetElectionContext= async(id)=>{
                  setLoading(true);
                
                  const elections = firestore.collection("election").doc(id);
                
                  await elections.get().then(async(snapshot)=>{
                      if (!snapshot.exists){
                        
                      
                        var error = "Election Does Not Exist!";
                        throw error;
                
                      }
                      if(snapshot.data().lauched || snapshot.data().live || snapshot.data().inProgress){
                         error = "Your election has been launched already!";
                        throw error;
                
                    }
                    if(snapshot.data().candidateCount == undefined){
                      error = "Your election has no candidtates!!";
                     throw error;
             
                 }
                      await setElectionData(snapshot.data());
                      await setNewElection(snapshot.data());

                      console.log(snapshot.data())
                        getPortfolios();  

                      setLoading(false)

                  }).catch((err)=>{
                    console.log(err)
                    setError(err)

                    setLoading(false)


                  })
                
                
                 
                }
              if (linkcode) {
                  ResetElectionContext(linkcode);  
                
              }else{
          
                  myhistory.replace('/dashboard')
              }
                
          },[linkcode,triger])



    return(
        
            <Container className="mx-auto">
                <Breadcrumb className="no-printme">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard/"+linkcode }}>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/manager/"+linkcode }} >
                    Election Manager
                </Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/create-portfolio/"+linkcode }} >
                    Portfolios & Candidates
                </Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/create-portfolio/"+linkcode }} active>
                    Preview 
                </Breadcrumb.Item>
            </Breadcrumb>
            {
                        // currentElection.type > 1 ?
                        //  <PaidProgressHeader level={level} className="m-3" /> :
                          <ProgressHeader level={level} className="m-3"/> 

                        
                     
                     }            <Row className="mt-4 mb-4 my-auto">
            {(success ) && 
                            <Alert variant="success" className="text-center"><h3 className="text-muted mx-auto">
                                {}
                                Your Election "{currentElection.name}" was launched succesfully</h3>
                                click here to view you election details<br/>
                                <LinkButton to={"/manager/"+currentElection.id} className="primary-button">View Details</LinkButton>
                            </Alert>}

               
                
                {(loading) && 
                <Row className='mx-auto'>
                  <Col className='mx-auto text-center'>    
                    <Spinner animation="border" className=' mx-auto' variant="success"/>

                  </Col>
                </Row>
                }
                {/* {(loading ) && <Spinner animation="border" className='mx-auto' variant="success"/>} */}
           {!(error || portfolioerror ) && <Col className="mx-auto ">
            

                    {/* <h4 className="text-success text-center">Preview Election and Launch</h4> */}
                    {(!success ) && <p className="text-center">
                    <h3 className="portfolio-name text-center m-3 text-success">{currentElection && currentElection.name}</h3>

                        <strong className=" text-center"> Review your election details before launch</strong>
                    </p>}
                    <Row className="">
                        
                        <Container>
                        
                        
                        {/* {(loading && !error)  &&  <Spinner animation="border" className='mx-auto' variant="success"/> } */}

                            {(portfolioData && !success && !error) && portfolioData.map((data,i) => ( 
                                <Row className="shadow m-2 mb-4">
                                    {/* <ListPorfolioPreview key={data.portfolioId} setCurrentPortfolio={setCurrentPortfolio} data={data} /> */}
                                    <CandidatesPreview key={i}  setTriger={setTriger} currentPortfolio={data} setErrorUP = {AddNewError} />
                                </Row>
                            )) 
                            }

                            
                            
                            
                        </Container>

                    </Row>
                    <Row className="m-3 text-end text-center mx-auto">
                      
                      
                         {/* <Button onClick={Launch} className="btn-success mx-auto"> <GoRocket/> Launch</Button> */}
                         <Col>

                            <LinkButton to={`/publish-election/${linkcode}`} disabled={portfolioerror ? true : false}
                                                className="btn my-4 font-weight-bold atlas-cta cta-blue mx-auto"
                                                // className="btn btn-success p-2 m-2 primary-button"
                                                > proceed to launch <VscArrowRight /></LinkButton>


                         </Col>

                   

                    {/* <Button onClick={Launch} className="btn-success "> <GoRocket/> Launch</Button> */}
                   
                
                </Row>
                    
                
            </Col>}
            {(error || portfolioerror) && // My error template
            <>
                                <ErrorTemplate error={error || portfolioerror} returnLink={`/create-portfolio/${linkcode}`} returnPage={`Portfolios`}/>

            </>
                    }
            
           
            </Row>
            </Container>
          

    )
}

export default PreviewElection;