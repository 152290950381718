
import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table, Alert, Image,Form,Breadcrumb} from 'react-bootstrap';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';

import './css/loadmanager.css';
const ErrorTemplate =({error,returnLink,returnPage})=>{

 

    
   

   

    return(
        <Body>
            <Container className="m-2 mx-auto align-content-sm-center container-fluid">
             {/*welcome Heading  */}
           
     

             {/* activity summary bar */}
          

         
             <Container className="text-center mx-auto">
                <div className="errorImg"></div>
                {(error.length > 2) ?
                  <p className="text-center m-3 portfolio-name text-danger">ERROR: {error}</p>

                
                :
                (error.map((data,i) => (
                        
                 <p className="text-center m-3 portfolio-name text-danger">ERROR: {data}</p>
                )))}
                 
                 
                 <LinkButton className="atlas-cta cta-blue mx-auto" to={returnLink ? returnLink :`/dashboard`}>Return to your {returnPage ? returnPage :`Dashboard`}</LinkButton>
             </Container>
            
             
             

             </Container>



    
           


    

    </Body>
    )
}


export default ErrorTemplate;