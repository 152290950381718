import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, Outlet, redirect, Route, useMatches, useParams } from "react-router-dom";
import { firestore } from "../../firebase";
import ElectionNotFoundError from "../../views/ErrorPages/ElectionNotFoundError";
import { useAuth } from "./AuthContext";
import { useElectionContext } from "./ElectionContext";

const AuthElectionPrivateRoute = ({ children, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { currentElection } = useElectionContext();
  const { currentUser,userID,userProfile,getUser } = useAuth();
  const { linkcode } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (userID) {
      getUser(userID).then(() => {
        setIsLoading(false);
      });
    }
  }, [userID]);

  // console.log(userProfile);
        
  if (!currentUser) navigate ("/login", {state: { from: rest.location?.pathname || "/"}});
  if (!linkcode) return navigate("/dashboard");
  if(!isLoading){if (userProfile) { 
    if(!userProfile.myElections.includes(linkcode))  return navigate("/dashboard/error-not-found", {state: { error: "Election Not Found"}});
  }}

  if (isLoading) {
  return <Outlet {...rest}  ><Spinner color="primary"/></Outlet>

  }
   
  return <Outlet {...rest}  >{children}</Outlet>
};

export default AuthElectionPrivateRoute;
