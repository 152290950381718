import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useElectionContext } from '../../components/Context/ElectionContext';

import logo from "../../assets/img/logo.png"
import useFetchElectionByCode from '../../components/Hooks/useFetchElectionByCode';
import { firestore } from '../../firebase';
import "./Overlay.css";
import { SignUpWidget } from '../../components/SignUpWidget';
import { CreateElectionWidget } from '../../components/CreateElectionWidget';
import { VscArrowDown } from 'react-icons/vsc';
//visible header that contains LOGO and Navigation Icon
import {useAuth} from '../../components/Context/AuthContext';
import { TitleText } from '../User/Root/RootElemtents';

function Overlay(props) {
    const myhistory = useNavigate();
    const [loading,setLoading] = useState(false);
    const [code,setCode] = useState('');
    const [error,setError] = useState('');
    const [election,setElection] = useState('');
    const {setCurrentElection,setNewElection,currentElection} = useElectionContext();
    const {currentUser} = useAuth()
    const {linkcode} = useParams()

    
   

    //on code enter and click proceed
    const GetElection= async()=>{
        let allelections =[];
        setLoading(true);
        const elections = firestore.collection("election").where("code", "==", code);
      
        const snapshot = await elections.get();
      if (snapshot.empty) {
       
        setError("Error : Election Code is Invalid");
        setLoading(false);
         console.log("Error : No such election!");
         return;

      }
      snapshot.forEach((doc) => {
        allelections.push(doc.data());
         console.log("election data: fetched", doc.data());
        setLoading(false);
      });
      if(allelections[0].type === 2){
        setNewElection(allelections[0])
        setLoading(false)

        myhistory('/election')

      }else if(allelections[0].type === 1){
        setCurrentElection(allelections[0]);
        setLoading(false)
        myhistory('/election')
      }
    
    }
    
    if (linkcode) {
        setCode(linkcode)
        console.log(linkcode + ' --the code is set')
        GetElection();
    }
    const proceed =(event)=>{
        event.preventDefault();
        setError('')
        setLoading(true)
        if(code){
            GetElection()
        }else{
            setLoading(false)
            setError("Error : please Enter an election Code to proceed")
        }
    }

    // find election with that code 

    // if election exists
        // redirect to election page to proceed

    // else display election doesn't exist.
  


    return(
                        <div className="row masthead m-0 mx-auto text-center w-100 " id="vote">
                            {/* <div className="col-xl-9 mx-auto ">
                                {/* <h1 className="mb-5 text-success text-center"> Here to vote?</h1> */}
                            {/* </div> */} 

                            <div className="col-md-6 col-sm-12 h-100  text-white mx-auto my-auto" id="">
                            <div className="h-100 mx-auto my-lg-5 align-items-center ">
                                <form onSubmit={proceed}>
                                    <div className="form-row my-auto valign-items-center">
                                       {loading && <Spinner animation="border" variant="light"/>}
                                    <TitleText className="text-light text-capitalize">Enter your election code below to vote</TitleText>
                                    <strong className="text-danger">{error}</strong><br/>
                                    <VscArrowDown className='text-dark'/>
                                    
                                    <div className="input-group text-center">
                                        <input 
                                        type="text" 
                                        className="form-control form-input center-align text-center p-3 font-weight-bold border"  
                                        placeholder="XXXXX" 
                                        aria-label="Election code" 
                                        aria-describedby="basic-addon2"
                                        onChange={(e)=>setCode(e.target.value)}
                                        value={code}
                                        />
                                        <div className="input-group">
                                            
                                            <button
                                            data-aos="fade" data-aos-easing="linear" data-aos-duration="1000" data-aos-once="true" 
                                            className="btn my-4 font-weight-bold atlas-cta cta-green mx-auto"
                                            // className="btn btn-light form-input  " 
                                            type="submit"
                                            >Enter Poll</button>
                                        </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            </div>
                            
                            {/* <div className="col-sm-12 col-md-6 mx-auto bg-gradient order-first"  id="feature-curved-lef"> */}
                            {/* <div className="mx-auto my-lg-5 ro-bg-white ">
                            {/* <SignUpWidget/> */}
                            {/* <CreateElectionWidget/> */}

                            {/* </div> */} 
                            {/* </div> */}
                    </div>
         
    );
}
export default Overlay;