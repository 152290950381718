import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card,Tab , Breadcrumb,Alert,Form,Accordion,InputGroup,ProgressBar,Divider, Modal} from 'react-bootstrap';
import { BsPlus, BsPlusSquare } from 'react-icons/bs';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useAuth } from '../../../components/Context/AuthContext';
import useFetchElectionByUserId from '../../../components/Hooks/useFetchElectionByUserId';
import ElectionList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
// material ui imports
// import { DropzoneArea } from "material-ui-dropzone";
import { firestore, storageRef } from '../../../firebase';
import ProgressHeader, { PaidProgressHeader } from './ProgressHeader';
import { DropzoneArea } from 'material-ui-dropzone';
import { GetElectionforEC } from '../../../components/Hooks/Functions';
import { useElectionContext } from '../../../components/Context/ElectionContext';
import ListPorfolio from './ListPortfolio';
import AddPortfolio from '../../../components/Hooks/useAddPortfolio';
import Candidates from './ListCandidates';
import ErrorTemplate from '../Manage/ErrorTemplate';
import AddCandidateForm from './addCandidateForm';
import "../Dashboard/Dashboard.css"
//visible header that contains LOGO and Navigation Icon
var d = new Date();
var n = d.getTime();

const NewPortfolio =(props)=>{
     // files to upload
  const {setNewElection} = useElectionContext();

  const [files, setFiles] = useState(null);
  const [data, setData] = useState([]);
  const [code, setCode] = useState('');
  const [mainError, setMainError] = useState('');
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ploading, setPLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [portfolioData,setPortfolioData] = useState([]) // data for old portfolio
  const [currentPortfolioData,setCurrentPortfolio] = useState([]) // data for current portfolio
  const [newPortfolioData,setNewPortfolioData] = useState([]) // data for new portfolio
  const [level, setLevel] = useState(2);
  const [progressImg, setProgressImg] = useState(0); // uplaod image progress barr
  const [error, setError] = useState(''); // sets Error from operations
  const [pFormError,setPFormError]= useState('')
  const [formError, setFormError] = useState([]); // check error from forms
  const { userID } = useAuth()
  const myhistory = useNavigate();
  const {linkcode} = useParams();
  const [currentElection, setElectionData] = useState();
  const [triger,setTriger] = useState(n) // a random value that trigerss a refresh for the useEffect hook
  const [pSuccess,setPSuccess]=useState(false)
  const [show, setShow] = useState(false);

 
// if(currentElection){
//     if(currentElection.launched || currentElection.live || currentElection.inProgress){
//     myhistory.push('/manager')
// }
// }

const handleClose = () => {
  var a = new Date();
  var b = a.getTime();
  setShow(false)
   setTriger(b)
  setCurrentPortfolio(currentPortfolioData);

};


const handleSuccess = async() => {
  
    var a = new Date();
  var b = a.getTime();
   setShow(false)
  await setTriger(b)
  setCurrentPortfolio(currentPortfolioData);

}

  const UpdateData =(item,value)=>{
    setData(data => ({...data, [item]:value}))
   
  }
  

    const eventHandler = (event) => {
        let val = event.target.value;
        let nam = event.target.name;
        setData(data => ({...data, [nam]:val}))
    };

    const PortfolioDataHandler = (event) => {
        let val = event.target.value;
        let nam = event.target.name;

        setNewPortfolioData(newPortfolioData => ({...newPortfolioData, [nam]:val}))
        setPFormError('')

    };
    
    const findFormErrors = () => {
        const newErrors = {}
        // name errors
        if ( !data.name || data.name === '' ) newErrors.name = 'cannot be blank!'
        else if ( data.name.length > 100 ) newErrors.name = 'Name is too long!'
        // food errors
        if ( !data.startDate || data.startDate === '' ) newErrors.startDate = 'select a start date!'
        // rating errors
        if ( !data.endDate || data.endDate === '' ) newErrors.endDate = 'select an end date!'
        if ( !data.type || data.type === '' ) newErrors.type = 'choose an election type!'
        if ( !data.img || data.img === '' ) newErrors.img = 'Add a Picture!'
        

        return newErrors;
    }

   
    const SubmitPAdd = async ()=>{ // Add Portfolio
         const {error,success} = await AddPortfolio(newPortfolioData,userID,currentElection);
         
         setError(error);
         setPSuccess(success);
            setPLoading(false)
            var d = new Date();
            var n = d.getTime();

           if (success) {
            setTriger(n)
            setNewPortfolioData([])

           } 
        }

    
    const PortfolioProceed = (event) => {
        event.preventDefault();
    //  const form = event.currentTarget;
    //   if (form.checkValidity() === false) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //   }
    setPLoading(true)
    
        // Conditional logic:
        if ( newPortfolioData.name) {
        // We got errors!
        SubmitPAdd();

        
        } else {
        // No errors! Put any logic here for the form submission!
        setPFormError("invalid input");
        setPLoading(false);
            // continue with other rendering
        }
        
    };

    if(!linkcode){
      myhistory.push(`/dashboard`)
  }
  
  
      
     
  
      useEffect(()=>{
          const getPortfolios =async()=>{
          try {
                setLoading(true)
                let allportfolios =[];
        
                const portfolios = firestore.collection('portfolio').where("electionId", "==", (linkcode));
                const snapshot = await portfolios.get();
              
              if (snapshot.empty) {
                // console.log("empty")
                // setError("No Portfolio Yet")
                setLoading(false)
                  throw "No Portfolio Yet";
  
               
              }else{
                 snapshot.forEach((doc) => {
                allportfolios.push(doc.data());
              setLoading(false)
               
              }); 
              setLoading(false)
              }
              
              console.log(allportfolios);
              setPortfolioData([...allportfolios]);
            }  catch (error) {
                console.log(error)
                setError(error)
                setLoading(false)

              }
          }
            
  
  
                const  ResetElectionContext= async(id)=>{
                    setLoading(true);
                  
                    const elections = firestore.collection("election").doc(id);
                  
                    await elections.get().then(async(snapshot)=>{
                        if (!snapshot.exists){
                          setLoading(false)

                        
                          var error = "Election Not Found!";
                          throw error;
                  
                        }
                        await setElectionData(snapshot.data());
                        await setNewElection(snapshot.data());

                        console.log(snapshot.data())
                          getPortfolios();  
  
                        setLoading(false)
  
                    }).catch((err)=>{
                      console.log(err)
                      setError(err)

                      setLoading(false)


                    })
                  
                  
                   
                  }
                if (linkcode) {
                    ResetElectionContext(linkcode);  
                  
                }else{
            
                    myhistory.replace('/dashboard')
                }
                  
            },[linkcode,triger])

   const AddCandidateModal=
            <>     
              <Modal show={show}  
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              backdrop="static" keyboard={false} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title className='portfolio-name text-center m-3'>Add Candidate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddCandidateForm  closeModal={handleSuccess} portfolioId={currentPortfolioData.portfolioId} />
                    {/* <Voteslip candidates={data.candidate}/> */}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" className="rounded-pill" onClick={handleClose}>
                    Close 
                  </Button>
                </Modal.Footer>
              </Modal>
            </>;
    return(
        
          <Container sx={12} className="mx-auto ">
            <Breadcrumb className="no-printme">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard" }}>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:`/manager/${linkcode}` }} >
                    Election Manager
                </Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:`/create-portfolio/${linkcode}` }} active>
                    Portfolios & Candidates
                </Breadcrumb.Item>
            </Breadcrumb>
            <h3 className="portfolio-name text-center m-3">{currentElection && currentElection.name}</h3>              
            <ProgressHeader level={level} className="m-3"/> 

                        
                     
                     
            {( currentElection ) && <Row className="mt-4 mb-4 my-auto">
                {(loading && !currentElection) && <Spinner animation="border" variant="success"/>}
                
            <Col  className="mx-auto ">
                <Row className="p-3  text-center">
                        <h5 className="portfolio-name p-2 m-2">Add New Portfolio</h5>
                        <Form noValidate className="row text-center my-2"  validated={validated} onSubmit={PortfolioProceed} inline>
                                        {/* <Form.Label  className="col-12 col-sm-12 col-md-2 d-inline text-sm-center text-md-end my-auto m-2" htmlFor="portfolio">Portfolio Name</Form.Label> */}

                    <Form.Group className=" text-center mx-auto col-12 col-md-6 col-lg-5 " controlId="validationCustom01">

                            <Form.Control
                            className="form-input m-2 p-3  border-0 bg-white"
                            id="portfolio"
                            type="text"
                            name="name"
                            placeholder="Portfolio Name"
                            required ={true}
                            value={newPortfolioData.name}
                            onChange={PortfolioDataHandler}
                            isInvalid={ !!pFormError }
                            />                       
                            <Form.Control.Feedback type="invalid">
                            {pFormError}
                            </Form.Control.Feedback>
                   
                      <Button type="submit" variant="success" id="button-addon2" className="text-center col-xs-12 col-md-3 col p-2 m-sm-2 m-auto my-auto atlas-cta mx-auto text-center"> 
                    {ploading  ? <Spinner animation="border" variant="white"/> :<span>Add  <BsPlusSquare/></span> }
                    </Button>
                    </Form.Group> 
                    {(error ) && <Alert variant="danger"><span className="text mx-auto">{error}</span></Alert>}

                    </Form>
                    </Row>
                    <hr/>
                    
                
            </Col>
        <Container  className=" border-grey p-5" >
          {AddCandidateModal}
          <div variant="pills" className="flex-column nav-pills">
            <h3 className='portfolio-name text-center'>Portfolios</h3>       
          <Accordion >
         {!portfolioData.length && <Row className="">
                        
                        <Col  className="text-center">
                        {(error ) && <Alert variant="danger"><span className="text- mx-auto">{error}</span></Alert>}
                        {(loading && !error)  &&  <Spinner animation="border" variant="success"/> }

                  
                        </Col>
                    </Row>}
          {portfolioData && portfolioData.map((data,i) => ( 
                    
              
          <Accordion.Item eventKey={data.portfolioId} alwaysOpen>
            <Accordion.Header className="text-success " onClick={()=>setCurrentPortfolio(data)} eventKey={data.portfolioId}>
              <ListPorfolio key={data.portfolioId} setCurrentPortfolio={setCurrentPortfolio} data={data} />
            </Accordion.Header>

            <Accordion.Body >
                {/* {variant.name} */}
                {/* <a href='#' onClick={()=>setShow(true)} disabled={data.portfolioId ? false : true} class=" btn-secondary"> New Candidate</a> */}

                <Candidates setTriger={setTriger} setCurrentPortfolio={setCurrentPortfolio} currentPortfolio={currentPortfolioData}/>
            </Accordion.Body>
           </Accordion.Item>)
            ) 
            }
       </Accordion>
          </div>
        </Container>

           <Col>
            </Col>
            
      </Row>
      }
            {currentElection && <div className="text-center">
                  <LinkButton to={`/preview-election/${linkcode}`} className="atlas-cta cta-blue mx-auto text-center mx-auto" >Preview & Deploy </LinkButton>
            </div>}
            
             {(error && !currentElection) && // My error template
               <ErrorTemplate error={error}/>
            }
            </Container>
          

    )
}

export default NewPortfolio;