import React ,{ useState ,useEffect} from "react";
import {arrayAdd, firestore,increment,timestamp} from '../../firebase';

import Hashids from 'hashids'



async function UpgradeElectionPro(userID,currentElection,reference,type,price){
    const longhashids = new Hashids('ILoveMighty-upgrade',15)
    let error = ''
    let success=false;
    
    
        //references
        const createdAt = timestamp();
       
        // Create a reference to the SF doc.
        var MainCounters = firestore.collection("EC").doc("--Counter--");
        var election = firestore.collection("election").doc(currentElection.id);
        const update = firestore.collection('update')
        const users = firestore.collection('users').doc(userID);

        await firestore.runTransaction(async (transaction) => {
            var user =  await transaction.get(users);
            var ilect =  await transaction.get(election);
            var EC =  await transaction.get(MainCounters);
            
              if(!reference){   
                        throw "Payment not done!";
                    }
              if(!user.exists){   
                        throw "User does not exist!";
                    }
                    if(!ilect.exists){   
                        throw "election does not exist!";
                    } 
                    if(ilect.data().userId !== userID){ 
                        throw "You are not the owner for this election";
                    }
                    if(ilect.data().type == type){ 
                        throw "You can not upgrade the electtion to the same Status";
                    }
                    if(ilect.data().live){ 
                        throw "You can't edit this election now, it is Live!";
                    }
                //console.log(ilect.data())

                        var newCount = (EC.data().updatedElectionCount || 0) + 1;
               var id = longhashids.encode(newCount);    

                        transaction.update(MainCounters, { updatedElectionCount: newCount });
                       
                        transaction.update(election, {
                            reference,
                            isPaid:true,
                            type,
                            updatedAt:createdAt,
                            updateId:id,
                            price,
                        });
                        transaction.set(update.doc(id), { electionId: currentElection.id,createdAt,reference, id});
                        success = true
 
                    }).then((success) => {
                        console.log("Election successfully updated!");
                        return success;
                    }).catch((err) => {
                    error = err;
                    console.error("Error updating election: ", error);
                    return error;
                });
        


       
    return {error,success}

}

export default UpgradeElectionPro;