import React from 'react';
import "./Demotable.css";

const DemoTable = () => {
  return (
    <table className="demo-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Username</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>user1</td>
        </tr>
        <tr>
          <td>2</td>
          <td>user2</td>
        </tr>
        <tr>
          <td>3</td>
          <td>user3</td>
        </tr>
        {/* You can add more rows as needed */}
      </tbody>
    </table>
  );
};

export default DemoTable;
