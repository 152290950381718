import React ,{ useState ,useEffect} from "react";
import {arrayAdd, firestore,increment,timestamp} from '../../firebase';

import Hashids from 'hashids'



async function VerifyVoters(data,currentElection){
    const hashids = new Hashids('IAmMightyMan-voters-confirm',7)
    const longhashids = new Hashids('IAmMightyMan-voters',15)
    let error = ''
    let id="";
    let voterid= data.id.replace(/\\|\//g,'');
    let success=false;
    
    
        //references
        const createdAt = timestamp();
       
        // Create a reference to the SF doc.
        var election = firestore.collection("election").doc(currentElection.id);
        const voterslist = firestore.collection('voters').doc(currentElection.Provoters)
      
        await firestore.runTransaction(async (transaction) => {
            // var user =  await transaction.get(users);
            var ilect =  await transaction.get(election);
            
              
                if(!ilect.exists){   
                        throw "election does not exist!";
                    } 
                    if(ilect.data().live){ 
                        throw "You can't edit this election now";
                    }
                // console.log(ilect.data())
                    
                        var newCount = (ilect.data().registeredVotersCount || 0) + 1;
                        console.log(newCount)
                        id = hashids.encode(newCount);    

                        transaction.update(voterslist, {
                            // ...data,
                            
                            [`voters.${voterid}.code`]:id,
                            updatedAt: createdAt,
                        })

                        transaction.update(election, {
                            registeredVotersCount: newCount ,
                        });
                     success = id;
                     

                    return success;
 
            }).catch((err) => {
                    error = err;
                    console.error("Error adding election: ", error);
                    return error;
                });
        


       
    return {error,success}

}

export default VerifyVoters;