import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate,useLocation } from "react-router-dom";

function ElectionNotFoundError(props) {
    const history = useNavigate();
    const {state} = useLocation();
  
    let error  = state ? state.error :  "Page Not Found"  ;
  
    return (
      <Container>
        <Row className="justify-content-center mt-5">
          <Col xs={12} md={8} lg={6}>
            <h1 className="text-center">Error</h1>
            <h2 className="text-center">{error}</h2>
            <div className="text-center mt-3">
  
            <Button className="" onClick={() => history("/")}>
              Go to Homepage
            </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
}
export default ElectionNotFoundError;