import React ,{ useState ,useEffect} from "react";
import {arrayAdd, firestore,increment,timestamp} from '../../firebase';

import Hashids from 'hashids'



async function EditCandidate(data,userID,currentElection,portfolioId){
    const longhashids = new Hashids('ILoveAVMB-Candidates',15)
    let error = ''
    let id="";
    let success=false;
    
    
        //references
        const createdAt = timestamp();
        
        // Create a reference to the SF doc.
        var election = firestore.collection("election").doc(currentElection.id);
        const candidate = firestore.collection('candidates').doc(portfolioId);
             console.log(userID)
        const getusers = firestore.collection("users").doc(userID);
        await firestore.runTransaction(async (transaction) => {
            var user =  await transaction.get(getusers);
            var ilect =  await transaction.get(election);
            
              if(!user.exists){   
                        throw "User does not exist!";
                    }
                    if(!ilect.exists){   
                        throw "election does not exist!";
                    }

                    if(ilect.data().userId !== userID){  

                        throw "You are not admin for this election";
                    }
                    if(ilect.data().live){ 
                        throw "You can't edit this election now";
                    }

                console.log(ilect.data())
                    
                        
                        transaction.update(candidate, {
                            img :data.img,
                            name: data.name,
                            email: data.email,
                            updatedAt:createdAt,
                        })

                       
                     success = true

                    return success;
 
            }).catch((err) => {
                    error = err;
                    console.error("Error editing election: ", error);
                    return error;
                });
        


       
    return {error,success}

}

export default EditCandidate;