import React, { useEffect, useState } from 'react';
import { useParams,Link, useNavigate } from 'react-router-dom';
import { Row, Media , Container , Card, Col ,Modal, Button,Spinner, Alert,Breadcrumb} from 'react-bootstrap';

import logo from "../../assets/img/logo.png"
import { firestore, timestamp } from '../../firebase';
import Voteslip from '../Voteslip/Voteslip';
import { useElectionContext } from '../../components/Context/ElectionContext';
import Category from './Category';
import "./Portfolio.css";
import { LinkButton } from '../ViewsElement';
import { Body } from '../User/Dashboard/DashboardElement';
import { TitleText,WelcomeText} from '../../components/Elements/DashboardElemtents';
import { ActiveButtonGreen ,ActiveButtonLightBlue} from '../../components/Elements/PagesElement';


//visible header that contains LOGO and Navigation Icon
var d = new Date();
var n = d.getTime();



const Portfolios =()=> {
    const [portfolioData,setPortfolio] = useState([])
    const [error,setError] = useState('')
    const [loading,setLoading] = useState(false)
    const {setNewElection , currentElection} = useElectionContext();
    const [triger,setTriger] = useState(n)
    const myhistory = useNavigate()
    if(!currentElection){
        myhistory('/');
        
    }
    const leave =async()=>{
        await setNewElection(null)
        myhistory('/')
    }


    useEffect(()=>{
  const getPortfolios =async()=>{
        setLoading(true)
        let allportfolios =[];

        const portfolios = firestore.collection('portfolio').where("electionId", "==", (currentElection.id ||currentElection.electionId));
        const snapshot = await portfolios.get();
      if (snapshot.empty) {
       
        console.log("empty")
        setError("No Portfolio")
        setLoading(false)

        return;
      }else{
         snapshot.forEach((doc) => {
        allportfolios.push(doc.data());
       
      }); 
      setLoading(false)
      }
      setPortfolio(allportfolios);
    }
    if(currentElection){
     getPortfolios();

    }

    },[triger])

    return(

        <Body>
        <Row className="row ">
            <Container className="">
                <Breadcrumb className="no-printme">
                    {/* <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/portfolio" }} active >Election Page</Breadcrumb.Item> */}
                    
                </Breadcrumb>
            </Container>
            {/* election-name-tab */}
            <div className="row container d-flex align-items-center  p-4  ">
            <div className=" row bg-gradient ">
                <WelcomeText className="m-2 mb-3 text-center text-uppercase h4  text-success fw-bold"> {currentElection && currentElection.name} </WelcomeText>
            </div>

            <div className="p-4 row mx-auto border-1 rounded-start bg-white text-center ">
                <WelcomeText className="text-muted"> Categories</WelcomeText>
                {currentElection && (currentElection.published ? 
                <Col className="text-center m-3">
                    <LinkButton to={"/view-results/"+(currentElection.id ||currentElection.electionId)} className="btn text-center btn-lg btn-warning"> View Results </LinkButton>
                </Col>: 
                (loading  ? <Spinner className='row  mx-auto' animation="border" variant="success"/> :
                error ? <Alert  className='row  mx-auto' variant="danger">{error}</Alert>:
                portfolioData.map((data,i) => ( 
                    <div className='row  mx-auto'>
                        <Category  key={data.portfolioId} data={data} setTriger={setTriger}/>
 
                    </div>
                )) 
                
                )
                )
            }
              <div className=" row text-center my-4 ">
                <Button onClick = {leave} className="col-auto mx-auto btn btn-info text-center text-white fw-bolder px-5 py-2"> Leave Election </Button>
            </div>     
            </div>
           
            </div>

            
            {/* <Voteslip/> */}

        </Row>
        </Body>
    );
}
export default Portfolios;