import React, { useEffect, useState } from 'react';
import { Row,Container,Spinner, Button,Alert,Table} from 'react-bootstrap';
import { useParams,Link, useNavigate } from 'react-router-dom';
import { firestore } from '../../../firebase';
// import { forIn } from 'lodash'


//  costum hooks 
import { useElectionContext } from '../../../components/Context/ElectionContext';


//styled elements

// A NOTE FOR FUTURE RONALD.... 

// USE DATA ... NOT DATA DOT VOTERS... I AM NOW TRYING TO RETREIVE THE DATA WITHOUT THE OTHER DATAILS THAT WILL BE ADDED FROM OUR CODE.

const VotersList =(props)=>{
    const myhistory = useNavigate();
    const [loading,setLoading] = useState(false);
    const [code,setCode] = useState('');
    const [voters,setVoters] = useState([]);
    const [error,setError] = useState('');
    const [election,setElection] = useState('');
    const {currentElection} = useElectionContext();
    const {linkcode} = useParams()

    
   

    //on code enter and click proceed
    const GetElection= async()=>{
        setLoading(true);
        const elections = firestore.collection("voters").doc(currentElection.Provoters);
      
      await elections.get().then((doc) => {
        if (doc.exists) {
          var data =doc.data();
            console.log("election data: fetched", data.voters);
            
                setVoters(data.voters)
                setLoading(false);

            
    
        } else {
                // doc.data() will be undefined in this case
                setError("Election Code Invalid");
                setLoading(false);
                console.log("No such election!");
                throw "No such election!";
            }
        }).catch((err)=>{
            console.log("------------ Error -------------");
            console.log(err);
            setError(err)
            setLoading(false);

        })
       
    }
    // const Proceed =(event)=>{
    //     event.preventDefault();

    //     setError('')
    //     if(code){
           
    //     }else(
    //         setError("please Enter an election Code to proceed")
    //     )
    // }
    const CreatedList=()=>{
                let keys = Object.keys(voters);
        
                    keys.map( key => {
                        console.log(voters[key])    
                        return {
                            key, ...voters[key],
                            
                        }
                    } );


                }

    useEffect(()=>{
        GetElection()


    },[])

    return(

    <div className="col-12 m4  mx-auto">
        <div className='text-center fw-bolder'>
          {voters && Object.keys(voters).length}  Registered voters
        </div>
        {(loading && !error) && <Spinner variant="success" animation="border"/>}
        {error && <Alert variant="warning">{error}</Alert>}
        <Table className="align-middle mb-0 table table-borderless table-striped table-hover text-center m-2">
                                <thead>
                                    <tr>
                                        <th className="text-center">#Number</th>
                                        <th>ID</th>
                                        <th className="text-center">Name</th>
                                        <th className="text-center">Status</th>
                                    </tr>
                                </thead>

            
        <tbody>
        {voters && 
                Object.keys(voters).map((key,i) => {
                return <tr>
                        <td className="text-center text-muted">#{i+1}</td>
                        <td> {key}
                        </td>
                        <td className="text-center">{voters[key].name}
                        </td>
                        <td className="text-center">
                        {voters[key].code ? 'registered':'unregistered'}
                        </td>
                    </tr>
                    
                } )

      
       
        
        }
        </tbody>
        </Table>
     </div>

     )
}

export default VotersList;

  // voters.map((voter,key)=>(
        //     console.log(key)
        // ))
      

