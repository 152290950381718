import React, { useRef, useState } from "react";
// router dom
import { Link, useNavigate,useLocation } from "react-router-dom";

// css import
import "./Auth.css";

// boostraP IMPOTS
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import { useAuth } from "../../components/Context/AuthContext";
import { firestore } from "../../firebase";

// image imports
// import Image from "../../../images/img-2.png";
// import { TutorAuthHeader } from "./TutorAuthHeader";


// header import



export default function SignUp() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  // const usernameRef = useRef()
  const passwordConfirmRef = useRef();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useNavigate();

   let location = useLocation();
   const {signup, verifyUser, login,ufrom } = useAuth();
 
   let { from } = location.state || (ufrom && { from: { pathname: ufrom } })|| { from: { pathname: "/login" } };
   

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);
      await signup(
        emailRef.current.value,
        passwordRef.current.value,
        firstNameRef.current.value,
        lastNameRef.current.value
      );
      // console.log("completed signup, going to login")
      // await login(emailRef.current.value, passwordRef.current.value);
      // console.log("completed login, going to verify")

      await verifyUser();
      history.push(from);
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  }
  return (
    <Container fluid>
      <Row>
        {" "}

      
        <Col  className="signupsideimg-S">
          <Container fluid className="my-auto">
            <Row className="height-full-S">
              <Col sx={11} sm={6} md={4} lg={3}  className="mx-auto my-auto text-center container">
               
                <h2 className="header m-4">Sign Up!</h2>
               
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                  <Form.Group >
                    <Form.Control
                      className="form-input m-2 col lg"
                      type="text"
                      required
                      ref={firstNameRef}
                      placeholder="Enter first name"
                    />
                  </Form.Group>
                  <Form.Group >
                    <Form.Control
                      className="form-input col lg  m-2"
                      type="text"
                      required
                      ref={lastNameRef}
                      placeholder="Enter last name"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      className="form-input  m-2"
                      type="email"
                      placeholder="Enter email"
                      ref={emailRef}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      className="form-input  m-2"
                      type="password"
                      placeholder="Password"
                      ref={passwordRef}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      className="form-input  m-2"
                      type="password"
                      placeholder="Confirm password"
                      ref={passwordConfirmRef}
                      required
                    />
                  </Form.Group>

                  <Button
                    disabled={loading}
                    variant="success"
                    className="primary-button"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form>
                <p className="text-muted p-1 mx-auto d-block">
                Already have an account? <Link to="/login" className="text-success "> Sign in.</Link>
                </p>
              </Col>
              
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
