import React, { useState } from 'react';
import logo from "../../assets/img/logo.png"
import { RiMenu3Line, RiCloseFill,RiUser3Fill } from "react-icons/ri";
import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { useElectionContext } from '../Context/ElectionContext';
import { useAuth } from '../Context/AuthContext';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';


//visible header that contains LOGO and Navigation Icon

function Header() {
    const [cookies, setCookie] = useCookies(['permit']);
    const {voter} = useElectionContext();
    const {currentUser,userProfile,logout} =useAuth()

    // authenticated drop down.
    const AuthDrop =()=>(
            <Dropdown>
            <Dropdown.Toggle  variant="" id="dropdown-basic">
            <span  className="text-success fw-bold">{userProfile && userProfile.firstName} {' '}</span>
                <RiUser3Fill/> 
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/dashboard"  className="text-success fw-bold"> Dashboard </Dropdown.Item>
                {/* <Dropdown.Item as={Link} to="/user-profile"> Profile </Dropdown.Item> */}
                <Dropdown.Item as={Link} to="/pricing"  className="text-success fw-bold"> Create Election </Dropdown.Item>
                {/* <Dropdown.Item as={Link} to="/become-Pro"> Become Pro </Dropdown.Item> */}
                <Dropdown.Divider></Dropdown.Divider>
                <Dropdown.Item  onClick = {()=>logout()}  className="text-success fw-bold">Logout</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>)

        const GuestDrop =()=>(
            <Dropdown >
            <Dropdown.Toggle  variant="light" id="dropdown-basic">
            <Navbar.Text  className="text-success fw-bold">Account  </Navbar.Text>
                <RiUser3Fill/> 
            </Dropdown.Toggle>

            <Dropdown.Menu>
            <Dropdown.Item as={Link} to="/login"  className="text-success fw-bold"> Login</Dropdown.Item>
            <Dropdown.Item as={Link} to="/signup"  className="text-success fw-bold"> Sign Up </Dropdown.Item>

               
            </Dropdown.Menu>
        </Dropdown>)

    return(

            <Navbar collapseOnSelect expand="lg" bg="white" sticky="top" variant="success" className='w-100'>
                <Container   className="text-success ">
                    <Navbar.Brand as={Link} to="/">
                    <img alt="Ilect" src={logo}  width="40" height="34" className="d-inline-block align-text-top"/>
                    {" "}
                    {/* <Navbar.Text className="text-success ">Ilect  </Navbar.Text> */}
                     
                    </Navbar.Brand>

                    <Navbar.Toggle  className="no-printme">
                        <RiMenu3Line size={30} color={"green"}/>
                    </Navbar.Toggle>
                    <Navbar.Collapse  className="justify-content-end no-printme" >
                    <Nav className="text-center"  pullRight>
                        <Nav.Link as={Link} className="text-success fw-bold" aria-current="page" to="/">Home</Nav.Link>
                        {/* <Nav.Link as={Link} className="text-success active" aria-current="page" to="/about">About</Nav.Link>*/}
                        <Nav.Link as={Link} className="text-success fw-bold" aria-current="page" to="/get-started">Get Started</Nav.Link>
                        <Nav.Link as={Link} className="text-success fw-bold" aria-current="page" to="/pricing">Pricing</Nav.Link>
                       
                        {/* <Nav.Link as={Link} className="text-success" to="/election"> Elections</Nav.Link> */}
                        
                        {/* <Nav.Link as={Link} className="text-warning" to="/become-pro"> Become A Pro</Nav.Link> */}
                        
                        
                        {currentUser ? <AuthDrop/> : <GuestDrop/>}
                        
                        
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>         
    );
}
export default Header;
