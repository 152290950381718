import React, { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";

import { IoMailOutline,IoLogoLinkedin,IoLogoTwitter,IoLogoFacebook,IoLogoInstagram } from "react-icons/io5";
 import "./Footer.css";

export default function Footer() {
      return (
            <footer className="footer-dark mt-3 no-printme ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 item text">
                            <h3>About Us</h3>
                            <p>
                            With cutting edge technologies, we are gradually revolutionizing the election process, making it secured, faster, transparent and affordable.
                           <br/>
                            we are <strong className="text-white">Ilectcorp</strong> ... <span className="font"> -the trusted election aid-</span>
                            </p>
                        </div>
                        <div className="col-sm-6 col-md-3 item">
                            <h3>Our Services</h3>
                            <ul>
                                <li>Online elections</li>
                                <li>Offline elections</li>
                                <li>Special / Custom elections </li>
                            </ul>
                        </div>
                        {/*    */}
                       
                        
                        <div className="col item social">
                            <a href="https://www.facebook.com/ilectcorp"><IoLogoFacebook/></a>
                            <a href="https://www.twitter.com/ilectcorp"><IoLogoTwitter/></a>
                            <a href="https://www.instagram.com/ilectcorp"><IoLogoInstagram/></a>
                            <a href="mailto:ilectcorp@gmail.com"><IoMailOutline/></a>
                            <a href="https://www.linkedin.com/company/ilect-corp/about/"><IoLogoLinkedin/></a>
                         
                        </div>
                    </div>
                    <p className="copyright">Ilectcorp © 2018</p>
                </div>
            </footer>
        );
}