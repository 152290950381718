import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { LinkButton } from '../views/ViewsElement'
import { useAuth } from './Context/AuthContext'


export const SignUpWidget =()=>{
    const {currentUser} = useAuth();

    return(
        <div className="mt-4 m-5">
            {/* <hr/> */}
            
            <div className="col-md-10 col-lg-12 col-xl-7 mx-auto m3 p1 bg-gradient">
            <h5 className="text-dark m-2">
                New election?
            </h5>
            {(currentUser) ? 
                <div>
                    <LinkButton to="/create-election" className="btn btn-success p-2 m-2"> Create Election.</LinkButton>
                    <p className="text-dark p-1 d-block">
                        start by creating it here
                    </p>
                </div>  
            :
                <div>
                    <LinkButton to="/signup" className="btn btn-success p-2 m-2"> Sign up.</LinkButton>
                    <p className="text-dark p-1 d-block">
                       Already have an account? <Link to="/login" className="text-success "> Sign in.</Link>
                    </p>
                </div>
            }
            </div>
        </div>
    )
}
