import React, { useEffect, useState } from 'react';
import { useParams,Link } from 'react-router-dom';
import { Row, Media , Container , Card, Col ,Modal, Button,Spinner} from 'react-bootstrap';

import logo from "../../../assets/img/logo.png"
import Voteslip from '../../Voteslip/Voteslip';
import { useElectionContext } from '../../../components/Context/ElectionContext';


//visible header that contains LOGO and Navigation Icon


          

const ListPorfolio =({data,setTriger,setCurrentPortfolio})=>{
    const [show, setShow] = useState(false);
    const [sec, setSec] = useState('');
    const {voter , currentElection} = useElectionContext();
      
    const handleClose = () => {
      var a = new Date();
      var b = a.getTime();
      setShow(false)

      setTriger(b)

    };
    const handleShow = () => setShow(true);

    const handleSuccess = () => {
      
      var a = new Date();
      var b = a.getTime();
     
      var width = 5;
      var id = setInterval(frame, 1000);
      
      function frame() {
        if (width == 0) {
          clearInterval(id);
            setTriger(b)
             setShow(false)
        } else {
          width--;
          setSec(width)
        }
      }
    }
    

  const VotingModal=
          <>     
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>{data.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Voteslip closeModal={handleSuccess} portfolioId={data.portfolioId} candidates={data.candidate}/>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" className="rounded-pill" onClick={handleClose}>
                  Close {sec && "( "+ sec +" )"}
                </Button>
              </Modal.Footer>
            </Modal>
          </>;
    
    const canvote =
        <Col xs={12} className="m-1">
        <Row>
            <Col xs={7} className="fw-bold">
                {/* {variant.name} */}
                {data.name}
            </Col>
            {}
            {/* <Col className="text-end">
                <Button onClick={()=>setCurrentPortfolio(data)} className="btn btn-info" href="#">View Candidates</Button>
            </Col> */}
        </Row>

        </Col>
    
    const cannotvote =
        <Col xs={12} className="m-1">
        <Row>
            <Col xs={8} className="">
                {/* {variant.name} */}
                {data.name}
            </Col>
            {}
            <Col xs={4} className="text-end">
                <Button  className="btn btn-light" href="#">voted</Button>
            </Col>
        </Row>
        <hr/>

        </Col>
    

          if (data.voters.indexOf(voter) !== -1){
            console.log("voted already");
            return cannotvote;


        }else{
            console.log("can vote");
            return canvote;
        }
}

export default ListPorfolio;