import React ,{useEffect, useState} from 'react'
import logo from "../../../assets/img/logo.png"
import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table,Alert,Form,InputGroup,ProgressBar,Modal} from 'react-bootstrap';
import { BsPlus, BsPlusSquare } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../../components/Context/AuthContext';
import useFetchElectionByUserId from '../../../components/Hooks/useFetchElectionByUserId';
import ElectionList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
// material ui imports
// import { DropzoneArea } from "material-ui-dropzone";
import { firestore, storageRef } from '../../../firebase';
import ProgressHeader from './ProgressHeader';
import { DropzoneArea } from 'material-ui-dropzone';
import { useElectionContext } from '../../../components/Context/ElectionContext';
import UniqueCandidate from './UniqueCandidate';
import AddCandidateForm from './addCandidateForm';



const CandidatesPreview =({currentPortfolio,setTriger,setErrorUP})=>{
    const [candidate,setCandidate] = useState([])
    const [data,setData] = useState([])
    const [success,setSuccess] = useState(false);
    const [error,setError] = useState("");
    const [initerror,setInitError] = useState("");
    const [initloading,setInitLoading] = useState(false);
    const [loading,setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [sec, setSec] = useState('');
      
    const handleClose = () => {
      var a = new Date();
      var b = a.getTime();
      setShow(false)

      

    };

   const validErrors =()=> {
      if (!currentPortfolio.candidate.length) {
  setErrorUP(currentPortfolio.name,currentPortfolio && currentPortfolio.name + ": Has No Candidates")}
      }

    const handleShow = () => setShow(true);

    const handleSuccess = () => {
      
        var a = new Date();
      var b = a.getTime();
      setTriger(b)
       setShow(false)
      
      
    }
    

    // i get portfolioid 
    // get candidates by that id
    const AddCandidateModal=
          <>     
            <Modal show={show}  
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static" keyboard={false} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add Candidate</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <AddCandidateForm  closeModal={handleSuccess} portfolioId={currentPortfolio.portfolioId} />
                  {/* <Voteslip candidates={data.candidate}/> */}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" className="rounded-pill" onClick={handleClose}>
                  Close 
                </Button>
              </Modal.Footer>
            </Modal>
          </>;
   
useEffect(() => {
validErrors()
 
}, [currentPortfolio])


    return(
    <Col  className="mx-auto">
        <Row className="text-center ">
    
            
        <h3 className="portfolio-name my-3 bg-gradient">{currentPortfolio && currentPortfolio.name}</h3>
        <hr/>
        <Row className="bg-gradient">

            {(error ) && <Alert variant="danger col-12 col-md-6 mx-auto"><span className=" mx-auto">{error}</span></Alert>}
            {currentPortfolio.candidate ?
            (!currentPortfolio.candidate.length) && <Alert variant="danger" className='col-12 col-md-6 mx-auto'><span className="">{currentPortfolio && currentPortfolio.name + ": Has No Candidates"}</span></Alert> :''
            }
            {(loading && !error)   &&  <Spinner animation="border" className='mx-auto' variant="success"/> }

            
            {currentPortfolio.candidate && currentPortfolio.candidate.map((data,i) => (
            <UniqueCandidate preview={true} candidateId={data} key={i}/>
            )) 
            }
            </Row>
        </Row>
       
   
    </Col> 
    )
} 

export default CandidatesPreview;