import React from "react";
import {createBrowserRouter,RouterProvider,Route, useNavigate} from "react-router-dom";
import { CookiesProvider } from 'react-cookie';

//components import
import Election from "./views/Election/Election";
import Landing from "./views/Landing/Landing";
import Portfolios from "./views/Portfolios/Portfolios";
import SignUp from "./views/UserAuth/Signup";
import SignIn from "./views/UserAuth/Login";
import PublicRoute from "./components/Context/PublicRoute";
import PrivateRoute from "./components/Context/PrivateRoute";
import Dashboard from "./views/User/Dashboard/Dashboard";
import UserElections from "./views/User/Elections/Elections";
import NewElection from "./views/User/Elections/NewElectionFree";
import NewPortfolio from "./views/User/Elections/NewPortfolio";


//And
//css
import "./App.css";
import PreviewElection from "./views/User/Elections/PreviewElection";
import ElectionPrivateRoute from "./components/Context/ElectionPrivateRoute";
import ManageElection from "./views/User/Manage/ElectionManager";
import AuthElectionPrivateRoute  from "./components/Context/AuthElectionPrivateRoute";
import ViewPortfolio from "./views/User/Manage/PreviewElection";
import ManageResults from "./views/User/Manage/Results";
import SetUpElection from "./views/User/Elections/SetUpElection";
import Voters from "./views/User/Manage/Voters";
import Info from "./views/Landing/Info";
import Overlay from "./views/Landing/Overlay";
import Pricing from "./views/Pricing/Pricing";
import ShowResults from "./views/Results/Results";
import RegisterVoter from "./views/VotersRegister/RegisterVoter";
import NewElectionPlus from "./views/User/Elections/NewElectionPlus";
import NewElectionPro from "./views/User/Elections/NewElectionPro";
import PublishElection from "./views/User/Elections/PublishElection";
import NewElectionGrand from "./views/User/Elections/NewElectionGrand";

import ErrorPage from "./views/ErrorPages/error-page";
import BasicLayout from "./components/Layouts/BasicLayOut";
import Root from "./views/User/Root/Root";
import UserLayOut from "./components/Layouts/UserLayOut";
import Profile from "./views/User/Profile/Profile";
import RootExternal from "./views/User/Root/RootExternal";
import ElectionNotFoundError from "./views/ErrorPages/ElectionNotFoundError";
import PageNotFound from "./views/ErrorPages/NotFoundPage";
import EditProfile from "./views/User/Profile/EditProfile";
import ResetPassword from "./views/User/Profile/ResetPassword";
import ProfileLayout from "./views/User/Profile/ProfileLayout";
import UpgradeElection from "./views/User/Elections/UpgradeElection";

function Dologout(){
  const navigate = useNavigate()
return navigate("/");
}

const Myrouter = createBrowserRouter([
  {
    path: "/",
    element: <BasicLayout/>,
    errorElement:<ErrorPage/>,
    children:[
      {
        path: "",
        element: <Landing/>, 
        errorElement:<ErrorPage/>,
      },
      {
        path: "get-started",
        element: <Pricing/>, 
        errorElement:<ErrorPage/>,
      },
      {
        path: "cast-vote",
        element: <Overlay/>, 
        errorElement:<ErrorPage/>,
      },
      {
        path: "about",
        element: <Info/>, 
        errorElement:<ErrorPage/>,
      },
      {
        path: "election",
        element: <Election/>, 
        errorElement:<ErrorPage/>,
      },
      {
        path: "election/:linkcode",
        element: <Election/>, 
        errorElement:<ErrorPage/>,
      },
      {
        path: "pricing",
        element: <Pricing/>, 
        errorElement:<ErrorPage/>,
    
      },
      {
        path: "view-results/:linkcode",
        element: <ShowResults/>, 
        errorElement:<ErrorPage/>,
    
      },
      {
        path: "register-voter/:linkcode",
        element: <RegisterVoter/>, 
        errorElement:<ErrorPage/>,
      },
      {
        path: "codeerror",
        element: <><ElectionNotFoundError/></>,
        errorElement:<ErrorPage/>,
      },

      // VOTING ROUTE
       {
        element: <ElectionPrivateRoute/>,
        errorElement:<ErrorPage/>,
        children:[
       {
        path:"portfolio",
        element:< ><Portfolios/></>,
        errorElement:<ErrorPage/>,
      },]
    },

    
    {/* AUTHENTICATION ROUTES / PUBLIC ROUTES*/},
    {
      element: <PublicRoute restricted/>,
      errorElement:<ErrorPage/>,
      children:[
         {
          path:"login",
          element:<><SignIn/></>,
          errorElement:<ErrorPage/>,
        },
        {
          path:"signup",
          element:<><SignUp/></>,
          errorElement:<ErrorPage/>,
        },
      ]
    },
    
    ]
  },
  {
    path: "",
    element: <UserLayOut/>,
    errorElement:<ErrorPage/>,
    children:[
          {
            element: <PrivateRoute/>,
            errorElement:<ErrorPage/>,
            children:[
              {
                path:"logout",
                element: <Dologout/>,
                errorElement:<ErrorPage/>,
              },
              {
                path: "dashboard",
                element: <><Root/></>,
                errorElement:<ErrorPage/>,
                children:[
                  {
                    path: "",
                    element: <><Dashboard/></>,
                    errorElement:<ErrorPage/>,
                  },
                  {
                    path: "manager",
                    element: <><UserElections/></>,
                    errorElement:<ErrorPage/>,
                  },
                  {
                    path: "myelections",
                    element: <><UserElections/></>,
                    errorElement:<ErrorPage/>,
                  },
                  {
                    path: "new-election",
                    element: <><Pricing/></>,
                    errorElement:<ErrorPage/>,
                  },
                  {
                    path: "profiles",
                    element: <><ProfileLayout/></>,
                    errorElement:<ErrorPage/>,
                    children:[
                      {
                        path: "",
                        element: <><Profile/></>,
                        errorElement:<ErrorPage/>,
                      },
                      {
                        path: "edit",
                        element: <><EditProfile/></>,
                        errorElement:<ErrorPage/>,
                      },
                      {
                        path: "reset-password",
                        element: <><ResetPassword/></>,
                        errorElement:<ErrorPage/>,
                      }
                    ]
                  },
                  {
                    element: <AuthElectionPrivateRoute/>,
                    // loader: rootLoader,
                    errorElement:<ErrorPage/>,
                    children:[
                        
                        
                        {
                          path: "setup-election/:linkcode",
                          element: <><SetUpElection/></>,
                          errorElement:<ErrorPage/>,
                        },
                      
                        {
                          path: "create-portfolio/:linkcode",
                          element: <><NewPortfolio/></>,
                          errorElement:<ErrorPage/>,
                        },
                        {
                          path: "preview-election/:linkcode",
                          element: <><PreviewElection/></>,
                          errorElement:<ErrorPage/>,
                        },
                        {
                          path: "publish-election/:linkcode",
                          element: <><PublishElection/></>,
                          errorElement:<ErrorPage/>,
                        },
                        {
                          path: "upgrade-election-pro/:linkcode",
                          element: <><UpgradeElection type={2}/></>,
                          errorElement:<ErrorPage/>,
                        },
                        {
                          path: "upgrade-election-plus/:linkcode",
                          element: <><UpgradeElection type={3}/></>,
                          errorElement:<ErrorPage/>,
                        },
                        {
                          path: "upgrade-election-grand/:linkcode",
                          element: <><UpgradeElection type={4}/></>,
                          errorElement:<ErrorPage/>,
                        },
                      ]},
                  {
                    path: "create-election",
                    element: <><NewElection/></>,
                    errorElement:<ErrorPage/>,
                  },
                
                  {
                    path: "create-election-pro",
                    element: <><NewElectionPro/></>,
                    errorElement:<ErrorPage/>,
                  },
                
                  {
                    path: "create-election-plus",
                    element: <><NewElectionPlus/></>,
                    errorElement:<ErrorPage/>,
                  },
                  {
                    path: "create-election-grand",
                    element: <><NewElectionGrand/></>,
                    errorElement:<ErrorPage/>,
                  },
                  {
                    path: "error-not-found",
                    element: <><PageNotFound/></>,
                    errorElement:<ErrorPage/>,
                  },
                
                ]
              },        
              {
                path: "create-election",
                element: <><NewElection/></>,
                errorElement:<ErrorPage/>,
              },
            
              {
                path: "create-election-pro",
                element: <><NewElectionPro/></>,
                errorElement:<ErrorPage/>,
              },
            
              {
                path: "create-election-plus",
                element: <><NewElectionPlus/></>,
                errorElement:<ErrorPage/>,
              },
            
              
            ]},
          
            {
              //        {/* ELECTION MANAGER */},
        
              element: <AuthElectionPrivateRoute/>,
              errorElement:<ErrorPage/>,
              // loader: rootLoader,
              children:[
                  {path: "",
                  element: <RootExternal/>,
                  errorElement:<ErrorPage/>,
                  children:[
                    
                    {
                      path: "manager/:linkcode",
                      element: <><ManageElection/></>,
                      errorElement:<ErrorPage/>,
                    },
                    {
                      path: "manage-preview/:linkcode",
                      element: <><ViewPortfolio/></>,
                      errorElement:<ErrorPage/>,
                    },
                    {
                      path: "manage-results/:linkcode",
                      element: <><ManageResults/></>,
                      errorElement:<ErrorPage/>,
                    },
                    {
                      path: "manage-voters/:linkcode",
                      element: <><Voters/></>,
                      errorElement:<ErrorPage/>,
                    },
                    {
                      path: "create-portfolio/:linkcode",
                      element: <><NewPortfolio/></>,
                      errorElement:<ErrorPage/>,
                    },
                    {
                      path: "preview-election/:linkcode",
                      element: <><PreviewElection/></>,
                      errorElement:<ErrorPage/>,
                    },
                    {
                      path: "publish-election/:linkcode",
                      element: <><PublishElection/></>,
                      errorElement:<ErrorPage/>,
                    },
                    {
                      path: "setup-election/:linkcode",
                      element: <><SetUpElection/></>,
                      errorElement:<ErrorPage/>,
                    },
                  ]
                }
              ]
            },
          ]
  },
  
 
  
]);


export default  Myrouter;
