import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card ,InputGroup, Table, Alert, Image,Form,Breadcrumb} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { Link, useHistory, useParams } from 'react-router-dom';
import { BecomeProWidget } from '../../../components/BecomeProWidget';
import { useAuth } from '../../../components/Context/AuthContext';

import { useElectionContext } from '../../../components/Context/ElectionContext';
import AddSingleVoterHook from '../../../components/Hooks/useAddSingleVoter';

const AddSingleVoter =()=>{
    const {voter,currentElection} = useElectionContext();
    const [electionData,setElectionData] = useState(currentElection)
    const [success,setSuccess] = useState(false);
    const [level, setLevel] = useState(2);
    const [error,setError] = useState("");
    const [mainerror,setMainError] = useState("");
    const [copied,setCopied] = useState(false);
    const [loading,setLoading] = useState(false);
    const {userID,currentUser} = useAuth()
    const [progress, setProgress] = useState(0);
    const [progressImg, setProgressImg] = useState(0);
    const [data, setData] = useState([]);
    const [validated, setValidated] = useState(false);

    const UpdateData =(item,value)=>{
        setData(data => ({...data, [item]:value}))
      }
      
    
        const eventHandler = (event) => {
            let nam = event.target.name;
            if(nam === 'id'){
            let val = event.target.value.replace(/\\/g, '').replace(".", "dot");
            UpdateData(nam, val);
    
            }else{
            let val = event.target.value;
            UpdateData(nam, val);
    
            }
    
        };
        
        const findFormErrors = () => {
            const newErrors = {}
            // name errors
            if ( !data.id || data.id === '' ) newErrors.id = 'cannot be blank!'
            if ( !data.name || data.name === '' ) newErrors.name = 'cannot be blank!'
            else if ( data.name.length > 100 ) newErrors.name = 'Name is too long!'
            // food errors
        
            
    
            return newErrors;
        }

        const Submit = async ()=>{
             const {error,success} = await AddSingleVoterHook (data,userID,currentElection);
             setMainError(error);
             setSuccess(success);
            setLoading(false)

        }
        
        const Proceed = (event) => {
            event.preventDefault();
            setLoading(true)
        const newErrors = findFormErrors()
            // Conditional logic:
            if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setError(newErrors)
            } else {
            // No errors! Put any logic here for the form submission!
                Submit();
                // continue with other rendering
            }
            
        };
    
        return(
           
    
  
                <div className='col-12 col-md-6 mx-auto p-4 '>
                <h4 className='text-center text-muted p-3 m-1'>Add individual voters</h4>
                <Form noValidate validated={validated} onSubmit={Proceed} inline>
                {(loading && !error) && <Spinner variant="success" animation="border"/>}
                    {mainerror && <Alert variant="warning">{JSON.stringify(mainerror)}</Alert>}
                    {success && <Alert variant="success">
                        <h4>{success}</h4>
                    </Alert>}
                {/* election TITLE */}
                <Form.Group className="row" controlId="validationCustom01">
                    <Form.Label className="align-bottom text-center mx-auto">
                    Name
                    </Form.Label>
                    <InputGroup className="form-input col">
    
                    <Form.Control
                    className=""
                    type="text"
                    name="name"
                    required ={true}
                    value={data.name}
                    onChange={eventHandler}
                    isInvalid={ !!error.name }
                    />
                    <Form.Control.Feedback type="invalid">
                {error.name}
                </Form.Control.Feedback>
                </InputGroup>
                </Form.Group>
                {/* election TITLE */}
                <Form.Group className="row" controlId="validationCustom01">
                    <Form.Label className=" align-bottom text-center mx-auto">
                    unique ID (Staff /student Id /Email /Phone Number)
                    </Form.Label>
                    <InputGroup className="form-input col">
    
                    <Form.Control
                    className=""
                    type="text"
                    name="id"
                    required ={true}
                    value={data.id}
                    onChange={eventHandler}
                    isInvalid={ !!error.id }
                    />
                    <Form.Control.Feedback type="invalid">
                {error.id}
                </Form.Control.Feedback>
                </InputGroup>
                </Form.Group>
    
            
                <Col className="text-center">
                    <Button
                    type="submit"
                    className=" btn-success text-center"
                    >
                    Proceed
                    </Button>
    
                </Col>
                </Form>
    
    
                
            </div>
              
        )
    }
    
    export default AddSingleVoter;