import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Chart from "react-google-charts";
const BarChart =React.forwardRef(({data , portfolio})=>{
return(
        <div className="bg-gradient col-12 col-lg-6" style={{ display: 'flex', maxWidth: 900 }}>
        <Chart
            
             width={'100%'}
             height={500}
            chartType="BarChart"
            loader={<Spinner variant="warning" animation="border"/>}
            data={[
                ['Name', 'Votes'],
                ...data
                ]}
            options={{
                title: portfolio,
                chartArea: { width: '50%' },
                colors: ['#3febb2', '#ffab91'],
                bar: { groupWidth: '25%' },
                bars: 'horizontal',
                axes: {
                    y: {
                      0: { side: 'right' },
                    },
                  },
                hAxis: {
                    title: 'Total Votes',
                    minValue: 0,
                },
                vAxis: {
                    title: 'Candidates',
                },
                
                animation: {
                    duration: 1000,
                    easing: 'out',
                    startup: true,
                  },
            }}
            // For tests
            rootProps={{ 'data-results': '4' }}
        />
    </div>
)})

export default BarChart;