import React, { useEffect, useState,useRef } from 'react';
import ReactToPrint, { useReactToPrint } from 'react-to-print';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table, Alert, Image,Form,Breadcrumb, Tabs, Tab} from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useAuth } from '../../../components/Context/AuthContext';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
import logo from "../../../assets/img/logo.png"
import { firestore } from '../../../firebase';
import CandidatesResults from './ListCandidatesResult';


//visible header that contains LOGO and Navigation Icon
var d = new Date();
var n = d.getTime();

const ManageResults =()=>{
    const componentRef = useRef();
    const fullresultsRef = useRef();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [portfolioData,setPortfolio] = useState([]) // data for old portfolio
    const [error, setError] = useState(''); // sets Error from operations
    const myhistory = useNavigate();
    const {linkcode} = useParams();
    const [currentElection , setElectionData] = useState();
    const [triger,setTriger] = useState(n) // a random value that trigerss a refresh for the useEffect hook
    const [key, setKey] = useState();
 
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });
    const handlePrintFull = useReactToPrint({
        content: () => fullresultsRef.current,
      });
    
      const FullResults = React.forwardRef(({},ref)=>{
        return (
        <div className="mx-auto text-center" ref={ref}>
        
        {(error ) && <Alert variant="danger"><h3 className="text-muted mx-auto">{error}</h3></Alert>}
           
           
              { (portfolioData && !success && !error) && portfolioData.map((data,i) => ( 
                       <div className="mx-auto ro-bg-white mt-2">

                           <CandidatesResults setTriger={setTriger} currentPortfolio={data}/>
                       </div>
                       ))
                       }
               
               

                  
        </div>)

      })
  
    useEffect(()=>{
        const getPortfolios =async()=>{
        try {
              setLoading(true)
              let allportfolios =[];
      
              const portfolios = firestore.collection('portfolio').where("electionId", "==", (linkcode));
              const snapshot = await portfolios.get();
            
            if (snapshot.empty) {
              console.log("empty")
              setError("No Portfolio")
              setLoading(false)
                throw "No Portfolio";

             
            }else{
               snapshot.forEach((doc) => {
              allportfolios.push(doc.data());
             
            }); 
            setLoading(false)
            }
            setPortfolio(allportfolios);
          }  catch (error) {
              console.log(error)
              setError("No Portfolio")
            }
        }
          


              const  ResetElectionContext= async(id)=>{
                  setLoading(true);
                
                  const elections = firestore.collection("election").doc(id);
                
                  await elections.get().then(async(snapshot)=>{
                      if (!snapshot.exists){
                        
                      
                        var error = "No such election!";
                        throw error;
                
                      }
                      await setElectionData(snapshot.data());
                      console.log(snapshot.data())
                        getPortfolios();  

                      setLoading(false)

                  }).catch((err)=>{
                    console.log(err)
                  })
                
                
                 
                }
              if (linkcode) {
                  ResetElectionContext(linkcode);  
                
              }else{
          
                  myhistory.replace('/dashboard')
              }
                
          },[linkcode,myhistory])


    return(
        <Body>
            <Container className="m-2 mx-auto align-content-sm-center container-fluid flex-fill">
             {/*welcome Heading  */}
             
            <Breadcrumb className="no-printme">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:"/dashboard" }}>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:`/manager/${linkcode}` }} >
                    Election Manager
                </Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to:`/manage-results/${linkcode}` }} active>
                    Results
                </Breadcrumb.Item>
            </Breadcrumb>
             <HeaderBanner className=" row mx-sm-0 mx-auto my-auto p-2">
                    
                    <Col sm={2} md={2} lg={2}>
                        <HeaderBannerIcon >
                        <Image src={currentElection && (currentElection.img || logo)} rounded fluid/>
                    </HeaderBannerIcon>
                    </Col>
                    
                    <Col>
                        <h5>{currentElection && currentElection.name}</h5>
                    </Col>
                 
             </HeaderBanner>
             
             

             {/* activity summary bar */}
             <div className=" p-md-2 mx-auto text-end">
             
                     <div className="m-lg-1 no-printme" >
                        {/* <Col  className="my-auto text-center"><h4> {currentElection && currentElection.name}</h4> </Col> */}
                                       <button className="primary-button " onClick={handlePrintFull}>Print All Results</button>
                                       <div className='d-none'>
                                        <FullResults ref={fullresultsRef}/>
                                       </div>

                     </div>
                     <div className="mx-auto text-center">
                         <h5>Results</h5>
                     {(error ) && <Alert variant="danger"><h3 className="text-muted mx-auto">{error}</h3></Alert>}
                        
                        {(loading && !error)  &&  <Spinner className="mx-auto" animation="border" variant="success"/> }
                            <div className="mx-auto ro-bg-white mt-2">
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="mb-3"
                                    >

                            {(portfolioData && !success && !error) && portfolioData.map((data,i) => ( 
                                
                                   
                                    <Tab className='bg-transparent' eventKey={data.name} title={data.name} >
                                  
                                    <CandidatesResults ref={componentRef} setTriger={setTriger} currentPortfolio={data}/>

                                    <button className='primary-button' onClick={handlePrint}>Print {data.name} results</button>

                                    </Tab>
                            )) 
                            }                                    
                            </Tabs>                                
                            </div>



                               
                     </div>
                   

             </div>



             {/* table for recent elections */}
    


        </Container>
    

    </Body>
    )
}


export default ManageResults;