import React, { useEffect, useState } from 'react';

import { Row,Nav,NavDropdown,Navbar ,Spinner, Col, Container, Button, Card , Table,Alert,Form,InputGroup,ProgressBar,Divider} from 'react-bootstrap';
import { BsPlus, BsPlusSquare } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../components/Context/AuthContext';
import useFetchElectionByUserId from '../../../components/Hooks/useFetchElectionByUserId';
import ElectionList from '../../../components/Table/Table';
import { HeaderBanner ,LinkButton,Body,HeaderBannerIcon, ActionButtonRight} from '../Dashboard/DashboardElement';
// material ui imports
// import { DropzoneArea } from "material-ui-dropzone";
import { firestore, storageRef } from '../../../firebase';
import ProgressHeader from './ProgressHeader';
import { DropzoneArea } from 'material-ui-dropzone';
import { GetElectionforEC } from '../../../components/Hooks/Functions';
import { useElectionContext } from '../../../components/Context/ElectionContext';
import ListPorfolio from './ListPortfolio';
import AddPortfolio from '../../../components/Hooks/useAddPortfolio';
import Candidates from './ListCandidates';

//visible header that contains LOGO and Navigation Icon
var d = new Date();
var n = d.getTime();

const SetUpElection =(props)=>{
     // files to upload
  const [files, setFiles] = useState(null);
  const [data, setData] = useState([]);
  const [code, setCode] = useState('');
  const [mainError, setMainError] = useState('');
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [portfolioData,setPortfolio] = useState([]) // data for old portfolio
  const [currentPortfolioData,setCurrentPortfolio] = useState([]) // data for current portfolio
  const [newPortfolioData,setNewPortfolioData] = useState([]) // data for new portfolio
  const [level, setLevel] = useState(1);
  const [progressImg, setProgressImg] = useState(0); // uplaod image progress barr
  const [error, setError] = useState(''); // sets Error from operations
  const [pFormError,setPFormError]= useState('')
  const [formError, setFormError] = useState([]); // check error from forms
  const { userID } = useAuth()
  const myhistory = useNavigate();
  const {linkcode} = useParams();
  const {currentElection,setNewElection} = useElectionContext();
  const [triger,setTriger] = useState(n) // a random value that trigerss a refresh for the useEffect hook
  const [pSuccess,setPSuccess]=useState(false)


    
    

    useEffect(()=>{
            const  ResetElectionContext= async(id)=>{
                // setLoading(true);
                let allelections =[];
              
                const elections = firestore.collection("election").where("id", "==", id);
              
                await elections.get().then(async(snapshot)=>{
                    if (snapshot.empty) {
 
                        var error = "No such election!";
                           throw error ;
                     
                     }
                     snapshot.forEach((doc) => {
                       allelections.push(doc.data());
                        //console.log("election data: fetched", doc.data());

                     });
                     await setNewElection(allelections[0]);

                    setLoading(false)
              
              
                }).catch((err)=>{
                  console.log(err)
                  setError(err)
                })
              
              
               
              }
              if (linkcode) {
                ResetElectionContext(linkcode);    
              
            }else{
        
                myhistory.replace('/create-election')
            }
              
        },[linkcode])

        return(
        
            <Container className="mx-auto">
                {/* <ProgressHeader level={level} className="m-3"/> */}
            <Row className="mt-4 mb-4 my-auto">
                {(loading && !currentElection) && <Spinner animation="border" variant="success"/>}

                {(currentElection && !loading && !error) && 
             <Container className="text-center my-auto mx-auto">
                 <h3 className="text-center m-3">{currentElection.name}</h3>

                 <h4 className="text-success m-3">Congratulations!!</h4>
                 <p>you have succesfully created your  election.<br/>click the button below to set it up</p>
                 <LinkButton className="btn btn-success m-3" to={`/manager/${currentElection.id}`}>Election Manager</LinkButton>
             </Container>
            
             }
            </Row>
            </Container>
          

    )
}

export default SetUpElection;