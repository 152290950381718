import { Outlet } from 'react-router-dom'
import { AuthProvider } from '../../../components/Context/AuthContext'


export default function ProfileLayout() {
  return (
    <>
    <AuthProvider>
            <Outlet className="content"/>
    </AuthProvider>
    </>
  )
}