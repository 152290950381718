import React, { useEffect, useState } from 'react';
import { Row,Container,Spinner, Button,Alert,Col} from 'react-bootstrap';
import { useParams,Link, useNavigate, useNavigation } from 'react-router-dom';

//  costum hooks 
import useFetchElectionById from '../../components/Hooks/useFetchElectionById';
import { useElectionContext } from '../../components/Context/ElectionContext';

//import components and widgets
import {SignUpWidget} from "../../components/SignUpWidget";
import IdLog from './IdLog';

//styled elements
import { LinkButton } from '../ViewsElement';
// import { GetElection } from '../../components/Hooks/Functions';

//visible header that contains LOGO and Navigation Icon
import "./Election.css"
import { RowingRounded } from '@material-ui/icons';
import { firestore } from '../../firebase';


const Proceed =({electionId})=>{
    const {setCurrentVoter} = useElectionContext();
    const [loading,setLoading] = useState(false)
    const myhistory =useNavigate()
    const Checkin=()=>{
        setLoading(true)
        setCurrentVoter()
        setLoading(false)

        myhistory('/portfolio');
    }
   
    return(
        
        electionId &&
        electionId.live ?
        <div className="col-md-10 col-lg-8 col-xl-7 mx-auto m3 p3">
            <p className="text-muted m-3"> 
                click <span className="text-success">"Proceed"</span> to continue.
            </p>
            <Button onClick={()=>Checkin()} 
            // className="btn btn-success p-2 m-2"
            className="btn my-4 font-weight-bold atlas-cta cta-blue mx-auto"

            > Proceed</Button>
            {loading && <Spinner variant="light" animation="border"/>}
        </div>:<Alert variant="warning">Sorry:This election is currently offline!</Alert>
        
    )
}

function Election (){
    const [open,setOpen] = useState(false);
    const [election,setElection] = useState('');
    const [codeError,setCodeError] = useState('');
    const [loading,setLoading] = useState(false);
    const {voter,currentElection,setCurrentElection} = useElectionContext();
    const myhistory = useNavigate();
    const {linkcode} = useParams();
    // if (linkcode) {
    //     console.log(linkcode + ' --the code is set')
    //     const Newelection = await GetElection(linkcode);
    //     console.log(Newelection)
    //     //  setCurrentElection(Newelection)

    // }else{
    // if(!currentElection){
    //     myhistory('/')
    // }}
    
     const  GetElection= async(code)=>{
        let allelections =[];
        // setLoading(true);
        const elections = firestore.collection("election").where("code", "==", code);
      
        const snapshot = await elections.get();
      if (snapshot.empty) {
       
         var error = "Election Not Found ";
            myhistory('/codeerror',{state: { error: error}})
            return error ;
    
      }
      snapshot.forEach((doc) => {
        allelections.push(doc.data());
         //console.log("election data: fetched", doc.data());
        
      });
    setCurrentElection(allelections[0]);
    // myhistory('/election')
    }

    // fetch election
        
    useEffect(() => {
        function fetchData() {
        if (linkcode && !currentElection) {
        console.log(linkcode + ' --the code is set');
        GetElection(linkcode);
        // setCurrentElection(newElection);

        } else {
        if (!currentElection) {
        myhistory('/');
        }
        }
        }
        fetchData();
        }, [linkcode]);
        
      
    

    return(
            <div className="align-items-center  text-center flex-column green  mt-2 mx-0 ">
                <Row className="ro-bg-white mx-auto container w-70">
                   
                        <Col className="confirmation">
                            <h5 className="col-xl-9 mx-auto m-2">
                               <h3 className="fw-bold">{!currentElection ? <Spinner animation="border"/> :currentElection.name}</h3>
                            </h5>

                            {currentElection  &&  (
                                currentElection.live ?
                                (
            
                             (((currentElection.votersCast  && currentElection.votersCast.length) >= 10) && currentElection.type === 1) ? 
                             (
                            <h5 className="col-xl-9 mx-auto m-2">
                               <h3 className="fw-bold">{!currentElection ? <Spinner animation="border"/> :currentElection.name + " is ready and needs to upgrade"}</h3>
                               
                            </h5>
                             )        :
                             currentElection && (currentElection.private ? <IdLog electionId={currentElection} /> : <Proceed electionId={currentElection} />)

                            ):
                            <h5 className="col-xl-9 mx-auto m-2 m-md-5 p-md-5 text-center">
                               <h3 className="fw-bold text-danger text-center">{!currentElection ? <Spinner animation="border"/> : "Sorry, this election is offline at the moment, contact your election admin to continue."}</h3>
                               
                            </h5>
                            )
                        }
                          
                           
                            
                        </Col>

                    </Row>
                                       {/* <SignUpWidget/> */}

            </div>
    );
}

export default Election;
//  seperate Pro, grand and free
// type stands for type of level 1 = free, 2 = pro , 3 = star , 4 =grand
// 
// 
// 
